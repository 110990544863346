import "./NewMessage.scss";
import Blur from "react-css-blur";
import { useEffect, useState } from "react";

import { AddFavList } from "../../../redux/chatSlice";
import { useDispatch, useSelector } from "react-redux";
import Socket from "../../../redux/socketConnect";
import ClassNameGenerator from "@mui/utils/ClassNameGenerator";
import { formatDateMsg } from "../../Utils/formatDate";
import { CompassOutlined } from "@ant-design/icons";
import moment from "moment";

const NewMessage = ({
  recentMessages,
  conversation,
  currentUser,
  handleClick,
  receiver,
  unreadMessageCounts,
  showUnreadAndUnanswered,
  RemoveRoom,
  isRemoved,
  ChatData,
  isNewMessage,
  isFavActive,
}) => {
  const dispatch = useDispatch();
  const {
    RecieverDetails,
    AllUsers,
    notification,
    messages,
    ChatRoomsUsers,
    currentChat,
    unreadMsg,
    favList,
    usersNotFoundMessage,
    getAllMessagesLoading
  } = useSelector((state) => state.chat);

  const [notificationData, setNotificationData] = useState([]);
  const [isChatOpen, setIsChatOpen] = useState([]);
  const [Favusers, setFavusers] = useState([]);
  const [selectedUserMessages, setSelectedUserMessages] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [latestMessageTimestamps, setLatestMessageTimestamps] = useState({});
  const [displayChatUsers, setDisplayChatUsers] = useState([]);
  const [blurredChats, setBlurredChats] = useState([]);

  useEffect(() => {
    const handleRemoveUserFromChat = (data) => {
      setBlurredChats((prev) => [...prev, data?.isSameChatRoom?.senderId]);
    };
    console.log("chatdata Ouside.......", ChatData, isNewMessage);

    if (isRemoved && RemoveRoom) {
      handleRemoveUserFromChat(RemoveRoom);
    } else {
      console.log("Removing inside", isRemoved, RemoveRoom);
      setBlurredChats([]);
    }
  }, [isRemoved, RemoveRoom]);

  const loadSelectedUserMessages = (userId, lastMsg) => {
    if (!lastMsg) {
      const userMessages = recentMessages?.filter(
        (message) =>
          message.senderId === userId || message.receiverId === userId
      );
      setSelectedUserMessages(userMessages);
      setSelectedUserId(userId);
    }
  };

  useEffect(() => {
    const unReadChats = recentMessages.filter((m) => m.isRead !== true);
    let isOpen = unReadChats.filter(
      (m) => m?.chatId !== ChatRoomsUsers?.ChatId
    );
    console.log("isOpen", isOpen, ChatRoomsUsers?.ChatId);
    if (isOpen) {
      setIsChatOpen(isOpen);
      setNotificationData((prevData) => [...prevData, ...isOpen]);
    } else {
      setNotificationData([]);
    }
    return () => setIsChatOpen(null);
  }, [unreadMsg]);

  const toggleFav = (user) => {
    const isFavorite = user?.FavByAdmin?.includes(currentUser?.id);
    let updatedUsers;
    if (isFavActive === "Favorites") {
      updatedUsers = displayChatUsers.filter(
        (chatUser) => chatUser.id !== user.id
      );
    } else {
      // Update local state immediately for instant UI change
      updatedUsers = displayChatUsers.map((chatUser) => {
        if (chatUser.id === user.id) {
          return {
            ...chatUser,
            FavByAdmin: isFavorite
              ? chatUser.FavByAdmin.filter((id) => id !== currentUser?.id)
              : [...(chatUser.FavByAdmin || []), currentUser?.id],
          };
        }
        return chatUser;
      });
    }

    setDisplayChatUsers(updatedUsers); // Update state instantly

    // Dispatch Redux action to update backend
    dispatch(AddFavList(currentUser?.id, user?.id));
  };

  const sortUsers = (users) => {
    const favorites = [];
    const nonFavorites = [];

    // Split into favorites and non-favorites first
    users.forEach(user => {
      user.FavByAdmin?.includes(currentUser?.id)
        ? favorites.push(user)
        : nonFavorites.push(user);
    });

    // Sort each group by lastChatTime descending
    const sortByTime = (a, b) => b.lastChatTime - a.lastChatTime;

    return [
      ...favorites.sort(sortByTime),
      ...nonFavorites.sort(sortByTime)
    ];
  };

  const handleOpenChat = (conversation) => {
    handleClick(conversation);
    console.log("isChatOpen", isChatOpen);
    if (conversation?.id === isChatOpen[0]?.senderId) {
      setIsChatOpen(false);
      setNotificationData([]);
    }
  };

  useEffect(() => {
    Socket.socketInit(currentUser?.id, dispatch);
  }, [dispatch]);

  // // Update displayChatUsers based on showUnreadAndUnanswered and sortUsers
  // useEffect(() => {
  //   const sortedRecieverDetails = sortUsers(receiver, favList);

  //   if (showUnreadAndUnanswered) {
  //     const filteredReceiver = sortedRecieverDetails?.filter(
  //       (user) => user?.isSenderAdmin === false && user?.lastMessage
  //     );
  //     setDisplayChatUsers(filteredReceiver || []);
  //   } else {
  //     setDisplayChatUsers(sortedRecieverDetails);
  //   }
  // }, [
  //   isNewMessage,
  //   showUnreadAndUnanswered,
  //   recentMessages,
  //   receiver,
  //   favList,
  //   displayChatUsers,
  // ]);

  useEffect(() => {
    const updatedDisplayChatUsers = displayChatUsers?.map((user) => {
      if (user.id === ChatData?.chatUser?.id) {
        return {
          ...user,
          chatId: ChatData?.chatId,
        };
      }
      return user;
    });
    setDisplayChatUsers(updatedDisplayChatUsers);
  }, [ChatData, isNewMessage]);

  useEffect(() => {
    const enhancedUsers = receiver.map(user => {
      const userMessages = recentMessages.filter(msg =>
        msg?.chatId === user?.ChatId
      );

      const sortedMessages = [...userMessages].sort((a, b) =>
        parseInt(b.createdAt, 10) - parseInt(a.createdAt, 10)
      );
      return {
        ...user,
        lastChatTime: sortedMessages[0]?.createdAt || user?.lastChatTime,
        lastMessageText: sortedMessages[0]?.text || '',
      };
    });

    setDisplayChatUsers(sortUsers(enhancedUsers));
  }, [receiver, recentMessages, currentUser?.id, messages]);

  return (
    <div className="msgContainer">
      {recentMessages && displayChatUsers?.length > 0 ? (
        displayChatUsers?.map((user, i) => {
          const userMessages = recentMessages?.filter(
            (message) =>
              message.chatId === user.ChatId
          );

          let lastMsg;
          let IslastMsgRead;
          let lastMsgReadBy;
          let lastChatTime = "";
          if (userMessages?.length > 0) {
            const lastMessage = userMessages[userMessages?.length - 1];
            const lastChatMsg = lastMessage.text;
            lastMsgReadBy =
              user?.LastMsgReadBy && user?.LastMsgReadBy !== currentUser.id
                ? true
                : false;

            IslastMsgRead =
              lastMessage.senderId !== currentUser?.id
                ? lastMessage.isLastMsgRead
                : false;
            const maxMessageLength = 25;
            lastMsg =
              lastChatMsg?.length > maxMessageLength
                ? lastChatMsg?.substring(0, maxMessageLength - 3) + "..."
                : lastChatMsg;

            const timestamp = parseInt(lastMessage?.createdAt, 10);
            lastChatTime = formatDateMsg(timestamp);

          }

          let lastMsgdb;
          let isLastMsgdbRead;
          let lastMsgReadByDb;
          const maxdbMessageLength = 25;
          if (user?.lastMessage) {
            lastMsgdb =
              user?.lastMessage?.length > maxdbMessageLength
                ? user?.lastMessage?.substring(0, maxdbMessageLength - 3) +
                "..."
                : user?.lastMessage;
            isLastMsgdbRead = user?.isLastMsgRead;
            lastMsgReadByDb =
              user?.LastMsgReadBy && user?.LastMsgReadBy !== currentUser.id
                ? true
                : false;
          }
          // console.log("lastMsgReadByDb", user.isLastMsgRead);

          let nameChar =
            user.accountType == "business"
              ? user?.userId?.charAt(0).toUpperCase()
              : user?.userId?.charAt(0).toUpperCase();

          const timestamp = parseInt(user?.lastChatTime, 10);
          let lastChatTimeDB = formatDateMsg(timestamp);
          const unreadCount = recentMessages.filter(
            (msg) => !msg?.isMsgAutoGenrated && msg?.senderId === user?.id && (!msg.isRead  || !user?.UnReadMsgCountByAdmin?.includes(currentUser?.id))
          ).length;



          let isChatBlurred = blurredChats.includes(user.id);
          return (
            <div
              className={`CustomerMessages ${isChatBlurred || lastMsgReadBy || lastMsgReadByDb
                ? "setbusy"
                : "setfree"
                } ${currentChat === user.id ? "active" : ""}`}
              onClick={() => {
                handleOpenChat(user);
                loadSelectedUserMessages(user?.id, lastMsg);
              }}
              key={i}
            >


              <div className="divOne">
                {nameChar == undefined ? "NA" : nameChar}

              </div>
              <div className="divTwo">
                <div className="upperDiv">

                  <h6 style={{ fontFamily: "Roboto" }}>
                    {user?.userId?.length > 10
                      ? `${user?.userId.slice(0, 4)}...`
                      : user?.userId || user?.id?.slice(0, 5)}
                    <span className="accountTypeTag"
                      style={{ backgroundColor: user?.accountType === 'business' ? "#ED4123" : "#40517D" }}>
                      ({user?.accountType?.toUpperCase().slice(0, 1)})
                    </span>
                    {/* {user?.isSessionExpired == false && */}
                    {/* // currentUser?.id !== user?.connectedTo && ( */}
                    {/* //   <span style={{ color: "red" }}>(busy)</span> */}
                    {/* // )} */}

                  </h6>
                  {/* <div className="infoContainer">
                    <span className="accountTypeTag"
                      style={{ backgroundColor: user?.accountType === 'business' ? "#ED4123" : "#40517D" }}>
                      {user?.accountType?.toUpperCase().slice(0,1)}
                    </span>
                     </div> */}

                  {(lastChatTime || lastChatTimeDB) && (
                    <p className="chatTime">{lastChatTime || lastChatTimeDB}</p>
                  )}


                </div>
                <div className="lowerDIv">
                  <Blur
                    radius={
                      isChatBlurred || lastMsgReadBy || lastMsgReadByDb
                        ? "5px"
                        : "0"
                    }
                    transition="400ms"
                  >
                    <p>{lastMsg || lastMsgdb}</p>
                  </Blur>
                  {currentChat !== user?.id && !getAllMessagesLoading &&
                    (unreadCount > 0 || (!user?.UnReadMsgCountByAdmin?.includes(currentUser?.id) && user?.UnReadMsgCount > 0)) && (
                      <div
                        className={`msgCount ${isChatBlurred || user.isLastMsgRead ? "BlurCount" : ""
                          } `}
                      >
                        <div className="msgRec">
                          {unreadCount || user?.UnReadMsgCount}
                        </div>
                      </div>
                    )}

                  <div>

                    {
                      <img
                        src={
                          user?.FavByAdmin?.includes(currentUser?.id)
                            ? require("../../../assets/icons/StarFilled.svg")
                                .default
                            : require("../../../assets/icons/StartEmpty.svg")
                                .default
                        }
                        alt="user"
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleFav(user);
                        }}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "25%",
          }}
        >
          <span
            style={{
              fontSize: "1rem",
              fontFamily: "Segoe UI",
              color: "#717171",
            }}
          >
            {showUnreadAndUnanswered
              ? "Ta-da! You’re up to date."
              : usersNotFoundMessage}
          </span>
        </div>
      )}
    </div>
  );
};

export default NewMessage;
