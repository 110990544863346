import React, { useState, useEffect, useRef } from "react";
import "./BusinessLicenseVerification.scss";
import Group_345 from "../../../assets/icons/Group_345.svg";
import Vector from "../../../assets/icons/Vector.svg";
import SearchIcon from "@mui/icons-material/Search";
import Container from "../../../components/HOC/Container";
import MessageComponents from "../../../components/MessageComponent/MessageComponent";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useLocation } from "react-router-dom";
import {
  getMessageCustomerAsync,
  getSearchBusinessAsync,
  selectedUserDetails,
  sendMessageToUser,
  setSelectedOption,
  // setSelectedOptions,
  updateBusinessAsync,
  UpdateStatusAsync,
  UpdatePhoneVerificationAsync,
} from "../../../redux/usersSlice";
import { getChatRoomsUser } from "../../../redux/chatSlice";
import { Tooltip } from "react-tooltip";

import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import Switch from "react-switch";
import NOdataFound from "../../../assets/icons/NoAdminData.svg";
import ThreeDot from "../../../assets/icons/threedot.svg";
import threedot_red from "../../../assets/icons/threedot_red.svg";

import BusinesssLicenseVerificationDialog from "../../../components/Dialogs/AdminPanelActionDialog/BusinessLicenceverificationDialog";
import Footer from "../../../components/Footer/Footer";
import { Constants } from "../../../utils/Constants";
// table --
import MessageIcon from "@mui/icons-material/Message";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import makeStyles from "@mui/styles/makeStyles";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-dropdown";
import avatar from "../../../assets/icons/avatar.svg";
import moment from "moment/moment";
import Socket from "../../../redux/socketConnect";
import DraggableImage from "../../../components/Dialogs/ViewFiles/Docs/DraggableImage";
import { SelectedRoute } from "../../../redux/adminManagementSlice";

function BusinessLicenseVerification() {
  let locations = useLocation();
  const receivedData = locations.state && locations.state.data;
  const [messageToggle, setMessageToggle] = useState(false);
  const SelectedBus_License_Veri_Email = useRef(null);
  const [writeMessage, setWriteMessage] = useState(false);
  const [checked, setChecked] = useState(false);
  const [message, setMessage] = useState("Write a message");
  const [page, setPage] = useState(1);
  const [loopId, setLoopId] = useState(null);
  const [state, setState] = useState(true);
  const [textMessage, setTextMessage] = useState("");
  const [messageHeading, setMessageHeading] = useState("Message 1");
  const [checkMessage, setCheckMessage] = useState(false);
  // const [isHighLight, setisHighLight] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [
    busLicenseVerificationDeleteData,
    setbusLicenseVerificationDeleteData,
  ] = useState(false);
  const BusLicenseVerificationhandleClose = () => {
    setbusLicenseVerificationDeleteData(false);
  };
  const [location, setLocation] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUserName] = useState("");
  const [address, setAddress] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [verificationData, setVerificationData] = useState([]);
  const [userId, setUserId] = useState(null);
  const [sendMessages, setSendMessage] = useState(false);
  const [recentUpdate, setrecentUpdate] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [filterDocsAccount, setfilterDocsAccount] = useState(false);
  const [LoggedInId, setLoggedInId] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);

  const classes = useStyles();
  const {
    newBussinessLicenseVerifactionData,
    newBussinessLicenseVerifactionLoading,
    updateBusinessLoading,
    deleteBusinessLoading,
    PaginationDataLength,
    startedRecords,
    toRecords,
    totalPages,
    updateMessage,
    selectedOption,
    sendMessageLoading
  } = useSelector((state) => state.users);

  ///-----------------------

  const { ChatRoomsUsers } = useSelector((state) => state.chat);

  const data = {
    location,
    email,
    username,
    address,
    isAdminApproved: 0,
    businessName,
    page: 1,
    backgroundColor: "#red !important",
    perPage: Constants.TAKE,
    sendMessages,
    // demo: 0,
  };
  // First time rendering

  useEffect(() => {
    let loggesInUser = JSON.parse(sessionStorage.getItem("userLoggedDetails"));
    setLoggedInId(loggesInUser);
  }, []);

  useEffect(() => {
    dispatch(getSearchBusinessAsync(data));
    dispatch(getMessageCustomerAsync());
  }, [dispatch]);

  //---------Handle pagination--
  const handlePagination = (event, value) => {
    data.page = value;
    dispatch(getSearchBusinessAsync(data));
  };
  // Filtering send messgaes
  // const get_send_Acccount = () => {
  //   console.log("Before state update:", sendMessages);
  //   setSendMessage(!sendMessages);
  //   dispatch(getSearchBusinessAsync(data));
  // };
  const AddNewData = () => {
    setCheckMessage(true);
  };

  const handleChangeSwitch = () => {
    setChecked(!checked);
    if (!checked) {
      setUsersData(
        newBussinessLicenseVerifactionData?.filter(
          (data) => data?.userToCustomiseMsgTblIdTbl === null
        )
      );
    } else {
      setUsersData(newBussinessLicenseVerifactionData);
    }
    // setMessageToggle(!messageToggle);
  };

  const get_recent_update = () => {
    setrecentUpdate(!recentUpdate);
    // dispatch(getSearchCustomerAsync(data, recentUpdate));
    if (!recentUpdate) {
      setUsersData(
        newBussinessLicenseVerifactionData?.filter(
          (data) => data?.isReview === false
        )
      );
    } else {
      setUsersData(newBussinessLicenseVerifactionData);
    }
  };

  useEffect(() => {
    setUsersData(newBussinessLicenseVerifactionData);
  }, [newBussinessLicenseVerifactionData]);

  const approveBusiness = (id) => {
    const data = {
      accountType: "business",
      id: id,
      isAdminApproved: true,
      isReview: true,
    };

    dispatch(UpdateStatusAsync(data));
  };

  // const verifiedPhone = async (id, isCurrentlyVerified) => {
  //   const changeStatus = {
  //     accountType: "business",
  //     id: id,
  //     isPhoneVerified: !isCurrentlyVerified, // Toggle verification status
  //   };

  //   // Await the result of the async actions
  //   await dispatch(UpdatePhoneVerificationAsync(changeStatus));
  //   // dispatch(getSearchBusinessAsync(data));
  // };

  const handleChangeBusinessAccount = () => {
    setMessageToggle(!messageToggle);
  };
  const GetMessages = () => {
    dispatch(getMessageCustomerAsync());
  };
  const SaveMessage = () => {
    setWriteMessage(false);
  };

  const handleDropdownChange = async (selected, item, index) => {
    let SelectMsg = updateMessage?.filter(
      (data) => data?.id === selected?.value
    );
    setRefreshKey((prevKey) => prevKey + 1);
    setUserId(item?.id);
    selected.userId = item?.id;
    selected.msg = SelectMsg[0]?.message;
    // dispatch(setSelectedOptions(selected));
    dispatch(setSelectedOption(selected));
    const userData = {
      selectedOption: selected,
      userID: item?.id,
    };
    localStorage.setItem("selectedOption", JSON.stringify(userData));
    const Data = {
      IdOfUser: item?.id,
      CustomMagId: selected?.value,
      accountType: "business",
      msg: SelectMsg[0]?.message,
      setnull: selected?.label === "None" ? "None" : null,
    };
    let loggesInUser = JSON.parse(sessionStorage.getItem("userLoggedDetails"));

    if (
      selected?.label !== "None" ||
      item?.userToCustomiseMsgTblIdTbl !== null
    ) {
      dispatch(sendMessageToUser(Data)).then((r) => {
        if (r?.data?.success === true) {
          // dispatch(getSearchBusinessAsync(data));
           dispatch(getChatRoomsUser(loggesInUser?.id, item?.id, "admin")).then(
            (res) => {
              if (res && Data) {
                const msg = {
                  chatId: res?.id || ChatRoomsUsers?.id,
                  senderId: loggesInUser?.id,
                  receiverId: Data?.IdOfUser,
                  text: SelectMsg[0]?.message,
                  isSenderAdmin: true,
                  isRead: true,
                };
                if (msg.chatId && msg.senderId && msg.text) {
                  Socket.onSend(msg, dispatch);
                }
              }
            }
          );
        } else {
          window.alert("Unable to send message");
        }
      });
    }
  };

  const FilterWithoutDocs = () => {
    setfilterDocsAccount(!filterDocsAccount);
    if (!filterDocsAccount) {
      setUsersData(
        newBussinessLicenseVerifactionData?.filter(
          (data) => data?.ShowDocsUrl === null
        )
      );
    } else {
      setUsersData(newBussinessLicenseVerifactionData);
    }
  };
  const Prev_message = (item) => {
    const index = updateMessage?.findIndex(
      (m) => m.id === item?.userToCustomiseMsgTblIdTbl?.IdOfCustomiseMsg
    );
    return index.toString();
  };
  const editFunction = (text) => {
    setLoopId(text?.id);
    setTextMessage(text?.message);
    setWriteMessage(true);
    // handleChangeBusinessAccount();
  };
  const dropdownOptions = updateMessage?.map(
    (message, index) => ({
      label: `${index === 0 ? "None" : index}`,
      value: message?.id,
      msg: message?.message,
      data: { id: message?.id },
      storageKey: `selectedOption_${index}`,
    }),
    console.log("dropdwn msg....", message)
  );
  // const DeleteItem = (item) => {
  //   SelectedBus_License_Veri_Email.current = item?.id;
  //   setbusLicenseVerificationDeleteData(true);
  // };
  const GoToChat = (item) => {
    dispatch(SelectedRoute("/Messages"));
    navigate("/Messages", {
      state: item,
    });
  };
  const SearchQuery = (e) => {
    setLocation("");
    setUserName("");
    setAddress("");
    setEmail("");
    setBusinessName(e.target.value);
  };
  const getDataByQuery = () => {
    dispatch(getSearchBusinessAsync(data));
  };
  const Verification_docs = (id, item) => {
    setOpenDialog(true);
    setVerificationData(item);
    const changeStatus = {
      accountType: "business",
      id: id,
      isAdminApproved: false,
      isReview: true,
    };
    dispatch(UpdateStatusAsync(changeStatus));
  };

  const NavigateToProfile = (item) => {
    dispatch(selectedUserDetails(item));
    navigate("/UserProfile/UserProfile");
    const data = {
      accountType: "business",
      id: item?.id,
      isAdminApproved: false,
      isReview: true,
    };
    dispatch(UpdateStatusAsync(data));
  };
  // broken image functionality

  const FailedToLoad = (e) => {
    console.log("not opened");
    e.target.src = avatar;
  };
  const formatDate = (timestamp) => {
    const today = moment().startOf("day");
    const messageDate = moment(parseInt(timestamp, 10));
    if (messageDate.isSame(today, "day")) {
      return "Today";
    } else if (messageDate.isSame(today.clone().subtract(1, "day"), "day")) {
      return "Yesterday";
    } else {
      return messageDate.format("DD MMMM YYYY");
    }
  };
  const numberOfColumns = 12;

  return (
    <Container>
      {newBussinessLicenseVerifactionLoading ||
      updateBusinessLoading ||
      deleteBusinessLoading || sendMessageLoading? (
        <Loader />
      ) : null}

      <div className="BusinessLicenseMainConatiner p-2">
        {/* Condition remember */}
        {openDialog && (
          <DraggableImage
            openDialog={openDialog}
            verificationData={verificationData}
            setOpenDialog={setOpenDialog}
          />
        )}
        <div
          className={
            !messageToggle
              ? "inner-containerForBusinessLicence"
              : "inner-containerForBusinessLicence_with_message"
          }
        >
          <div className="HeadingAndSearchNewCustomer">
            <div className="Left_Details">
              <h1>New Business account verification</h1>
              <div className="Header_Common">
                <Switch
                  onChange={() =>
                    newBussinessLicenseVerifactionData?.filter(
                      (item) => item?.userToCustomiseMsgTblIdTbl !== null
                    )?.length >= 1
                      ? handleChangeSwitch()
                      : window.alert("No account with send message")
                  }
                  checked={checked}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={22}
                  width={47}
                />
                <p className="ml-2 mr-1">Hide Account with send messages</p>
              </div>

              <div className="Header_Common">
                <Switch
                  onChange={get_recent_update}
                  checked={recentUpdate}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={22}
                  width={47}
                />
                <p className="ml-2"> Recent Update</p>
              </div>

              <div className="Header_Common">
                <Switch
                  onChange={() =>
                    newBussinessLicenseVerifactionData?.filter(
                      (data) => data?.ShowDocsUrl !== null
                    )?.length >= 1
                      ? FilterWithoutDocs()
                      : window.alert("All the account without docs uploaded ")
                  }
                  // onChange={FilterWithoutDocs}
                  checked={filterDocsAccount}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={22}
                  width={47}
                />
                <p className="ml-2"> Without Docs</p>
              </div>
            </div>
            <div className="Icon-containerNewCustomer">
              <div className="Input-and-iconsNewCustomer ">
                <SearchIcon
                  onClick={getDataByQuery}
                  className="searchIconNewCustomer"
                />
                <input
                  onChange={SearchQuery}
                  onKeyDown={(event) => {
                    event.key === "Enter" && getDataByQuery();
                  }}
                  className="Input-fieldNewCustomer"
                  type="text"
                  placeholder="Search name"
                />
              </div>
              <MessageIcon
                onClick={() => {
                  handleChangeBusinessAccount();
                  GetMessages();
                }}
                style={{
                  height: "40px",
                  width: "40px",
                }}
              />
            </div>
          </div>
          {usersData?.length === 0 && !newBussinessLicenseVerifactionLoading ? (
            <div className="NoAdminDataForBusLicenceVerify">
              <img src={NOdataFound} alt="NO DATA FOUND" />
              <div className="NoAdminDataForBusLicenceVerify">
                <h1>No Business Users Available</h1>
                <h3>
                  Currently there are no new business users for verification.
                </h3>
              </div>
            </div>
          ) : (
            <>
              <TableContainer
                sx={{ height: "calc( 100vh - 70px)", marginTop: "1rem" }}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow className={classes.root}>
                      <TableCell>
                        <span style={{ display: "flex", alignItems: "center" }}>
                          {/* <Checkbox checked={checked1}
                            onChange={toggleSelectAll} /> */}
                          <p className="Header_Blicence_verification">
                            Business Name
                          </p>
                          <img
                            style={{ width: 14, marginLeft: "0.2rem" }}
                            src={Group_345}
                            alt="Group_345"
                          />
                        </span>
                      </TableCell>
                      <TableCell>
                        <span style={{ display: "flex" }}>
                          <p className="Header_Blicence_verification">Date</p>
                          <img
                            style={{ width: 14, marginLeft: "0.2rem" }}
                            src={Group_345}
                            alt="Group_345"
                          />
                        </span>
                      </TableCell>
                      <TableCell>
                        <span style={{ display: "flex" }}>
                          <p className="Header_Blicence_verification">Phone</p>
                          <img
                            style={{ width: 14, marginLeft: "0.2rem" }}
                            src={Group_345}
                            alt="Group_345"
                          />
                        </span>
                      </TableCell>
                      <TableCell>
                        <span style={{ display: "flex" }}>
                          <p className="Header_Blicence_verification">
                            Location
                          </p>
                          <img
                            style={{ width: 14, marginLeft: "0.2rem" }}
                            src={Group_345}
                            alt="Group_345"
                          />
                        </span>
                      </TableCell>
                      <TableCell>
                        <span style={{ display: "flex" }}>
                          <p className="Header_Blicence_verification">
                            Address
                          </p>
                          <img
                            style={{ width: 14, marginLeft: "0.2rem" }}
                            src={Group_345}
                            alt="Group_345"
                          />
                        </span>
                      </TableCell>
                      <TableCell>
                        <span style={{ display: "flex" }}>
                          <p className="Header_Blicence_verification">
                            Email Address
                          </p>
                          <img
                            style={{ width: 14, marginLeft: "0.2rem" }}
                            src={Group_345}
                            alt="Group_345"
                          />
                        </span>
                      </TableCell>
                      <TableCell>
                        <span style={{ display: "flex" }}>
                          <p className="Header_Blicence_verification">
                            Videos/Pic Files
                          </p>
                          <img
                            style={{ width: 14, marginLeft: "0.5rem" }}
                            src={Group_345}
                            alt="Group_345"
                          />
                        </span>
                      </TableCell>
                      {/* <TableCell>
                        <span style={{ display: "flex" }}>
                          <p className="Header_Blicence_verification">
                            Phone text verification
                          </p>
                          <img
                            style={{ width: 14, marginLeft: "0.2rem" }}
                            src={Group_345}
                            alt="Group_345"
                          />
                        </span>
                      </TableCell> */}
                      <TableCell>
                        <span style={{ display: "flex" }}>
                          <p className="Header_Blicence_verification">
                            Verified
                          </p>
                          <img
                            style={{ width: 14, marginLeft: "0.2rem" }}
                            src={Group_345}
                            alt="Group_345"
                          />
                        </span>
                      </TableCell>
                      <TableCell>
                        <span style={{ display: "flex" }}>
                          <p className="Header_Blicence_verification">
                            Send Message
                          </p>
                          <img
                            style={{ width: 14, marginLeft: "0.2rem" }}
                            src={Group_345}
                            alt="Group_345"
                          />
                        </span>
                      </TableCell>
                      <TableCell>
                        <span style={{ display: "flex" }}>
                          <p className="Header_Blicence_verification">
                            Actions
                          </p>
                          <img
                            style={{ width: 14, marginLeft: "0.2rem" }}
                            src={Group_345}
                            alt="Group_345"
                          />
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {usersData
                      ?.filter(
                        (item) => item?.userToCustomiseMsgTblIdTbl === null
                      )
                      .map((item, i) => (
                        <TableRow
                          className={
                            !item?.isReview &&
                            (item?.ShowDocsUrl?.length > 0 ||
                              item?.DownloadDocsUrl?.length > 0)
                              ? classes.notReview
                              : classes.bodyroot
                          }
                          hover
                          role="checkbox"
                        >
                          <TableCell key={i}>
                            <span className="flex  justify-start items-center  ">
                              {/* <Checkbox
                              checked={checkboxStates[item?.userId] || checked1}
                              onChange={() => toggleCheckbox(item, i)} /> */}
                              <img
                                style={{
                                  cursor: "pointer",
                                  width: "2rem",
                                  height: "2rem",
                                  borderRadius: "50%",
                                  border: "1px solid grey",
                                }}
                                src={
                                  item?.image &&
                                  (item.image.includes(".jpg") ||
                                    item.image.includes(".jpeg") ||
                                    item.image.includes(".png"))
                                    ? `https://${item?.image}`
                                    : avatar
                                }
                                alt="user"
                                onError={FailedToLoad}
                                onClick={() => NavigateToProfile(item)}
                              />

                              <span className=" FirstClildBusiness">
                                <span
                                  onClick={() => NavigateToProfile(item)}
                                  className="underLineTextBusinessLicence "
                                >
                                  {item?.userId}
                                </span>
                                <span className=" returingDataBusLicence">
                                  {item?.firstName} {item?.lastName}
                                </span>
                              </span>
                            </span>
                          </TableCell>
                          <TableCell>
                            <span className="returingDataBusLicenceDate">
                              {formatDate(item?.createdAt * 1000)}
                            </span>
                          </TableCell>
                          <TableCell>
                            <span className="returingDataBusLicence">
                              {item?.phoneNumber}
                            </span>
                          </TableCell>
                          <TableCell>
                            <span className="flex  items-center ">
                              {item?.location?.map((data, i) => (
                                <>
                                  <span className="returingDataBusLicence">
                                    {data}
                                  </span>
                                  {data?.length !== 0 ? null : (
                                    <div className="carbDiv">
                                      {item?.location?.length - 1}
                                    </div>
                                  )}
                                </>
                              ))}
                            </span>
                          </TableCell>

                          <TableCell>
                            <p className="returingDataBusLicence">
                              {item?.streetAddress}
                            </p>
                          </TableCell>
                          <TableCell>
                            <span className="returingDataBusLicence">
                              {item?.emailAddress}
                            </span>
                          </TableCell>
                          {item?.ShowDocsUrl?.length > 0 ? (
                            <TableCell
                              onClick={() =>
                                Verification_docs(item?.id, item?.ShowDocsUrl)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <span
                                className="returingDataBusLicence"
                                style={{
                                  borderBottom: "2px solid blue",
                                  padding: "0.2rem 0",
                                }}
                              >
                                pics
                              </span>
                            </TableCell>
                          ) : (
                            <TableCell>
                              <span
                                className="returingDataBusLicence"
                                style={{ padding: "0.2rem 0" }}
                              >
                                No Docs
                              </span>
                            </TableCell>
                          )}
                          {/* <TableCell>
                            <Switch
                              onChange={() =>
                                verifiedPhone(item.id, item.isPhoneVerified)
                              }
                              checked={item.isPhoneVerified}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              height={22}
                              width={47}
                            />
                          </TableCell> */}
                          <TableCell>
                            <Switch
                              onChange={() => approveBusiness(item.id)}
                              checked={item.isAdminApproved}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              height={22}
                              width={47}
                              // disabled={item.isPhoneVerified == false}
                            />
                          </TableCell>
                          <TableCell
                            data-tooltip-id="my-tooltip1"
                            data-tooltip-content={
                              item?.connectedTo &&
                              item?.connectedTo !== LoggedInId?.id
                                ? "This user is engaged with another admin at the moment"
                                : null
                            }
                          >
                            <Dropdown
                               key={`dropdown-${refreshKey}`}
                              className={`myClassName dropdown-up ${
                                item?.connectedTo &&
                                item?.connectedTo !== LoggedInId?.id
                                  ? "dropdownDisabled"
                                  : ""
                              }`}
                              options={dropdownOptions}
                              onChange={(selectedValue) =>
                                handleDropdownChange(selectedValue, item, i)
                              }
                              value={
                                item?.userToCustomiseMsgTblIdTbl
                                  ?.IdOfCustomiseMsg
                                  ? Prev_message(item)
                                  : "None"
                              }
                              controlClassName="myControlClassName"
                              menuClassName="myDropdownMenuClass"
                            />
                            {item?.connectedTo &&
                            item?.connectedTo !== LoggedInId?.id ? (
                              <Tooltip
                                id="my-tooltip1"
                                style={{
                                  backgroundColor: "#38ab7c",
                                  zIndex: 100,
                                }}
                              />
                            ) : null}
                          </TableCell>

                          <TableCell
                            data-tooltip-id="my-tooltip1"
                            data-tooltip-content={
                              item?.connectedTo &&
                              item?.connectedTo !== LoggedInId?.id
                                ? "This user is engaged with another admin at the moment"
                                : null
                            }
                          >
                            <img
                              onClick={() =>
                                item?.connectedTo &&
                                item?.connectedTo !== LoggedInId?.id
                                  ? null
                                  : GoToChat(item)
                              }
                              style={{
                                marginLeft: "0.5rem",
                                cursor: "pointer",
                              }}
                              src={
                                !item?.isLastMsgRead &&
                                item?.isLastMsgRead === false
                                  ? threedot_red
                                  : ThreeDot
                              }
                              alt="More"
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    {/* Red line */}
                    {usersData?.filter(
                      (item) => item?.userToCustomiseMsgTblIdTbl === null
                    )?.length != 0 &&
                    usersData?.filter(
                      (item) => item?.userToCustomiseMsgTblIdTbl !== null
                    )?.length >= 1 ? (
                      <TableRow>
                        <TableCell colSpan={numberOfColumns}>
                          <hr
                            style={{ border: "1px solid red", width: "120%" }}
                          />
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {/* redline ended */}
                    {usersData
                      ?.filter(
                        (item) => item?.userToCustomiseMsgTblIdTbl !== null
                      )
                      .map((item, i) => (
                        <TableRow
                          className={
                            !item?.isReview &&
                            (item?.ShowDocsUrl?.length > 0 ||
                              item?.DownloadDocsUrl?.length > 0)
                              ? classes.notReview
                              : classes.bodyroot
                          }
                          hover
                          role="checkbox"
                        >
                          <TableCell key={i}>
                            <span className="flex  justify-start items-center  ">
                              {/* <Checkbox
                                checked={checkboxStates[item?.userId] || checked1}
                                onChange={() => toggleCheckbox(item, i)} /> */}
                              <img
                                style={{
                                  cursor: "pointer",
                                  width: "2rem",
                                  height: "2rem",
                                  borderRadius: "50%",
                                  border: "1px solid grey",
                                }}
                                src={
                                  item?.image &&
                                  (item.image.includes(".jpg") ||
                                    item.image.includes(".jpeg") ||
                                    item.image.includes(".png"))
                                    ? `https://${item?.image}`
                                    : avatar
                                }
                                alt="user"
                                onError={FailedToLoad}
                                onClick={() => NavigateToProfile(item)}
                              />

                              <span className=" FirstClildBusiness">
                                <span
                                  onClick={() => NavigateToProfile(item)}
                                  className="underLineTextBusinessLicence "
                                >
                                  {item?.userId}
                                </span>
                                <span className=" returingDataBusLicence">
                                  {item?.firstName} {item?.lastName}
                                </span>
                              </span>
                            </span>
                          </TableCell>
                          <TableCell>
                            <span className="returingDataBusLicenceDate">
                              {formatDate(item?.createdAt * 1000)}
                            </span>
                          </TableCell>
                          <TableCell>
                            <span className="returingDataBusLicence">
                              {item?.phoneNumber}
                            </span>
                          </TableCell>
                          <TableCell>
                            <span className="flex  items-center ">
                              {item?.location?.map((data, i) => (
                                <>
                                  <span className="returingDataBusLicence">
                                    {data}
                                  </span>
                                  {data?.length !== 0 ? null : (
                                    <div className="carbDiv">
                                      {item?.location?.length - 1}
                                    </div>
                                  )}
                                </>
                              ))}
                            </span>
                          </TableCell>

                          <TableCell>
                            <p className="returingDataBusLicence">
                              {item?.streetAddress}
                            </p>
                          </TableCell>
                          <TableCell>
                            <span className="returingDataBusLicence">
                              {item?.emailAddress}
                            </span>
                          </TableCell>
                          {item?.ShowDocsUrl?.length > 0 ? (
                            <TableCell
                              onClick={() =>
                                Verification_docs(item?.id, item?.ShowDocsUrl)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <span
                                className="returingDataBusLicence"
                                style={{
                                  borderBottom: "2px solid blue",
                                  padding: "0.2rem 0",
                                }}
                              >
                                pics
                              </span>
                            </TableCell>
                          ) : (
                            <TableCell>
                              <span
                                className="returingDataBusLicence"
                                style={{ padding: "0.2rem 0" }}
                              >
                                No Docs
                              </span>
                            </TableCell>
                          )}
                          {/* <TableCell>
                            <Switch
                              onChange={() =>
                                verifiedPhone(item.id, item.isPhoneVerified)
                              }
                              checked={item.isPhoneVerified}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              height={22}
                              width={47}
                            />
                          </TableCell> */}
                          <TableCell>
                            <Switch
                              onChange={() => approveBusiness(item.id)}
                              checked={item.isAdminApproved}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              height={22}
                              width={47}
                              // disabled={item.isPhoneVerified == false}
                            />
                          </TableCell>
                          <TableCell
                            data-tooltip-id="my-tooltip1"
                            data-tooltip-content={
                              item?.connectedTo &&
                              item?.connectedTo !== LoggedInId?.id
                                ? "This user is engaged with another admin at the moment"
                                : null
                            }
                          >
                            <Dropdown
                              key={`dropdown-${refreshKey}`}
                              className={`myClassName dropdown-up ${
                                item?.connectedTo &&
                                item?.connectedTo !== LoggedInId?.id
                                  ? "dropdownDisabled"
                                  : ""
                              }`}
                              options={dropdownOptions}
                              onChange={(selectedValue) =>
                                handleDropdownChange(selectedValue, item, i)
                              }
                              value={
                                item?.userToCustomiseMsgTblIdTbl
                                  ?.IdOfCustomiseMsg
                                  ? Prev_message(item)
                                  : "None"
                              }
                              controlClassName="myControlClassName"
                            />
                            {item?.connectedTo &&
                            item?.connectedTo !== LoggedInId?.id ? (
                              <Tooltip
                                id="my-tooltip1"
                                style={{
                                  backgroundColor: "#38ab7c",
                                  zIndex: 100,
                                }}
                              />
                            ) : null}
                          </TableCell>
                          <TableCell
                            data-tooltip-id="my-tooltip1"
                            data-tooltip-content={
                              item?.connectedTo &&
                              item?.connectedTo !== LoggedInId?.id
                                ? "This user is engaged with another admin at the moment"
                                : null
                            }
                          >
                            <img
                              onClick={() =>
                                item?.connectedTo &&
                                item?.connectedTo !== LoggedInId?.id
                                  ? null
                                  : GoToChat(item)
                              }
                              style={{
                                marginLeft: "0.5rem",
                                cursor: "pointer",
                              }}
                              src={
                                !item?.isLastMsgRead &&
                                item?.isLastMsgRead === false
                                  ? threedot_red
                                  : ThreeDot
                              }
                              alt="More"
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </div>
        <div
          className={messageToggle ? "MessageSidebar" : "MessageSidebarNone"}
        >
          <div className="MessageTopHeading">
            <p style={{ fontFamily: "Manrope" }}>
              Message next to pic for new customers
            </p>
            <div>
              <ArrowForwardIcon
                onClick={handleChangeBusinessAccount}
                style={{ fontSize: 20, cursor: "pointer" }}
              />
            </div>
          </div>
          <div className="BottomContainer">
            {updateMessage?.map((text, index) => (
              <div className="messageContainer">
                <div className="MessageBox">
                  <p style={{ fontFamily: "Manrope" }}>
                    {index === 0 ? "None" : "Message" + index}
                    <ErrorOutlineIcon style={{ fontSize: 20, marginLeft: 2 }} />
                  </p>
                  <div>
                    <DriveFileRenameOutlineOutlinedIcon
                      onClick={() => {
                        editFunction(text);
                        // handleChangeBusinessAccount();
                      }}
                      style={{
                        fontSize: 25,
                        backgroundColor: "#e2e1e8",
                        color: "#6c6c73",
                        borderRadius: 5,
                        padding: 2,
                        marginTop: 5,
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
                <div className="Messages">
                  <p style={{ fontFamily: "Manrope" }}>{text.message}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="Add_more_text_container">
            <button onClick={AddNewData} className="Button-Message">
              + Add more
            </button>
          </div>
        </div>
      </div>
      {PaginationDataLength > Constants.TAKE ? (
        <Footer
          page={page}
          handleChange={handlePagination}
          totalRecord={PaginationDataLength}
          startedRecords={startedRecords}
          toRecords={toRecords}
          totalPages={totalPages}
        />
      ) : null}

      <BusinesssLicenseVerificationDialog
        busLicenseVerificationDeleteData={busLicenseVerificationDeleteData}
        setbusLicenseVerificationDeleteData={
          setbusLicenseVerificationDeleteData
        }
        BusLicenseVerificationhandleClose={BusLicenseVerificationhandleClose}
        SelectedBus_License_Veri_Email={SelectedBus_License_Veri_Email}
      />
      {writeMessage ? (
        <MessageComponents
          setWriteMessage={setWriteMessage}
          SaveMessage={SaveMessage}
          setMessage={setMessage}
          setMessageHeading={setMessageHeading}
          loopId={loopId}
          setState={setState}
          Message={textMessage}
          // messageFunction={}
        />
      ) : null}
      {checkMessage ? (
        <MessageComponents
          setCheckMessage={setCheckMessage}
          SaveMessage={SaveMessage}
          setMessage={setMessage}
          setMessageHeading={setMessageHeading}
          // messageFunction={}
        />
      ) : null}
      {/* <Docs
        verificationData={verificationData}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
      /> */}
    </Container>
  );
}

export default BusinessLicenseVerification;
const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "#fff  !important",
      height: "28px",
      padding: "0.3rem 0.8rem",
    },
  },
  bodyroot: {
    "&:hover": {
      backgroundColor: "#c5d9cf !important",
    },
    "& .MuiTableCell-body": {
      height: "28px",
      padding: "0.2rem 0.8rem",
    },
  },
  notReview: {
    backgroundColor: "#b6dfca !important",
    "&:hover": {
      backgroundColor: "#c5d9cf !important",
    },
    "& .MuiTableCell-body": {
      height: "28px",
      padding: "0.2rem 0.8rem",
    },
  },
});
