import axios from "axios";
import { ApiConnection } from "../config/env";

const API_TIMEOUT = 200 * 60 * 1000; // 5 minutes
let lastUserActivityTimestamp = Date.now();
let timeoutRef = null;

const dispatchToggleSessionAlert = () => {
    import("../redux/store").then(({ default: store }) => {
        import("../redux/authSlice").then(({ ToggleSessionAlert }) => {
            store.dispatch(ToggleSessionAlert(true));
        });
    });
};

// Function to reset session timeout based on user activity
const resetSessionTimeout = () => {
    let loggedInUser = JSON.parse(sessionStorage.getItem("userLoggedDetails"));
    if (!loggedInUser) return;

    if (timeoutRef) clearTimeout(timeoutRef);

    timeoutRef = setTimeout(() => {
        const timeSinceLastActivity = Date.now() - lastUserActivityTimestamp;
        if (loggedInUser?.id && timeSinceLastActivity >= API_TIMEOUT) {
            dispatchToggleSessionAlert();
        }
    }, API_TIMEOUT);
};

// Function to track user activity
const trackUserActivity = () => {
    lastUserActivityTimestamp = Date.now();
    resetSessionTimeout();
};

// Attach event listeners for user activity
const startTrackingUserActivity = () => {
    document.addEventListener("mousemove", trackUserActivity);
    document.addEventListener("keydown", trackUserActivity);
    document.addEventListener("click", trackUserActivity);
    resetSessionTimeout(); // Start the timeout when tracking begins
};

// Remove event listeners when session ends
const stopTrackingUserActivity = () => {
    document.removeEventListener("mousemove", trackUserActivity);
    document.removeEventListener("keydown", trackUserActivity);
    document.removeEventListener("click", trackUserActivity);
    clearTimeout(timeoutRef);
};

// Start tracking user activity when the session begins
startTrackingUserActivity();

// Create an Axios instance
const axiosMultipartInstance = axios.create({
    baseURL: ApiConnection.BACKEND_URL,
});

// Add a request interceptor (API calls no longer affect session timeout)
axiosMultipartInstance.interceptors.request.use(
    (config) => {
        const token = sessionStorage.getItem("userLoggedToken");
        if (token) {
            config.headers["Authorization"] = "Bearer " + JSON.parse(token);
        }
        config.headers["Content-Type"] = "multipart/form-data";
        return config;
    },
    (error) => {
        console.error("Error in request", error);
        return Promise.reject(error);
    }
);

// Add a response interceptor (API calls no longer affect session timeout)
axiosMultipartInstance.interceptors.response.use(
    (response) => {
        console.log("API Response:", response);
        return response;
    },
    (error) => {
        console.error("Error in response", error);
        return Promise.reject(error);
    }
);

export default axiosMultipartInstance;
