import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  socket: null,
  messages: [],
  userId: null,


  // unreadMessageCounts: {},
};

const socketSlice = createSlice({
  name: "socket",
  initialState,
  reducers: {
    connectSocket: (state, action) => {
      state.socket = action.payload;
      state.userId = action.payload.userId;
      console.log("Connected to socket: " + action.payload.userId);
    },
    disconnectSocket: (state) => {
      state.socket = null;
    },
    receiveMessage: (state, action) => {
      console.log("Received msgs from server", action.payload);
      // if()
      state.messages.push(...action.payload);
    },
    incrementUnreadMessageCount: (state, action) => {
      console.log("incrementUnreadMessageCount", action.payload);
      const { userId } = action.payload;
      state.unreadMessageCounts[userId] =
        (state.unreadMessageCounts[userId] || 0) + 1;
    },
    
  },
});

export const {
  connectSocket,
  disconnectSocket,
  receiveMessage,
  incrementUnreadMessageCount,
} = socketSlice.actions;
export default socketSlice.reducer;
