import React, { useState, useRef, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import { useSelector, useDispatch } from "react-redux";
import "./EmailVerification.scss";
import {
  updateMAdmin,
  updateMAdminUserId,
} from "../../../redux/adminManagementSlice";
import { toast } from "react-toastify";
import Loader from "../../Loader";
import Swal from "sweetalert2";

const EmailVerification = ({
  emailOpen,
  setEmailOpen,
  role,
  isUser,
  userIdOpen,
  setUserIdOpen,
}) => {
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state) => state.auth);
  const { SelectedUUID, getAllAdminLoading } = useSelector(
    (state) => state.adminManage
  );
  const [newEmail, setNewEmail] = useState("");
  const [newUserId, setNewUserId] = useState("");
  const [currentEmail, setcurrentEmail] = useState("");
  const [currentUserId, setcurrentUserId] = useState("");
  const [openOTP, setOpenOTP] = useState(false);
  const [otp, setOtp] = useState({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
  });

  // Create references for each OTP input field
  const otpRefs = useRef([]);
  const handleChange = (e, index) => {
    const { value } = e.target;
    setOtp((prevOtp) => ({ ...prevOtp, [`otp${index + 1}`]: value }));
    if (value.length === 1 && index < otpRefs.current.length - 1) {
      otpRefs.current[index + 1].focus();
    }
  };

  const handleClose = () => {
    setNewEmail("");
    setcurrentEmail("");
    setNewUserId("");
    setcurrentUserId("");
    if (isUser) {
      setUserIdOpen(false);
    } else {
      setEmailOpen(false);
    }
  };

  //validate email
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };
  // const ChangeEmail = (e) => {
  //   if (e.target.value?.length > 0 && !validateEmail(e.target.value)) {
  //     Swal.fire({
  //       title: "Error!",
  //       text: "Invalid email address",
  //       icon: "error",
  //       confirmButtonText: "OK",
  //     });
  //   } else {
  //     const finalemail = validateEmail(e.target.value);
  //     setcurrentEmail(finalemail);
  //   }
  // };

  const ChangeEmail = (e) => {
    setcurrentEmail(e.target.value);
  };

  const NewEmail = (e) => {
    setNewEmail(e.target.value);
  };

  const ChangeUserId = (e) => {
    setcurrentUserId(e.target.value);
  };
  const NewUserId = (e) => {
    setNewUserId(e.target.value);
  };

  const Validation = () => {
    if (!currentEmail.length > 0) {
      toast.error("Please Enter current email ");
      return false;
    } else if (!validateEmail(currentEmail)) {
      toast.error("Please Enter valid email ");
      return false;
    } else if (!newEmail?.trim()?.length > 0) {
      toast.error("Please Enter new email");
      return false;
    } else if (!validateEmail(newEmail)) {
      toast.error("Please Enter valid new email");
      return false;
    } else {
      return true;
    }
  };

  const addEmail = () => {
    if (Validation()) {
      const data = {
        id: userDetails?.id,
        isShowPrimaryAccount: null,
        currentEmail: currentEmail.trim(),
        newEmail: newEmail.trim(),
      };
      setEmailOpen(false);
      dispatch(updateMAdmin(data));
      setEmailOpen(false);
      setNewEmail("");
      setcurrentEmail("");
    }
  };
  const updateUserId = () => {
    if (!currentUserId.trim() && !newUserId.trim()) {
      toast.error("Please enter all the field's");
    } else {
      const data2 = {
        id: SelectedUUID,
        oldUserId: currentUserId.trim(),
        newUserId: newUserId.trim(),
      };
      dispatch(updateMAdminUserId(data2)).then((res) => {
        if (res?.data?.currentState === 1) {
          setUserIdOpen(false);
          setOpenOTP(true);
        }
      });
    }
  };
  // Verifing OTP
  const VerifyOTP = (e) => {
    e.preventDefault();
    const fullOtp = `${otp.otp1}${otp.otp2}${otp.otp3}${otp.otp4}${otp.otp5}`;
    let data = {
      OTP: fullOtp,
      newUserId: newUserId,
      oldUserId: currentUserId,
    };

    if (fullOtp?.length < 5) {
      toast.error("Please enter OTP");
    } else {
      dispatch(updateMAdminUserId(data)).then((res) => {
        if (res?.data?.currentState === 2) {
          setOpenOTP(false);
          setNewUserId("");
          setcurrentUserId("");
          // Reset the OTP fields
          setOtp({
            otp1: "",
            otp2: "",
            otp3: "",
            otp4: "",
            otp5: "",
          });
          // Optionally, reset focus to the first OTP input
          otpRefs.current[0]?.focus();
        }
      });
    }
  };

  return (
    <>
      {getAllAdminLoading && <Loader />}
      <Dialog open={emailOpen || userIdOpen} onClose={handleClose}>
        <div className="EmailVerifyDiv">
          <h1>
            {role
              ? "Email for code verification"
              : isUser
              ? "Change UserId"
              : "Change Email"}
          </h1>
          <div className="emailDiv">
            <div className="emails">
              {isUser ? <p>Current UserId</p> : <p>Current Email</p>}
              <input
                type="text"
                value={currentEmail || currentUserId}
                onChange={
                  !isUser ? (e) => ChangeEmail(e) : (e) => ChangeUserId(e)
                }
                placeholder={isUser ? "Ex123#" : "current@example.com"}
              />
            </div>
            <div className="emails">
              <p>New Email</p>
              <input
                type="text"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                placeholder="Enter Email"
              />
            </div>
          </div>
          <div className="Buttons">
            <button onClick={handleClose} className="button1">
              Cancel
            </button>
            <button
              onClick={isUser ? updateUserId : addEmail}
              className="button2"
            >
              Save
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog open={openOTP}>
        <form className="otp-Form">
          <span className="mainHeading">Enter OTP</span>
          <p className="otpSubheading">
            We have sent a verification code to your registered email
          </p>
          <div className="inputContainer">
            {[0, 1, 2, 3, 4].map((index) => (
              <input
                key={index}
                required
                maxLength="1"
                type="text"
                className="otp-input"
                id={`otp${index + 1}`}
                value={otp[`otp${index + 1}`]}
                onChange={(e) => handleChange(e, index)}
                ref={(el) => (otpRefs.current[index] = el)} // Assign the ref to each input
              />
            ))}
          </div>
          <button onClick={VerifyOTP} className="verifyButton" type="submit">
            Verify
          </button>
          <button
            onClick={() => setOpenOTP(false)}
            type="button"
            className="exitBtn"
          >
            ×
          </button>
        </form>
      </Dialog>
    </>
  );
};

export default EmailVerification;
