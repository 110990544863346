import moment from "moment";

export const formatDate = (timestamp) => {
  const messageDate = moment(parseInt(timestamp, 10));

  const todayTimestamp = moment().startOf("day").valueOf();
  const yesterdayTimestamp = moment()
    .subtract(1, "days")
    .startOf("day")
    .valueOf();

  if (timestamp >= todayTimestamp) {
    return `Today at ${messageDate.format("h:mm A")}`;
  } else if (timestamp >= yesterdayTimestamp) {
    return `Yesterday at ${messageDate.format("h:mm A")}`;
  } else {
    return messageDate.format("MMM DD [at] h:mm A");
  }
};

export const formatDateMsg = (timestamp) => {
  if (!timestamp) return "";
  
  // Convert to milliseconds if needed
  const ts = timestamp < 1000000000000 ? timestamp * 1000 : timestamp;
  const messageDate = moment(ts);
  const now = moment();

  // For messages within last 7 days
  if (messageDate.isAfter(now.clone().subtract(7, 'days'))) {
    if (messageDate.isSame(now, 'day')) {
      return messageDate.format('h:mm A');
    }
    if (messageDate.isSame(now.clone().subtract(1, 'day'), 'day')) {
      return 'Yesterday';
    }
    return messageDate.format('ddd h:mm A');
  }
  
  // For older messages
  return messageDate.format('MMM DD');
};
