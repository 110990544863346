import React, { useEffect, useState } from "react";
import "./Statics.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import ContainerWithoutFooter from "../../../components/HOC/ContainerWithoutFooter";
import FilterIcon from "../../../assets/icons/IconFilter.svg";
import businessCenter from "../../../assets/icons/business_center.svg";
import AccountCircle from "../../../assets/icons/account_circle.svg";
import gavel from "../../../assets/icons/gavel.svg";
import FilterMenu from "../../../components/Filters/FilterMenu/FilterMenu";
import Filter from "../../../components/Filters/FilterBy/FilterBy";
import makeStyles from "@mui/styles/makeStyles";
import TableHead from "@mui/material/TableHead";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllStaticsDataAsync,
  getAllUsersDataAsync,
} from "../../../redux/staticsSlice";
import Loader from "../../../components/Loader";
import Footer from "../../../components/Footer/Footer";
import { Constants } from "../../../utils/Constants";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { getAllLocationAsync } from "../../../redux/announcementsSlice";

function Statics() {
  const [page, setPage] = useState(1);
  const [openFilter, setOpenFilter] = useState(false);
  const [filteMenus, setFilterMenus] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [alignment, setAlignment] = useState("Total Pro accounts");
  const filterOpenButton = () => {
    setFilterMenus(true);
    dispatch(getAllLocationAsync());
  };
  const {
    getAllStaticsLoading,
    getAllUsersData,
    getAllUserAccountLoading,
    getAllStaticsData,
    totalAdminPage,
    AdminPaginationDataLength,
    adminStartedRecords,
    adminToRecords,
  } = useSelector((state) => state.statics);
  const data = {
    // location: "",
    // country: "",
    // zipCode: "",
    // FromDate: "",
    // ToDate: "",
    Type:
      alignment === "Total Pro accounts"
        ? "Pro Business"
        : alignment === "Business Accounts"
        ? "business"
        : "customer",
  };

  useEffect(() => {
    dispatch(getAllStaticsDataAsync());
  }, [dispatch]);

  const handleChange = (event, value) => {
    setPage(value);
    const skip = (value - 1) * Constants.TAKE;
    dispatch(getAllUsersDataAsync(data, Constants.TAKE, skip, value));
  };
  const handleTabs = (event, newAlignment) => {
    setPage(1)
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };
  useEffect(() => {
    dispatch(getAllUsersDataAsync(data, Constants.TAKE, 0, 1));
  }, [alignment]);
  return (
    <ContainerWithoutFooter>
      {getAllStaticsLoading || getAllUserAccountLoading ? <Loader /> : null}
      <div className="StaticMainContainer">
        <ToggleButtonGroup
          color="primary"
          value={alignment}
          exclusive
          onChange={handleTabs}
          aria-label="Platform"
          className="StaticBidContainer"
          sx={{
            "& .MuiToggleButton-root": {
              outline: "none",
            },
          }}
        >
          <ToggleButton
            style={{
              backgroundColor:
                alignment === "Total Pro accounts" ? "#38AB7C" : "#fff",
            }}
            className="StaticBidDiv"
            value="Total Pro accounts"
          >
            <div>
              <p
                style={{
                  color: alignment === "Total Pro accounts" ? "#FFF" : "#000",
                }}
              >
                Total Pro accounts
              </p>
              <p
                style={{
                  color: alignment === "Total Pro accounts" ? "#FFF" : "#000",
                }}
                className="DataNumber"
              >
                {getAllStaticsData?.getNumOfUsers?.numofBusinessProAccounts}
              </p>
            </div>
            <div className="bg-[#FFD555] BidImageContainer">
              <img
                src={require("../../../assets/icons/movie.svg").default}
                alt="ads"
              />
            </div>
          </ToggleButton>
          <ToggleButton
            style={{
              backgroundColor:
                alignment === "Business Accounts" ? "#38AB7C" : "#fff",
              color: alignment === "Business Accounts" ? "#FFF" : "#000",
            }}
            className="StaticBidDiv"
            value="Business Accounts"
          >
            <div>
              <p
                style={{
                  color: alignment === "Business Accounts" ? "#FFF" : "#000",
                }}
              >
                Business Accounts
              </p>
              <p
                style={{
                  color: alignment === "Business Accounts" ? "#FFF" : "#000",
                }}
                className="DataNumber"
              >
                {getAllStaticsData?.getNumOfUsers?.numOfBusiness}
              </p>
            </div>
            <div className="bg-[#37CB34] BidImageContainer">
              <img src={businessCenter} alt="ads" />
            </div>
          </ToggleButton>
          <ToggleButton
            style={{
              backgroundColor:
                alignment === "Customer Accounts" ? "#38AB7C" : "#fff",
              color: alignment === "Customer Accounts" ? "#FFF" : "#000",
            }}
            className="StaticBidDiv"
            value="Customer Accounts"
          >
            <div>
              <p
                style={{
                  color: alignment === "Customer Accounts" ? "#FFF" : "#000",
                }}
              >
                Customer Account
              </p>
              <p
                style={{
                  color: alignment === "Customer Accounts" ? "#FFF" : "#000",
                }}
                className="DataNumber"
              >
                {getAllStaticsData?.getNumOfUsers?.numOfCustomer}
              </p>
            </div>
            <div className="bg-[#3AD0FF] BidImageContainer">
              <img src={AccountCircle} alt="ads" />
            </div>
          </ToggleButton>
          <ToggleButton
            style={{
              backgroundColor: "#FFCF6B",
              color: "#000",
              cursor: "not-allowed",
            }}
            className="StaticBidDiv"
            value="Total Bid's Sold"
            disabled // Make the button unclickable
          >
            <div>
              <p style={{ color: "#000" }}>Total Bid Sold</p>
              <p style={{ color: "#000" }} className="DataNumber">
                {getAllStaticsData?.getNumOfUsers?.numOfCompletedProject}
              </p>
            </div>
            <div className="bg-[#38AB7C] BidImageContainer">
              <img src={gavel} alt="ads" />
            </div>
          </ToggleButton>
        </ToggleButtonGroup>
        <div className="staticContainer">
          <h1>Statistics</h1>
          <div onClick={filterOpenButton} className="StaticIconContainer">
            <p>Filter</p>
            <img src={FilterIcon} alt="filter" />
          </div>
        </div>
        <TableContainer sx={{ maxHeight: "82%" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow className={classes.root}>
                <TableCell>
                  <p className="Header_banking">No.</p>
                </TableCell>
                {/* <TableCell>
                  <p className="Header_banking">Zip Code</p>
                </TableCell> */}
                <TableCell>
                  <p className="Header_banking">State</p>
                </TableCell>
                <TableCell>
                  <p className="Header_banking">Bid's Sold in State</p>
                </TableCell>

                <TableCell>
                  {alignment === "Total Pro accounts" ? (
                    <p className="Header_banking">
                      Approved Pro Business Accounts
                    </p>
                  ) : alignment === "Business Accounts" ? (
                    <p className="Header_banking"> Business Accounts</p>
                  ) : (
                    <p className="Header_banking">Customer Accounts</p>
                  )}
                </TableCell>
                <TableCell>
                  {alignment === "Total Pro accounts" ? (
                    <p className="Header_banking">
                      Total Pro Business Accounts
                    </p>
                  ) : alignment === "Business Accounts" ? (
                    <p className="Header_banking">Temp Business Accounts</p>
                  ) : (
                    <p className="Header_banking">Temp Customer Accounts</p>
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getAllUsersData
                .filter((item) => typeof item === "object")
                .map((item, i) => {
                  return (
                    <TableRow
                      className={classes.bodyroot}
                      hover
                      role="checkbox"
                      key={i}
                    >
                      <TableCell className={classes.customTableCell}>
                        {i + 1}
                      </TableCell>
                      {/* <TableCell className={classes.customTableCell}>
                        NA
                      </TableCell> */}
                      <TableCell className={classes.customTableCell}>
                        {item?.state}
                      </TableCell>
                      <TableCell className={classes.customTableCell}>
                        {item?.bidsold}
                      </TableCell>
                      {alignment === "Total Pro accounts" ? (
                        <TableCell className={classes.customTableCell}>
                          {item?.proAccountsAdminApproved
                            ? item?.proAccountsAdminApproved
                            : 0}
                        </TableCell>
                      ) : (
                        <TableCell className={classes.customTableCell}>
                          {item?.totalAccounts
                            ? item?.totalAccounts || 0
                            : item?.totalProAccounts || 0}
                        </TableCell>
                      )}
                      {alignment === "Total Pro accounts" ? (
                        <TableCell className={classes.customTableCell}>
                          { item?.totalProAccounts || 0 }
                        </TableCell>
                      ) : (
                        <TableCell className={classes.customTableCell}>
                          {item?.demoAccounts
                            ? item?.demoAccounts || 0
                            : item?.tempAccounts || 0}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {AdminPaginationDataLength > Constants.TAKE ? (
            <Footer
              page={page}
              handleChange={handleChange}
              totalRecord={AdminPaginationDataLength}
              startedRecords={adminStartedRecords}
              toRecords={adminToRecords}
              totalPages={totalAdminPage}
            />
          ) : null}
        </TableContainer>
      </div>
      {/* <Filter openFilter={openFilter} setOpenFilter={setOpenFilter} /> */}
      <FilterMenu
        alignment={alignment}
        filteMenus={filteMenus}
        setFilterMenus={setFilterMenus}
      />
    </ContainerWithoutFooter>
  );
}

export default Statics;
const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      height: "28px",
      padding: "0.6rem 1rem",
    },
  },
  bodyroot: {
    "&:hover": {
      backgroundColor: "#c5d9cf !important",
    },
    "& .MuiTableCell-body": {
      height: "28px",
      padding: "0.6rem 1rem",
    },
  },
  customTableCell: {
    color: " #616B7A !important",
    fontSize: "0.85rem !important",
    fontFamily: "roboto !important",
  },
});
