export const Constants = {
  TAKE: 10,
  takeForChat: 15,
  TakeForChatUser: 20,



  // Notifications:
  DOCUMENTUPDATE: 0, //0 : when customer and business updated document for verifications


  MASTER_ADMIN: 0, //
  ADMIN: 1, //1
  CUSTOMER: 2, // 2
  BUSINESS: 3, //3


};


