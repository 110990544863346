import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  logoutUserAsync,
  refreshUserAsync,
  ToggleSessionAlert,
  UpdateSessionAlert,
} from "../redux/authSlice";
import axiosInstance from "../helper/axiosInstance";
import Loader from "../components/Loader";
import AuthRoutes from "./AuthRoutes";
import AdminRoutes from "./AdminRoutes";
import MasterAdminRoutes from "./MasterAdminRoutes";
import Swal from "sweetalert2";

export default function Routes() {
  const dispatch = useDispatch();

  let { userType, showSessiosAlert, userDetails, loading, showUnauthorizedModal } = useSelector(
    (state) => state.auth
  );


  useEffect(() => {
    if (showSessiosAlert && userDetails) {
      Swal.fire({
        text: "Your session has expired due to inactivity.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Logout",
        cancelButtonText: "Extend Session",
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#28a745",
      }).then((result) => {
        if (result.isConfirmed) {
          const loggedInUser = JSON.parse(sessionStorage.getItem("userLoggedDetails"));
          if (!loggedInUser) return;

          const data = {
            id: loggedInUser?.id,
            requestType: '0',
            isSwitch: false,
          };
          dispatch(logoutUserAsync(data));
          sessionStorage.clear();
          dispatch(ToggleSessionAlert(false));
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log("Session extended");
          dispatch(ToggleSessionAlert(false));
        }
      });

    }
  }, [showSessiosAlert, dispatch]);

  useEffect(() => {
    const requestInterceptor = axiosInstance.interceptors.response.use(
      (response) => {
        console.log("API Response received successfully:", response.data);
        return response;
      },
      async (error) => {
        console.error("Error in response:", error);

        if (error.response) {
          const { status, data } = error.response;

          if (status === 401 && data.error === "Unauthorized") {
            const userLoggedToken = sessionStorage.getItem("userLoggedToken");

            if (userLoggedToken) {
              console.log("User token exists but is unauthorized. Attempting to refresh token...");
              dispatch(UpdateSessionAlert(true));
            } else {
              console.log("User token does not exist in session storage. Redirecting to login...");
              // Optionally, redirect to login or handle accordingly
            }
          } else if (status === 500) {
            console.error("Internal Server Error:", data.error);
          } else {
            console.error("Unhandled error:", data.error);
          }
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Request setup error:", error.message);
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axiosInstance.interceptors.response.eject(requestInterceptor);
    };
  }, [dispatch]);

  const handleLogout = async () => {
    if (userDetails?.id) {
      await dispatch(logoutUserAsync(userDetails?.id));
      sessionStorage.clear();
      dispatch(UpdateSessionAlert(false));
    }
  };

  const handleRefresh = async () => {
    if (userDetails?.id) {
      const data = {
        id: userDetails?.id,
        accountType: userDetails.accountType,
      };

      try {
        await dispatch(refreshUserAsync(data)); // Dispatch refresh action
        dispatch(UpdateSessionAlert(false)); // Hide the modal after successful refresh
        if (data.accountType === "masteradmin") {
          window.location.reload(); // Reload for masteradmin
        }
      } catch (error) {
        console.error("Error refreshing session:", error);
      }
    }
  };

  const renderRoutes = () => {
    switch (userType) {
      case "UNREGISTERED":
        return <AuthRoutes
        // referTo={referTo} 
        />;
      case "admin":
        return <AdminRoutes />;
      case "masteradmin":
        return <MasterAdminRoutes />;
      default:
        return null;
    }
  };

  const modalStyles = {
    modal: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 1000,
    },
    modalContent: {
      background: "white",
      padding: "20px",
      borderRadius: "8px",
      textAlign: "center",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
      maxWidth: "400px",
      width: "100%",
    },
    modalButtons: {
      marginTop: "20px",
    },
    button: {
      margin: "0 10px",
      padding: "10px 20px",
      backgroundColor: "#007bff",
      color: "white",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      fontSize: "16px",
    },
  };

  return (
    <Router>
      {renderRoutes()}
      {loading && <Loader />}
      {showUnauthorizedModal && (
        <div style={modalStyles.modal}>
          <div style={modalStyles.modalContent}>
            <h2>Unauthorized Access</h2>
            <p>
              Your session has expired or you are unauthorized to access this
              resource.
            </p>
            <div style={modalStyles.modalButtons}>
              <button
                style={modalStyles.button}
                onClick={handleRefresh}
                disabled={loading}
              >
                {loading ? "Refreshing..." : "Refresh"}
              </button>
              <button
                style={modalStyles.button}
                onClick={handleLogout}
                disabled={loading}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      )}
    </Router>
  );
}
