import React, { useState, useCallback, useRef } from "react";
import "./Messages.scss";
import Container from "../../../components/HOC/Container";
import NewMessage from "../../../components/MessageNavgations/NewMessage/NewMessage";
import FavoritesUsers from "../../../components/MessageNavgations/NewMessage/FavoritesUsers";
import Swal from "sweetalert2";
import Switch from "react-switch";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { useLocation } from "react-router-dom";
import {
  getChatRoomsUser,
  getReceivers,
  getAllMessagesAsync,
  setCurrentChat,
  AddNewUserToChat,
  MarkMsgRead,
} from "../../../redux/chatSlice";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import Chat from "./Chat";

import Socket from "../../../redux/socketConnect";
import newMessageIcon from "../../../assets/icons/newMessage.svg";

import { Constants } from "../../../utils/Constants";
import Loader from "../../../components/Loader";

import { Resizable } from "re-resizable";
import { Pagination } from "@mui/material";
import { useDebounce } from "use-debounce";

const Messages = ({ conversations }) => {
  const { state } = useLocation();
  // console.log("state", state);
  const dispatch = useDispatch();
  const chatContainerRef = useRef(null);
  const chatUserContainerRef = useRef(null);
  const prevScrollHeight = useRef(0);
  const isScrollPositionSet = useRef(false)
  const {
    ChatRoomsUsers,
    currentChat,
    messages,
    RecieverDetails,
    AllUsers,
    totalChatUsersCount,
    getAllMessagesLoading,
    getAllUsersLoading,
    isSocketConnected,
    favList,
    usersNotFoundMessage,
    newMessageCount,
    PreviousChatRoomId,
    ChatData,
    SessionExpiredby,
    RemoveRoom,
    isRemoved,
    isNewMessage,
    chatUserListLength,
    totalChatUser,
    AllChatRooms

    // ToRecords, StartedRecords
  } = useSelector((state) => state.chat);

  const [newMessage, setNewMessage] = useState("");
  const [IsScrolled, setIsScrolled] = useState(false);
  const [activeTab, setActiveTab] = useState("Chat");
  const [unreadMessageCounts, setUnreadMessageCounts] = useState({});
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [hasMoreUsers, sethasMoreUsers] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [ChatRoomPage, setChatRoomPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryAllUsers, setSearchQueryAllUsers] = useState("");
  const [scrollToLast, setScrollToLast] = useState(false);
  const [selectedUserId, setselectedUserId] = useState([]);
  const [showUnreadAndUnanswered, setShowUnreadAndUnanswered] = useState(false);
  const [openNewUser, SetOpenNewUser] = useState(false);
  const [userApiCall, SetisUserApiCall] = useState(true);
  const [showIncomingUser, SetIsshowIncomingUser] = useState(false);
  const [showSelectedUser, SetshowSelectedUser] = useState([]);
  const [isValidUser, SetisValidUser] = useState(false);
  const [page, setPage] = useState(1);
  const [debouncedSearchQuery] = useDebounce(searchQuery, 500); // 500ms debounce
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [showScrollToBottom, setShowScrollToBottom] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState(false);
  let isSender = loggedInUser?.id === ChatRoomsUsers?.senderId;
  let Ischat = RecieverDetails?.filter(
    (u) =>
      u?.id ===
      (isSender ? ChatRoomsUsers.receiverId : ChatRoomsUsers?.senderId)
  );
  let currentChatUser = RecieverDetails?.filter((u) => u?.id === currentChat);

  const totalMessages = messages.filter((m) => typeof m === "object").flat();

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("userLoggedDetails"));
    console.log("Initial user:", user);
    setLoggedInUser(user);
  }, []);

  // const ReloadData = () => {
  //   console.log("Reload data");
  //   // loggesInUser = JSON.parse(sessionStorage.getItem("userLoggedDetails"));
  //   let isFavTab = activeTab === "Favorites" ? true : false;

  //   // setLoggedInId(loggedInUser);
  //   if (loggedInUser?.id) {
  //     dispatch(
  //       getReceivers(
  //         loggedInUser?.id,
  //         searchQuery,
  //         loggedInUser?.location,
  //         Constants.TakeForChatUser,
  //         1,
  //         false,
  //         isFavTab,
  //         showUnreadAndUnanswered
  //       )
  //     );
  //     SetisValidUser(false);
  //   }
  // };

  // Unified fetch function
  const fetchUsers = useCallback(
    (pageNumber = page, search = debouncedSearchQuery) => {
      let isFavTab = activeTab === "Favorites" ? true : false;
      if (!loggedInUser?.id) return;
      dispatch(
        getReceivers(
          loggedInUser?.id,
          search,
          loggedInUser?.location,
          Constants.TakeForChatUser,
          pageNumber,
          false,
          isFavTab,
          showUnreadAndUnanswered
        )
      );
    },
    [
      loggedInUser,
      activeTab,
      showUnreadAndUnanswered,
      page,
      debouncedSearchQuery,
    ]
  );

  useEffect(() => {
    if (loggedInUser) {
      fetchUsers();
    }
  }, [fetchUsers, loggedInUser, selectedUserId]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setPage(1);
  };

  const handleToggleUnreadAndUnanswered = () => {
    setShowUnreadAndUnanswered((prev) => !prev);
    setPage(1);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(1);
  };


  const lastItem = totalMessages[totalMessages.length - 1];
  useEffect(() => {
    if (!loggedInUser) return;
    // console.log("User initialized, running dependent code", AllChatRooms);
    if (isSocketConnected) {
      try {
        const chatIds = AllChatRooms?.length > 0 && AllChatRooms?.map(u => u.id);
        chatIds.forEach(chatId => {
          Socket.joinRoom(chatId);

        });
      } catch (error) {
        console.error("Failed to fetch chats:", error);
      }

    }
  }, [ChatRoomsUsers?.ChatId, loggedInUser]);

  useEffect(() => {
    if (state) {
      SetshowSelectedUser([state]);
      handleOpenChat(state);
    }

    return () => {
      SetshowSelectedUser([]);
    };
  }, [state]);

  const updateUnreadMessageCount = (userId, count) => {
    setUnreadMessageCounts((prevCounts) => ({
      ...prevCounts,
      [userId]: count,
    }));
  };

  const handleOpenChat = async (user) => {
    if (user && loggedInUser) {
      setselectedUserId(user);
      // setCurrentPage(1);
      setHasMoreMessages(true);
      dispatch(setCurrentChat(user?.id));
      updateUnreadMessageCount(user?.id, 0);
      // dispatch(getChatRoomsUser(loggedInUser?.id, user?.id, "admin")).then(
      // (res) => {
      // console.log("##resssss", res);
      dispatch(
        getAllMessagesAsync(user, Constants.takeForChat, 0, false)
      );
      dispatch(MarkMsgRead(user.ChatId, loggedInUser?.id))
      // }
      // );
    }
  };

  const handleSubmit = async (e) => {
    // console.log("ChatRoomsUsers", ChatRoomsUsers);
    if (e) {
      e.preventDefault();
    }
    setIsScrolled(false);
    const trimmedMessage = newMessage.trim();
    if (trimmedMessage !== "") {
      const msg = {
        chatId: ChatRoomsUsers?.ChatId,
        senderId: loggedInUser?.id,
        receiverId: isSender
          ? ChatRoomsUsers.receiverId
          : ChatRoomsUsers?.senderId,
        text: trimmedMessage,
        projectId: "",
        isRead: true,
        isSenderAdmin: true,
        isSeen: false,
        createdAt: Date.now().toString(),
        status: 'sending' ,
        tempId: Date.now(),
      };
      console.log("handleSubmit", msg);

      if (msg.chatId && msg.senderId && msg.text) {
        // dispatch(sendMessageAsync(msg));
        Socket.onSend(loggedInUser?.id,msg, dispatch);
        setNewMessage(" ");
        setIsScrolled(true);
      }
    }
  };

  // const filteredChatUsers = RecieverDetails?.filter(
  //   (user) =>
  //     user && user.userId?.toLowerCase().includes(searchQuery.toLowerCase())
  // );
  const handleScroll = () => {
    const chatContainer = chatContainerRef.current;
    if (hasMoreMessages && chatContainer.scrollTop === 0) {
      setScrollToLast(true);
      const limit = Constants.TAKE;
      const skip = currentPage * limit;

      const totalMessages = messages?.flat();
      const lastItem = totalMessages[totalMessages.length - 1];

      if (lastItem > skip) {
        console.log("ChatRoomsUsers#####", ChatRoomsUsers);
        dispatch(
          getAllMessagesAsync(
            ChatRoomsUsers || PreviousChatRoomId,
            limit,
            skip,
            true
          )
        );
        setCurrentPage(currentPage + 1);
      } else {
        setHasMoreMessages(false);
        console.log("// No more messages to load");
      }
    }
  };

  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    chatContainer.addEventListener("scroll", handleScroll);

    return () => {
      chatContainer.removeEventListener("scroll", handleScroll);
    };
  }, [hasMoreMessages, messages, currentPage, ChatRoomsUsers]);

  return (
    <Container Comp={Chat}>
      <div
        messageContainer
        style={{
          height: `calc(100% - 70px)`,
          display: "flex",
          flexDirection: "row",
        }}
        className="inner-container"
      >
        <Resizable
          defaultSize={{
            width: 445,
            height: "100%",
          }}
          minWidth={450} // Minimum width it can be resized to
          maxWidth={700} // Optional: Set a maximum width if needed
          enable={{ right: true }} // Restrict resizing to horizontal direction
          className="leftContainer"
        >
          <div className="messageDiv">
            <div className="searchInput">
              <img
                src={require("../../../assets/icons/SearchIcon.svg").default}
                alt="user"
              />
              <input
                type="text"
                placeholder="Search Users..."
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "4%",
                gap: "2%",
              }}
            >
              <p
                style={{
                  color: "#717171",
                  fontFamily: "Roboto",
                  fontSize: "0.8rem",
                }}
              // className="mb-1 text-#717171  text-xs"
              >
                Unanswered
              </p>

              <label>
                <Toggle
                  defaultChecked={showUnreadAndUnanswered}
                  onChange={handleToggleUnreadAndUnanswered}
                  style={{ transform: "scale(0.8)" }}
                />
              </label>
            </div>
          </div>
          {/* {isValidUser && (
            <div onClick={ReloadData} className="refresh-main-container">
              <div className="refresh-container">
                <span>Reload New Message</span>
                <img src={newMessageIcon} alt="refresh" />
              </div>
            </div>
          )} */}
          <div className="MessageNav">
            <ul>
              <li
                className={activeTab === "Chat" ? "active" : ""}
                onClick={() => handleTabClick("Chat")}
              >
                Chats
              </li>
              <li
                className={activeTab === "Favorites" ? "active" : ""}
                onClick={() => handleTabClick("Favorites")}
              >
                Favorites
              </li>
            </ul>
          </div>
          {activeTab === "Chat" && (
            <div className="MessageDiv" ref={chatUserContainerRef}>
              <div>
                {RecieverDetails?.length > 0 ? (
                  <NewMessage
                    recentMessages={messages
                      .filter((m) => typeof m === "object")
                      .flat()
                      .reverse()}
                    conversation={conversations}
                    currentUser={loggedInUser}
                    handleClick={handleOpenChat}
                    receiver={
                      (showSelectedUser.length > 0 && showSelectedUser) ||
                      RecieverDetails
                    }
                    unreadMessageCounts={unreadMessageCounts}
                    showUnreadAndUnanswered={showUnreadAndUnanswered}
                    // isStartNewChat={false}
                    RemoveRoom={RemoveRoom}
                    isRemoved={isRemoved}
                    ChatData={ChatData}
                    isNewMessage={isNewMessage}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "25%",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "1rem",
                        fontFamily: "Segoe UI",
                        color: "#717171",
                      }}
                    >
                      {getAllMessagesLoading || getAllUsersLoading
                        ? " "
                        : "No Users Found"}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {activeTab === "Favorites" && (
            <div className="MessageDiv" ref={chatUserContainerRef}>
              {RecieverDetails?.length > 0 ? (
                <NewMessage
                  recentMessages={messages
                    .filter((m) => typeof m === "object")
                    .flat()
                    .reverse()}
                  conversation={conversations}
                  currentUser={loggedInUser}
                  handleClick={handleOpenChat}
                  receiver={
                    (showSelectedUser.length > 0 && showSelectedUser) ||
                    RecieverDetails
                  }
                  unreadMessageCounts={unreadMessageCounts}
                  showUnreadAndUnanswered={showUnreadAndUnanswered}
                  // isStartNewChat={false}
                  RemoveRoom={RemoveRoom}
                  isRemoved={isRemoved}
                  ChatData={ChatData}
                  isNewMessage={isNewMessage}
                  isFavActive={activeTab}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "25%",
                  }}
                >
                  <div
                    style={{
                      fontSize: "1rem",
                      fontFamily: "Segoe UI",
                      color: "#717171",
                    }}
                  >
                    {getAllMessagesLoading || getAllUsersLoading
                      ? " "
                      : "No Favorites Users"}
                  </div>
                </div>
              )}
            </div>
          )}
          {totalChatUser > Constants.TakeForChatUser ? (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "2%",
              }}
            >
              <Pagination
                page={page}
                count={totalChatUsersCount}
                color="primary"
                onChange={(e, v) => {
                  setPage(v);
                  fetchUsers(v);
                }}
              />
            </div>
          ) : null}
        </Resizable>
        <div className="rightContainer">
          {currentChatUser[0]?.userId && (
            <h1 className="UserAvatar">
              <p className="UserAvtarIcon">
                {currentChatUser[0]?.userId?.charAt(0).toUpperCase()}
              </p>
              {currentChatUser[0]?.userId?.toUpperCase()}
            </h1>
          )}
          {(getAllMessagesLoading || getAllUsersLoading) && <Loader />}

          <div className="rightContainer" ref={chatContainerRef}>
            {loadingMessages && <Loader small />}
            {!getAllMessagesLoading && totalMessages?.length > 0 && Ischat?.length > 0 ? (
              <Chat
                messages={messages
                  .filter((m) => typeof m === "object")
                  .flat()
                  .reverse()}
                currentUser={loggedInUser}
                receiver={ChatRoomsUsers}
                currentPage={currentPage}
                isQA={false}
                RemoveRoom={RemoveRoom}
                isRemoved={isRemoved}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "25%",
                }}
              >
                <div
                  style={{
                    fontSize: "3rem",
                    fontFamily: "Segoe UI",
                    color: "#717171",
                  }}
                >
                  {
                    !getAllMessagesLoading && 'Start Chat'}
                </div>
                {getAllMessagesLoading && <div
                  style={{
                    fontSize: "3rem",
                    fontFamily: "Segoe UI",
                    color: "#717171",
                  }}
                >
                  Loading...
                </div>
                }

              </div>
            )}
            {/* Scroll to Bottom Button */}
            {showScrollToBottom && (
              <button
                className="scroll-to-bottom"
                onClick={() => {
                  chatContainerRef.current?.scrollTo({
                    top: chatContainerRef.current.scrollHeight,
                    behavior: "smooth"
                  });
                }}
              >
                ↓
              </button>
            )}

          </div>

          {messages && (
            <div className="ReplyDiv">
              <textarea
                value={newMessage}
                onChange={(event) => {
                  setNewMessage(event.target.value);
                }}
                onKeyDown={(event) => {
                  newMessage.length > 0 &&
                    currentChat !== null &&
                    event.key === "Enter" &&
                    handleSubmit();
                }}
                placeholder="type..."
                className="textfield"
              ></textarea>
              <button
                style={{
                  pointerEvents: currentChat === null ? "none" : "auto",
                  opacity: currentChat === null ? "0.5" : "1",
                }}
                onClick={handleSubmit}
                className="btnForReply"
              >
                Reply
              </button>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Messages;
