import React, { useEffect, useState } from "react";
import Container from "../../../components/HOC/Container";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Calendar from "moedim";
import "./AddProject.scss";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import AdminIcon from "../../../assets/icons/admin-pic-icon.svg";
import Arrow from "../../../assets/icons/arrow-left.svg";
import {
  getCategoryAsync,
  getSubCategoryAsync,
  CreateProjectAsync,
  UpdateProjectAsync,
} from "../../../redux/categorySlice";
import Loader from "../../../components/Loader";
import { getAllLocationAsync } from "../../../redux/announcementsSlice";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
const AddProject = () => {
  const { userDetails } = useSelector((state) => state.users);
  const { Selected_Project } = useSelector((state) => state.project);
  const [pName, setPname] = useState("");
  const [serviceSubCategoryId, setServiceSubCategoryId] = useState("");
  const [categoryN_Name, setCategory_Name] = useState();
  const [sub_categoryN_Name, setsub_Category_Name] = useState();
  const [serviceCategoryId, setServiceCategoryId] = useState("");
  const [shortDics, setShortDisc] = useState("");
  const [visible, setVisible] = useState(false);
  const [openFromDate, setOpenFromDate] = useState(false);
  const [ValidDate, setValidDate] = useState("");
  const [value, setValue] = useState(new Date());
  const [citiState, setCitiState] = useState(false);
  const [openCitiesModal, setOpenCitiesModal] = useState(false);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [openFavDialog, setOpenFavDialog] = useState(false);
  const [selectedDocs1, setSelectedDocs1] = useState([]);
  const [selectedDocs2, setSelectedDocs2] = useState([]);
  const [selectedDocs3, setSelectedDocs3] = useState([]);
  const [selectedImage1, setSelectedImage1] = useState("");
  const [selectedImage2, setSelectedImage2] = useState("");
  const [selectedImage3, setSelectedImage3] = useState("");
  const [docsDialog, setDocsDialog] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [zipCode, setZipCode] = useState("");
  const [state, setState] = useState([]);
  const [locationChange, setLocationChange] = useState(false);
  // const [selectedCityCheckboxes, setSelectedCityCheckboxes] = useState({});
  const {
    categoryData,
    subCategoryData,
    createProjectLoading,
    updateProjectLoading,
  } = useSelector((state) => state.category);
  const { AllCitiesData, AllCitesLoading } = useSelector(
    (state) => state.announcement
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getCategoryAsync(true));
  }, []);
  console.log("Selected_Project", Selected_Project);
  useEffect(() => {
    if (Selected_Project) {
      setPname(Selected_Project?.projectTitle);
      // setValidDate(Selected_Project?.biddingEndDate); // Assuming timestamp
      setZipCode(Selected_Project?.zipCode);
      setSelectedLocations(Selected_Project?.location);
      setShortDisc(Selected_Project?.jobDescription);
      setCategory_Name(Selected_Project?.category?.name);
      setServiceSubCategoryId(Selected_Project?.subCategoryId);
      setVisible(Selected_Project?.isPublicallyVisible);
      setsub_Category_Name(Selected_Project?.subCategoryTbl?.name);
      setState(Selected_Project?.state);
      setSelectedState(Selected_Project?.state);
    } else {
      setPname("");
      setSelectedLocations([]);
      setShortDisc("");
      setVisible(false);
      setZipCode("");
      setSelectedState("");
      setState([]);
    }
  }, [Selected_Project]);

  const HandleProject = (e) => {
    e.preventDefault();
    setPname(e.target.value);
  };
  const handleZipCode = (event) => {
    const newZipCode = event.target.value;
    if (!isNaN(newZipCode)) {
      setZipCode(newZipCode);
    }
  };
  const SubCategoryId = (item) => {
    setServiceSubCategoryId(item.id);
    setsub_Category_Name(item?.name);
  };
  const getSeacrhData = (item) => {
    const categoryId = item.id;
    setCategory_Name(item?.name);
    setServiceCategoryId(categoryId);
    dispatch(getSubCategoryAsync("", categoryId));
  };
  useEffect(() => {
    let date = new Date(Selected_Project?.biddingEndDate * 1000);
    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    setValidDate(formattedDate);
  }, [Selected_Project?.biddingEndDate]);

  const Selected = (d) => {
    setValue(d);
    const formattedDate = d.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    setValidDate(formattedDate);
    setOpenFromDate(false);
  };
  const GetAllLocation = () => {
    setCitiState(true);
    dispatch(getAllLocationAsync());
  };

  const GetingLocation = (item) => {
    setSelectedState(item?.state); // Assuming item.state is a string
    setCitiState(false);

    const citiesToRemove = item?.usCanadaCitiesMasterTbl.filter((cityObj) => {
      const cityName = cityObj.city;
      return (
        cityName.substring(0, 2).toUpperCase() !== cityName.substring(0, 2)
      );
    });

    setSelectedCities(citiesToRemove);
    setOpenCitiesModal(true);
  };
  const LoactionData = (item) => {
    setLocationChange(true);
    // Update selectedLocations
    setSelectedLocations((prevSelectedLocations) => {
      const existingLocationIndex = prevSelectedLocations.findIndex(
        (loc) => loc.city === item.city
      );

      if (existingLocationIndex !== -1) {
        // Remove the city if it already exists
        return prevSelectedLocations.filter((loc) => loc?.city !== item?.city);
      } else {
        // Add the city if it does not exist
        return [...prevSelectedLocations, item?.city];
      }
    });
    setState((prevState) => {
      const newSelectedState = selectedState; // Use the updated selectedState
      if (newSelectedState && !prevState?.includes(newSelectedState)) {
        return [...prevState, newSelectedState];
      }
      return prevState;
    });
    setOpenCitiesModal(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace") {
      setState(null);
      setSelectedLocations((prevSelectedLocations) => {
        if (prevSelectedLocations.length === 0) return prevSelectedLocations;
        // Remove the last location
        const updatedLocations = prevSelectedLocations.slice(0, -1);
        // Get all unique states from the remaining locations
        const remainingStates = [
          ...new Set(updatedLocations.map((loc) => loc.state)),
        ];
        // Update state array
        setState(remainingStates);
        // Update selectedState string
        if (updatedLocations.length > 0) {
          setSelectedState(updatedLocations[updatedLocations.length - 1].state);
        } else {
          setSelectedState("");
        }

        return updatedLocations;
      });
    }
  };

  const HandleDics = (e) => {
    e.preventDefault();
    setShortDisc(e.target.value);
  };
  // const toggleLocationSelection = (city, state) => {
  //   const newSelectedLocations = selectedLocations.includes(city)
  //     ? selectedLocations.filter((loc) => loc !== city)
  //     : [...selectedLocations, city];
  //   setSelectedLocations(newSelectedLocations);
  //   setSelectedCityCheckboxes((prevState) => ({
  //     ...prevState,
  //     [city]: !prevState[city],
  //   }));
  // };

  // function capitalizeFirstLetter(string) {
  //   return string?.charAt(0).toUpperCase() + string.slice(1);
  // }

  // const InviteFavUser = (e) => {
  //   e.preventDefault();
  //   dispatch(getFavBusinessAsync(userDetails?.id));
  //   setOpenFavDialog(true);
  // };

  // Selecet first docs

  const allowedFormats = ["image/jpeg", "image/png", "image/jpg"];

  const handleFileSelect = (e, setImage, setDocs, index) => {
    const file = e.target.files[0];
    if (!file) return;

    // Validate file format
    if (!allowedFormats.includes(file.type)) {
      toast.error(
        "Invalid File Format !! Please select a valid image file (JPEG, PNG, JPG)."
      );
      e.target.value = ""; // Reset file input field
      return;
    }

    // Only proceed if the file is valid
    const modifiedFile = new File([file], `d${index}-${file.name}`, {
      type: file.type,
    });

    setImage(URL.createObjectURL(file));
    setDocs([modifiedFile]); // Store as an array
  };

  const SelectFirstDoc = (e) =>
    handleFileSelect(e, setSelectedImage1, setSelectedDocs1, 0);
  const SelectSecondDoc = (e) =>
    handleFileSelect(e, setSelectedImage2, setSelectedDocs2, 1);
  const SelectThirdDoc = (e) =>
    handleFileSelect(e, setSelectedImage3, setSelectedDocs3, 2);

  const SaveAllImages = () => {
    setDocsDialog(false);
  };
  const ClearFiles = () => {
    setSelectedDocs1([]);
    setSelectedDocs2([]);
    setSelectedDocs3([]);
    setSelectedImage1("");
    setSelectedImage2("");
    setSelectedImage3("");
  };

  const SaveData = () => {
    const locationArray = selectedLocations?.map((itm) => itm?.city);

    let formData = new FormData();

    const appendFilesToFormData = (files) => {
      if (files && Array.isArray(files)) {
        files.forEach((file) => {
          if (file instanceof File) {
            formData.append("files", file);
          }
        });
      }
    };

    if (Selected_Project?.id) {
      const selectedDocsArray = [];

      if (selectedDocs1) selectedDocsArray.push(...selectedDocs1);
      if (selectedDocs2) selectedDocsArray.push(...selectedDocs2);
      if (selectedDocs3) selectedDocsArray.push(...selectedDocs3);

      appendFilesToFormData(selectedDocsArray);

      formData.append(
        "metadata",
        JSON.stringify({
          projectId: Selected_Project?.id,
          categoryId: serviceCategoryId || Selected_Project?.categoryId,
          subCategoryId:
            serviceSubCategoryId || Selected_Project?.subCategoryId,
          projectTitle: pName,
          biddingEndDate: ValidDate,
          location: selectedLocations,
          projectStatus: Selected_Project?.projectStatus,
          isPublicallyVisible: visible,
          jobDescription: shortDics,
          isFromWeb: 1,
          zipCode: zipCode,
          state: [selectedState.toLocaleString()],
        })
      );

      dispatch(UpdateProjectAsync(formData, navigate)).then((response) => {
        if (response === true) {
          Swal.fire({
            icon: "success",
            text: "Project Updated",
          });
          navigate(-2);
        } else {
          console.log("response", response);
          Swal.fire({
            icon: "error",
            text: response?.message,
          });
        }
      });
    } else {
      const selectedDocsArray = [
        ...(Array.isArray(selectedDocs1) ? selectedDocs1 : []),
        ...(Array.isArray(selectedDocs2) ? selectedDocs2 : []),
        ...(Array.isArray(selectedDocs3) ? selectedDocs3 : []),
      ];

      appendFilesToFormData(selectedDocsArray);

      formData.append(
        "metadata",
        JSON.stringify({
          customerId: userDetails?.id,
          projectTitle: pName,
          location: selectedLocations,
          categoryId: serviceCategoryId,
          subCategoryId: serviceSubCategoryId,
          biddingEndDate: ValidDate,
          projectStatus: 0,
          isPublicallyVisible: visible,
          jobDescription: shortDics,
          isFromWeb: 1,
          zipCode: zipCode,
          state: [state.toLocaleString()],
        })
      );

      const isFormInvalid =
        !pName?.trim() ||
        ValidDate === "Invalid Date" ||
        selectedLocations.length === 0 ||
        !shortDics?.trim() ||
        !serviceCategoryId ||
        !serviceSubCategoryId;

      if (isFormInvalid) {
        Swal.fire({
          icon: "error",
          title: "Validation Error",
          text: "Please ensure all required fields are filled out correctly.",
        });
        return;
      }

      dispatch(CreateProjectAsync(formData, navigate));
    }
  };

  const closePopUP = () => {
    setOpenCitiesModal(false);
  };

  return (
    <Container>
      {AllCitesLoading || createProjectLoading || updateProjectLoading ? (
        <Loader />
      ) : null}
      <div className="HeadingAndBack_Add">
        <button onClick={() => navigate(-1)}>
          <img src={Arrow} alt="Arrow" className="h-6" />
        </button>
        <h1>Back</h1>
      </div>
      <div className="AddProjectMainContainer">
        <div className="TitlesAndInput">
          <p>Project Title*</p>
          <input
            value={pName}
            onChange={HandleProject}
            type="text"
            placeholder="Enter title "
            className="InputsUserId"
          />
        </div>
        <div className="Common_details">
          <TextField
            className="Inputs"
            id="outlined-select-currency"
            required
            select
            label={Selected_Project ? categoryN_Name : "Select"}
          >
            {categoryData.map((item, key) => (
              <MenuItem
                onClick={() => getSeacrhData(item)}
                key={item.name}
                value={item.name}
                defaultValue=""
              >
                {item?.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            className="Inputs"
            id="outlined-select-currency"
            required
            select
            label={Selected_Project ? sub_categoryN_Name : "Select"}
            disabled={!serviceCategoryId}
          >
            {subCategoryData?.map((subItem, key) => (
              <MenuItem
                onClick={() => SubCategoryId(subItem)}
                key={subItem?.name}
                value={subItem?.name}
                defaultValue=""
              >
                {subItem?.name}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="Common_details">
          <input
            type="text"
            placeholder="Bidding End date *"
            onClick={() => setOpenFromDate(true)}
            value={
              ValidDate == "Invalid Date"
                ? "Choose bidding end date"
                : ValidDate
            }
          />
          <Dialog
            PaperProps={{
              sx: {
                position: "fixed",
                top: "36.5%",
                right: "center",
              },
            }}
            open={openFromDate}
            onClose={() => setOpenFromDate(false)}
          >
            <DatePicker
              id="date-month-year-selector"
              selected={value}
              onChange={Selected}
              dateFormat="DD/MM/YYYY"
              showDateMonthYearPicker
              inline
            />
          </Dialog>
          <input
            type="text"
            placeholder="Location *"
            onClick={GetAllLocation}
            onKeyDown={handleKeyDown}
            defaultValue={selectedLocations}
          />
          <Dialog
            PaperProps={{
              sx: {
                position: "fixed",
                m: 0,
                right: "center",
                width: "50%",
                height: "90%",
                backgroundColor: "#fff",
              },
            }}
            open={citiState}
            onClose={() => setCitiState(false)}
          >
            {AllCitesLoading && <Loader />}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p style={{ display: "flex", margin: "0 auto" }}>Select State</p>
              {AllCitiesData?.map((item, key) => (
                <MenuItem
                  defaultValue=""
                  onClickCapture={() => GetingLocation(item)}
                >
                  <p style={{ padding: "0.5rem 1rem" }}>{key + 1}.</p>
                  <span className="StateAndCityNAme">
                    {item?.shortNameOfState}
                  </span>
                </MenuItem>
              ))}
            </div>
          </Dialog>
          <Dialog
            PaperProps={{
              sx: {
                position: "fixed",
                m: 0,
                right: "center",
                width: "50%",
                height: "90%",
                backgroundColor: "#fff",
              },
            }}
            open={openCitiesModal}
            onClose={() => setOpenCitiesModal(false)}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p style={{ display: "flex", margin: "0 auto" }}>Select City</p>
              {selectedCities?.map((item, key) => (
                <MenuItem
                  key={item.city}
                  value={item?.city}
                  defaultValue=""
                  onClickCapture={() => LoactionData(item)}
                >
                  <p style={{ padding: "0.5rem 1rem" }}>{key + 1}.</p>
                  <span className="StateAndCityNAme"> {item?.city}</span>
                </MenuItem>
              ))}
            </div>
          </Dialog>
        </div>

        <div className="Common_details">
          <input
            defaultValue={
              Selected_Project ? Selected_Project?.zipCode : zipCode
            }
            onChange={handleZipCode}
            type="text" // Change type to text to allow non-numeric characters
            placeholder="Enter Zip Code (Optional)"
            maxLength={5}
          />
          <input value={state} type="text" placeholder="State" maxLength={5} />
        </div>
        {/* File Uploading work */}
        {Selected_Project ? (
          <div className="DocumentContainer">
            <h3>Update Document</h3>
            <div onClick={() => setDocsDialog(true)} className="AddProjectBTN">
              Update Documents
            </div>
          </div>
        ) : (
          <div className="DocumentContainer">
            <h3>Upload Document</h3>
            {selectedImage1 || selectedImage2 || selectedImage3 ? (
              <div
                onClick={() => setDocsDialog(true)}
                className="AddProjectBTN"
              >
                Selected{" "}
                {selectedDocs1?.length +
                  selectedDocs2?.length +
                  selectedDocs3?.length}{" "}
                docs
              </div>
            ) : (
              <div
                onClick={() => setDocsDialog(true)}
                className="AddProjectBTN"
              >
                Add Documents
              </div>
            )}
          </div>
        )}
        <div className="TitlesAndInput">
          <span>Enter Short Description About your businsess</span>
          <textarea
            className="TextArea"
            onChange={HandleDics}
            value={shortDics}
            type="text"
            placeholder="Enter short discription"
            aria-multiline
          />
        </div>
        <div className="MessageFromAdmin">
          <p>Message from Admin</p>
          <span>
            Sidebar has been collecting the best design links of the day since
            October 2012. It's maintained by Sacha Greif and built with
            Vulcan.js.
          </span>
        </div>
        {/* <div className="CheckForRegBusiness">
          <div className="CheckbixContainer">
            <Checkbox checked={visible} onChange={() => setVisible(!visible)} />
            <span>Visible only to registered businsess</span>
          </div>
        </div> */}

        <div onClick={() => navigate(-1)} className="SaveAndCancel">
          <div className="Cancel">Cancel</div>
          <button
            onClick={SaveData}
            className={
              !pName?.trim() ||
              !ValidDate ||
              selectedLocations.length === 0 ||
              !shortDics?.trim()
                ? "disabled"
                : "Save"
            }
            disabled={
              !pName?.trim() ||
              !ValidDate ||
              selectedLocations.length === 0 ||
              !shortDics?.trim()
            }
          >
            Save
          </button>
        </div>
      </div>
      <Dialog
        PaperProps={{
          sx: {
            height: "35%",
            backgroundColor: "#fff",
          },
        }}
        open={docsDialog}
        onClose={() => setDocsDialog(false)}
      >
        <div onClick={() => setDocsDialog(false)} className="BackBTN_Docs">
          <button>
            <img src={Arrow} alt="Arrow" className="h-4" />
          </button>
          <h1>Back</h1>
        </div>
        <div className="ImageDialogContainer">
          <div className="CommonIdContainer">
            <div className="ImageContainer">
              {!selectedImage1 ? (
                <img
                  src={
                    Selected_Project
                      ? "https://" +
                      Selected_Project?.projectDocumentTbl?.showfilePath[0]
                      : AdminIcon
                  }
                  alt="upload "
                />
              ) : (
                <img src={selectedImage1} alt="upload " />
              )}
            </div>
            <div className=" UploadButtonContainer ">
              <input type="file" onChange={SelectFirstDoc} />
            </div>
          </div>
          <div className="CommonIdContainer">
            <div className="ImageContainer">
              {!selectedImage2 ? (
                <img
                  src={
                    Selected_Project
                      ? "https://" +
                      Selected_Project?.projectDocumentTbl?.showfilePath[1]
                      : AdminIcon
                  }
                  alt="upload "
                />
              ) : (
                <img src={selectedImage2} alt="upload " />
              )}
            </div>
            <div className=" UploadButtonContainer ">
              <input type="file" onChange={SelectSecondDoc} />
            </div>
          </div>
          <div className="CommonIdContainer">
            <div className="ImageContainer">
              {!selectedImage3 ? (
                <img
                  src={
                    Selected_Project
                      ? "https://" +
                      Selected_Project?.projectDocumentTbl?.showfilePath[2]
                      : AdminIcon
                  }
                  alt="upload "
                />
              ) : (
                <img src={selectedImage3} alt="upload " />
              )}
            </div>
            <div className=" UploadButtonContainer ">
              <input type="file" onChange={SelectThirdDoc} />
            </div>
          </div>
        </div>
        <div className="Cancel_Save_container">
          <span onClick={ClearFiles} className="CancelBTN">
            Clear
          </span>
          <span onClick={SaveAllImages} className="SaveBTN">
            Done
          </span>
        </div>
      </Dialog>
    </Container>
  );
};

export default AddProject;
