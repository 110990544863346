import React, { useState, useEffect } from "react";
import ContainerWithoutFooter from "../../../components/HOC/ContainerWithoutFooter";
import "./CreateAdmin.scss";
import Arrow from "../../../assets/icons/arrow-left.svg";
// import Eye from "../../../assets/icons/eye.svg";
import Cross from "../../../assets/icons/cross-blue.svg";
import Save from "../../../assets/icons/save.svg";
import AdminIcon from "../../../assets/icons/admin-pic-icon.svg";
import { signUpAsync } from "../../../redux/adminManagementSlice";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import axios from "axios";

function CreateAdmin() {
  const navigate = useNavigate();
  const [iPAddress, setIPAddress] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setfirstName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [lastName, setlastName] = useState("");
  const [file, setFile] = useState();
  const [selectedImage, setSelectedImage] = useState("");
  const { signUpLoading, signUpUserFailed } = useSelector(
    (state) => state.adminManage
  );
  const [userId, setUserId] = useState("");
  const [showCharLength, setShowCharLength] = useState(0);
  const [focus, setFocus] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const dispatch = useDispatch();
  const getData = async () => {
    try {
      const res = await axios.get("https://geolocation-db.com/json/");
      console.log(res);
      setIPAddress(res.data.IPv4);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const SelectImage = (e) => {
    const file = e.target.files[0];

    if (file) {
      const validImageTypes = ["image/jpeg", "image/png", "image/gif", "image/webp"];
      const maxSize = 5 * 1024 * 1024; // 5MB allowed max

      if (!validImageTypes.includes(file.type)) {
        Swal.fire({
          title: "Error!",
          text: "Please select a valid image file (JPEG, PNG, GIF, WebP)",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      }

      if (file.size > maxSize) {
        Swal.fire({
          title: "Error!",
          text: "File size exceeds the maximum limit of 5MB.",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      }
      setSelectedImage(URL.createObjectURL(file));
      setFile(file);
    }
  };

  const UserIdSetup = (e) => {
    setUserId(e.target.value);
    // Alphanumeric validator ----
    const alphanumericRegex = /^[a-zA-Z0-9]+$/;
    if (alphanumericRegex.test(e.target.value)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
    setShowCharLength(e.target.value.length);
  };

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@(gmail|outlook|yahoo|icloud|hotmail|aol|protonmail|zoho|gmx|yandex|mail|live|msn|comcast)\.(com|net|org|co|edu|gov|mil)$/i;
    return regex.test(email);
  };
  const validation = () => {
    if (firstName === "" || lastName === "" || emailAddress === "") {
      Swal.fire({
        title: "Error!",
        text: "All fields are required",
        icon: "error",
        confirmButtonText: "OK",
      });
      return false;
    } else if (!validateEmail(emailAddress)) {
      Swal.fire({
        title: "Error!",
        text: "Invalid email address",
        icon: "error",
        confirmButtonText: "OK",
      });
      return false;
   }
    return true;
  };

  let reset = (e) => {
    e.preventDefault();
    setfirstName("");
    setEmailAddress("");
    setlastName("");
    setUserId("");
    setIPAddress("");
    setPassword("");
    setEmailAddress("");
    setlastName("");
    setUserId("");
    setIPAddress("");
    setPassword("");
    setFile("");
    setSelectedImage("");
  };
  // Create admin -------------
  const signUp = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append(`files`, file);

    if (validation()) {
      try {
        formData.append(
          "metadata",
          JSON.stringify({
            firstName: firstName,
            lastName: lastName,
            password: password,
            emailAddress: emailAddress,
            userId: userId,
            iPAddress: iPAddress,
            accountType: "admin",
            isLoggedIn: 0,
            demo: 0,
            isVerified: 0,
          })
        );
        const res = await dispatch(signUpAsync(formData));

        if (signUpUserFailed) {
          Swal.fire({
            title: "failed",
            text: signUpUserFailed,
            icon: "error",
            confirmButtonText: "ok",
          });
          console.log("---signUpUserFailed", signUpUserFailed);
        }

        if (res === "success") {
          Swal.fire({
            title: "Success",
            text: "Account Created Successfully",
            icon: "success",
            confirmButtonText: "ok",
          });
          navigate(-1);
        }
      } catch (error) {
        Swal.fire({
          title: "failed",
          text: "Account Not Created ",
          icon: "error",
          confirmButtonText: "ok",
        });
        console.log(error);
        // handle any rejections/errors
      }
    }
  };
  // ... (rest of the code)

  return (
    <ContainerWithoutFooter>
      {signUpLoading ? <Loader /> : null}
      <div className="createAdminMainContainer">
        <div className=" createAdminMainContainerHeader ">
          <div className="  createAdminMainContainerHeaderButtons">
            <button onClick={() => navigate(-1)}>
              <img src={Arrow} alt="Arrow" className="h-6" />
            </button>
            <h1>Create Admin</h1>
          </div>
          <div className=" createAdminMainContainerHeaderSaveButtons ">
            <button className="cancelBtn ml-2" onClick={() => navigate(-1)}>
              <img src={Cross} alt="Cross" />
              <span className="text">Cancel</span>
            </button>
            <button
              className="saveBtn ml-2"
              onClick={signUp}
              // disabled={signUpLoading}
            >
              <img src={Save} alt="Save" style={{ color: "#fff" }} />
              <span className="text">Save</span>
            </button>
          </div>
        </div>

        <div className=" createAdminBottomContainer ">
          <div className="signup__container">
            <div className="upload-photo-view">
              <div className="ImageContainer">
                {selectedImage === "" ? (
                  <img src={AdminIcon} alt="upload " />
                ) : (
                  <img src={selectedImage} alt="upload " />
                )}
              </div>

              <div className="BTN_And_Condition">
                <div className=" UploadButtonContainer ">
                  <label htmlFor="fileInput" className="fileInputButton">
                    Choose File
                  </label>
                  <input
                    type="file"
                    id="fileInput"
                    onChange={SelectImage}
                    style={{ display: "none" }}
                  />
                  <button onClick={reset} className="reset-btn ">
                    <span className="reset-text">Reset</span>
                  </button>
                </div>
                <p className="allowed-pic-details">
                  Allowed JPG, GIF or PNG. Max size of 800K
                </p>
              </div>
            </div>

            <div className="two-input-view">
              <div className="w-[49%]">
                <h5>First Name</h5>
                <input
                  type="text"
                  value={firstName}
                  onChange={(e) => setfirstName(e.target.value)}
                />
              </div>
              <div className="w-[49%]">
                <h5>Last Name</h5>
                <input
                  type="text"
                  value={lastName}
                  onChange={(e) => setlastName(e.target.value)}
                />
              </div>
            </div>

            <div className="two-input-view">
              <div className="w-[49%]">
                <h5>User Id</h5>
                <input
                  type="text"
                  value={userId || ""}
                  onChange={UserIdSetup}
                  onKeyDownCapture={() => setFocus(true)}
                  maxLength={30}
                />
                {focus && userId.length > 0 && showCharLength <= 30 ? (
                  <h6
                    className={
                      !isValid ? "LengthData_with_Error" : "LengthData"
                    }
                  >
                    {!isValid
                      ? "Invalid character"
                      : `Your userId length is ${showCharLength}`}{" "}
                  </h6>
                ) : null}
              </div>
              <div className="w-[49%]">
                <h5>Password</h5>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>

            <div className="w-[100%]">
              <h5>E-mail</h5>
              <input
                type="text"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </ContainerWithoutFooter>
  );
}

export default CreateAdmin;
