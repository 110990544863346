import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../helper/axiosInstance";
import { toast } from "react-toastify";
import { Constants } from "../utils/Constants";

const initialState = {
  socket: null,
  messages: [],
  isNewMessage: false,
  NewHighLightUser: [],
  userId: null,
  unreadMessageCounts: {},

  getAllMessagesLoading: false,
  getAllUsersLoading: false,
  allMessagesData: [],
  //-------create
  sendMessageLoading: false,
  sendMessageError: "",
  ChatRoomsUsers: [],
  AllChatRooms: [],
  RecieverDetails: [],
  AllUsers: [],
  currentChat: null,
  currentChatRoomId: null,
  currentPage: 0,
  messagesPerPage: 10,
  notification: [],
  newMessageCount: 0,
  unreadMsg: [],
  favList: [],
  notifications: [],
  isNotifyOpen: false,
  getnotificationLaoding: false,
  usersNotFoundMessage: "",
  isNotificationBusiness: true,
  isNotificationCustomer: true,
  notificationToSHow: "",
  oldMessage: "",
  PreviousChatRoomId: "",
  ChatData: [],
  IsReceivedNewQA: false,
  ReceivedQAData: [],
  SessionExpiredby: "",
  isRemoved: false,
  RemoveRoom: [],
  isNotificationLoading: false,
  notificationErr: "",
  //--
  AddSecondryEmailLoading: "",
  AddSecondryEmailData: "",
  AddSecondryEmailError: "",
  totalChatUsersCount: 0,
  chatUserListLength: 0,
  StartedRecords: 0,
  ToRecords: 0,
  totalChatUser: 0,
};
export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    connectSocket: (state, action) => {
      state.isSocketConnected = action.payload;
      // state.userId = action.payload.userId;
      console.log("Connected to socket" + state.isSocketConnected);
    },
    disconnectSocket: (state) => {
      state.socket = null;
    },
    receiveMessage: (state, action) => {
      console.log(
        "Received msgs from server >>>>>>>>>>>>>>>>>>>",
        action.payload.chatUser
      );
      state.messages = [...action.payload, ...state.messages];
      state.isNewMessage = true;
      state.allMessagesData = [...action.payload, ...state.allMessagesData];
      state.newMessageCount = state.newMessageCount + 1;
      state.notificationToSHow = action.payload;
      state.unreadMsg = action.payload;
      state.notification = action.payload
    },

    getChatNotification: (state, action) => {
      console.log(
        "Received chat notitfication from server?????????????????????????????",
        action.payload?.chatUser?.id,
        action.payload?.chatUser
      );
      // state.notificationToSHow = [action.payload];
      state.ChatData = action.payload;
      state.isNewMessage = true;
      state.NewHighLightUser = action.payload?.chatUser;
      // state.searchCustomerAccountData?.map(user => {
      //   console.log("IsMAthced",user?.userId)
      //   if (user.id === action.payload?.chatUser?.id) {

      //     return {
      //       ...user,
      //       isLastMsgRead: false,
      //     };
      //   }
      //   return user;
      // });
    },
    getNotifications: (state, action) => {
      console.log("Received getNotifications from server", action.payload);
      state.unreadMsg = action.payload;
      state.notification = action.payload;

      // console.log("state.currentChat", state.currentChat);
      // if (isChatOpen) {

      // }
      // },
      // incrementUnreadMessageCount: (state, action) => {
      //   console.log("incrementUnreadMessageCount", action.payload);
      //   const { userId } = action.payload;
      //   state.unreadMessageCounts[userId] =
      //     (state.unreadMessageCounts[userId] || 0) + 1;
    },

    getAllMessages: (state, action) => {
      if (!state.getAllMessagesLoading) {
        state.getAllMessagesLoading = true;
      }
      state.getnotificationLaoding = action.payload ? false : true;
    },
    getAllMessagesSuccess: (state, action) => {
      console.log("getAllMessagesSuccess-", action.payload);
      return {
        ...state,
        messages: action.payload,
        getAllMessagesLoading: false,
      };
    },

    getAllMessagesSuccesswithPagination: (state, action) => {
      console.log("withPagination-", action.payload);
      return {
        ...state,
        messages: [...state.messages, ...action.payload],
        getAllMessagesLoading: false,
      };
    },

    getAllMessagesFailed: (state) => {
      state.getAllMessagesLoading = false;
    },

    sendMessage: (state) => {
      state.sendMessageLoading = true;
    },
    sendMessageSuccess: (state, action) => {
      console.log("from socletRS", action.payload);
      state.sendMessageLoading = false;
      state.messages = [...state.messages, ...action.payload];
    },
    sendMessageFailed: (state) => {
      state.sendMessageLoading = false;
      state.sendMessageError = "Unable to send message";
    },

    getAllChatRoomsUser: (state, action) => {
      state.getAllUsersLoading = true;
      // state.getAllMessagesLoading = true;
    },

    getAllChatRoomsUserSuccess: (state, action) => {
      console.log("##########SAM", action.payload);
      state.getAllUsersLoading = false;
      state.ChatRoomsUsers = action.payload;
    },
    getAllChatRoomsSuccess: (state, action) => {
      console.log('getAAA', action.payload)
      state.getAllUsersLoading = false;
      state.AllChatRooms = action.payload?.data;
    },

    getAllChatRoomsUserFailed: (state) => {
      state.getAllUsersLoading = false;
    },

    updateMessageStatus: (state, action) => {
      return {
        ...state,
        messages: state.messages.map(msg =>
          msg.tempId === action.payload.tempId
            ? { ...msg, status: action.payload.status }
            : msg
        )
      };
    },

    getAllReceivers: (state, action) => {
      console.log("getAllReceivers", action.payload);
      const userWithLastMsg = action.payload.usersWithLastMsg.map((user) => {
        // Determine if the user is a business or customer
        const isBusiness = !!user.business;
        const isCustomer = !!user.customer;
        const accountType = isBusiness
          ? "business"
          : isCustomer
            ? "customer"
            : "unknown";

        return {
          // Spread user properties
          ...user,

          // Flatten relevant business/customer properties into the main object
          ...(isBusiness ? { ...user.business } : {}),
          ...(isCustomer ? { ...user.customer } : {}),

          // Additional chat details added at the root level
          accountType, // To differentiate between business and customer
          lastMessage: user.lastMessage || null,
          lastChatTime: user.lastChatTime || null,
          isLastMsgRead: user.isLastMsgRead || false,
          isSessionExpired: user.isSessionExpired || null,
          LastMsgReadBy: user.LastMsgReadBy || null,
          isSenderAdmin: user.isSenderAdmin || false,
          unAnswered: user.unAnswered || false,
          UnReadMsgCount: user.UnReadMsgCount || 0,
          ChatId: user.id, // Assuming 'id' is the unique chat session identifier

          // Default values for fields that might not exist
          businessName: user.business?.businessName || null,
          fullName: user.customer?.fullName || null,
          city: user.business?.city || user.customer?.city || null,
          streetAddress:
            user.business?.streetAddress ||
            user.customer?.streetAddress ||
            null,
          state: user.business?.state || user.customer?.state || [],
          zipCode: user.business?.zipCode || user.customer?.zipCode || null,
          phoneNumber:
            user.business?.phoneNumber || user.customer?.phoneNumber || null,
          emailAddress:
            user.business?.emailAddress || user.customer?.emailAddress || null,
          image: user.business?.image || user.customer?.image || null,
          location: user.business?.location || user.customer?.location || [],
          serviceCategoryId:
            user.business?.serviceCategoryId ||
            user.customer?.serviceCategoryId ||
            null,
          serviceSubCategoryId:
            user.business?.serviceSubCategoryId ||
            user.customer?.serviceSubCategoryId ||
            [],
          unavailableLocation:
            user.business?.unavailableLocation ||
            user.customer?.unavailableLocation ||
            null,
          unavailableServiceSubCategoryId:
            user.business?.unavailableServiceSubCategoryId ||
            user.customer?.unavailableServiceSubCategoryId ||
            null,
          isVerified:
            user.business?.isVerified || user.customer?.isVerified || false,
          isBlockedAccount:
            user.business?.isBlockedAccount ||
            user.customer?.isBlockedAccount ||
            false,
          connectedTo: user.receiverId || [],
        };
      });

      console.log("usersWithLastMsg", userWithLastMsg);
      //       console.log('payload', (action.payload.CurrentPage - 1) * Constants.TakeForChatUser + 1);
      //  let AdminPaginationDataLength = action.payload?.usersWithLastMsg?.length - 1;
      //   let adminStartedRecords = (action.payload.CurrentPage - 1) * Constants.TakeForChatUser + 1;
      //   let adminToRecords = Math.min(action.payload.CurrentPage * Constants.TakeForChatUser ,  action.payload?.usersWithLastMsg?.length);
      //   let totalAdminPage = Math.ceil(action.payload?.total / Constants.TakeForChatUser);

      //   console.log('AdminPaginationDataLength',adminStartedRecords,adminToRecords,totalAdminPage);
      return {
        ...state,
        RecieverDetails: userWithLastMsg, // Store the updated users with embedded details
        AllUsers: userWithLastMsg, // Also update AllUsers for consistency
        totalChatUsersCount: Math.ceil(
          action.payload?.total / Constants.TakeForChatUser
        ),
        getAllMessagesLoading: false,
        totalChatUser: action.payload?.total
      };
    },

    AddNewUserToChat: (state, action) => {

      const isUserExist = state.RecieverDetails.some(
        (user) => user?.id === action.payload?.id
      );
      console.log("AddNewUserToChat", action.payload);
      if (!isUserExist) {
        return {
          ...state,
          RecieverDetails: [action.payload, ...state.RecieverDetails],
        };
      }
      return state;
    },
    GetPrviousChatID: (state, action) => {
      return {
        ...state,
        PreviousChatRoomId: action.payload,
      };
    },
    setCloseChatSesion: (state, action) => {
      return {
        ...state,
        PreviousChatRoomId: action.payload,
      };
    },
    setNewlyCreatedqa: (state, action) => {
      console.log("actin", action.payload);
      return {
        ...state,
        IsReceivedNewQA: action.payload === false ? false : true,
        ReceivedQAData: action.payload === false ? [] : action.payload,
      };
    },
    chatSessionExpired: (state, action) => {
      console.log("action chatSessionExpired", action.payload);
      return {
        ...state,
        SessionExpiredby: action.payload,
        RemoveRoom: [],
        isRemoved: false,
      };
    },
    removeFromChat: (state, action) => {
      return {
        ...state,
        RemoveRoom: action.payload,
        isRemoved: true,
      };
    },
    UnBlurredChatForOtherAdmins: (state, action) => {
      console.log("unBLur");
      return {
        ...state,
        RemoveRoom: [],
        isRemoved: false,
      };
    },

    getAllReceiversPaginated: (state, action) => {
      return {
        ...state,
        // RecieverDetails: action.payload,

        // totalChatUsersCount: action.payload.total,
        getAllMessagesLoading: false,
      };
    },

    getAllReceiversFailled: (state, action) => {
      state.getAllUsersLoading = false;
      state.getAllMessagesLoading = false;
      state.getnotificationLaoding = false;
      state.usersNotFoundMessage = action.payload || "No data available";
    },

    setCurrentChat: (state, action) => {
      state.currentChat = action.payload;
    },

    setFavList: (state, action) => {
      const { userId, adminId } = action.payload;

      const userIndex = state.RecieverDetails.findIndex((user) => user.id === userId);

      if (userIndex !== -1) {
        let user = state.RecieverDetails[userIndex];

        const isFav = user.FavByAdmin?.includes(adminId);

        const updatedFavByAdmin = isFav
          ? user.FavByAdmin.filter((id) => id !== adminId)
          : [...(user.FavByAdmin || []), adminId];

        state.RecieverDetails[userIndex] = { ...user, FavByAdmin: updatedFavByAdmin };
      }
    },


    getDocsNotifications: (state, action) => {
      console.log("Received getNotifications from docs", action.payload);

      let arr = [action.payload];

      state.notifications = [...arr, ...state?.notifications];
      state.isNotifyOpen = true;
      // state.getAllMessagesLoading = false;
    },

    getAllNotification: (state, action) => {
      console.log("state from notification...", action.payload);
      return {
        ...state,
        notifications: action.payload[0],
        notificationLength: action.payload[1],
        isNotificationLoading: false,
        notificationErr: "",
        // getnotificationLaoding: false,
      };
    },

    updateNotification: (state, action) => {
      console.log("updated Notifications from api", action.payload);
      return {
        ...state,
        notifications: action.payload,
        getnotificationLaoding: false,
      };
    },
    setIsNotifyOpen: (state, action) => {
      console.log("called isNotifyOpen", action.payload);
      state.isNotifyOpen = action.payload;
    },

    //--------------------------
    getNotificationSuccessCustomer: (state, action) => {
      state.isNotificationCustomer = action.payload;
    },
    getNotificationSuccessBusiness: (state, action) => {
      state.isNotificationBusiness = action.payload;
    },
    SetPrevMessage: (state, action) => {
      state.oldMessage = action.payload;
    },
    getnotificationLoader: (state, action) => {
      return {
        ...state,
        isNotificationLoading: action.payload,
        notificationLength: 0,
        notifications: [],
      };
    },
    getnotificationErr: (state, action) => {
      return {
        ...state,
        notificationErr: action.payload,
        notificationLength: 0,
        notifications: [],
      };
    },
    Changesecondaryemail: (state, action) => {
      return {
        ...state,
        AddSecondryEmailLoading: true,
      };
    },
    ChangesecondaryemailSuccess: (state, action) => {
      return {
        ...state,
        AddSecondryEmailLoading: false,
        AddSecondryEmailData: action.payload,
      };
    },
    ChangesecondaryemailFailed: (state, action) => {
      return {
        ...state,
        AddSecondryEmailLoading: false,
        AddSecondryEmailError: action.payload,
      };
    },
  },
});

export const getAllMessagesAsync =
  (user, take, skip, isPaginated) => async (dispatch) => {
    dispatch(getAllMessages());
    dispatch(getAllChatRoomsUserSuccess(user));
    console.log("caliing getAllMessagesAsync", user?.ChatId, take, skip,);
    try {
      const response = await axiosInstance
        .get("Chat/getMessageByChatRoomID", {
          params: { ChatID: user?.ChatId, projectId: "", take, skip },
        })

      console.log("respppppppppp, all messages :", response);
      if (response) {
        if (isPaginated) {
          dispatch(getAllMessagesSuccesswithPagination(response.data));
        } else {
          dispatch(getAllMessagesSuccess(response.data));
        }
        return response.data
      }
    }
    catch (err) {
      console.log(err);
      dispatch(getAllMessagesFailed());
      return false
    }
  };

export const sendMessageAsync = (data) => async (dispatch) => {
  console.log("redux data", data);

  dispatch(sendMessage());
  try {
    const response = await axiosInstance.post("Chat/sendMessage", data);
    console.log("response", response);
    if (response) {
      toast.success("Message sent !!");

      dispatch(sendMessageSuccess(response.data));
      return "ad create success";
    }
  } catch (err) {
    console.log(err);
    dispatch(sendMessageFailed(err.response.data));
    return "error";
  }
};

export const getChatRoomsUser =
  (senderId, receiverId, AccType) => async (dispatch) => {
    dispatch(getAllChatRoomsUser());
    console.log("senderId,  receiverId", senderId, receiverId);
    try {
      let res = await axiosInstance.get("Chat/getChatRoom", {
        params: {
          senderId,
          receiverId,
          AccType,
        },
      });
      if (res) {
        dispatch(getAllChatRoomsUserSuccess(res.data));
        console.log("response getChatRoomsUser", res.data);
        return res.data;
      }
    } catch (err) {
      console.log(err);
      dispatch(getAllChatRoomsUserFailed());
      return false;
    }
  };
export const getAllChatRooms = () => async (dispatch) => {
  dispatch(getAllChatRoomsUser());
  console.log("Fetching all ChatRoom...........",);
  try {
    let res = await axiosInstance.get("Chat/getAllChatRoom");
    if (res) {
      dispatch(getAllChatRoomsSuccess(res.data));
      console.log("response getChatRoomsUser", res.data);
      return res.data;
    }
  } catch (err) {
    console.log(err);
    dispatch(getAllChatRoomsUserFailed());
    return false;
  }
};
export const MarkMsgRead = (chatId, AdminId) => async () => {
  console.log("MarkMsgRead...........", chatId, AdminId);
  try {
    let res = await axiosInstance.get("Chat/markMessageAsReadByAdmin", {
      params: {
        chatId,
        AdminId,
      },
    });
    if (res) {
      console.log("response markMessageAsReadByAdmin", res.data);
      return res.data;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};
export const getReceivers =
  (adminId, userId, location, take, skip, isPaginated, favoritesOnly, unAnswered) => (dispatch) => {
    console.log("getReceivers", adminId, userId, take, skip, favoritesOnly, unAnswered);
    dispatch(getAllMessages());
    axiosInstance
      .get("BusisnessUser/GetAllBuisnesAndCustomerForChat", {
        params: { adminId, userId, location, take, skip, favoritesOnly, unAnswered },
      })
      .then((response) => {
        response.data.CurrentPage = skip;

        // if (isPaginated) {
        //   console.log(
        //     "isPaginated",
        //     take,
        //     skip,
        //     isPaginated,
        //     response.data?.usersWithLastMsg
        //   );
        // dispatch(getAllReceiversPaginated(response?.data));
        // } else {
        //   console.log(
        //     "isPaginatedNoT",
        //     take,
        //     skip,
        //     isPaginated,
        //     response.data?.usersWithLastMsg,
        //     response.data?.total
        //   );
        dispatch(getAllReceivers(response?.data));
        // }
      })
      .catch((err) => {
        console.log("found err", err?.response?.data?.message);
        dispatch(getAllReceiversFailled(err?.response?.data?.message));
      });
  };

export const getAllNotifications = (isNotShowingLoader) => (dispatch) => {
  dispatch(getnotificationLoader(true));

  let notificationProvider = [Constants.CUSTOMER, Constants.BUSINESS];

  axiosInstance
    .get("upload/getAllNotifications", {
      params: {
        notification: [Constants.DOCUMENTUPDATE],
        notificationProvider: notificationProvider,
        pagr: 1, //Replace when implement pagination
        perPage: 50, //Replace when implement pagination
      },
    })
    .then((response) => {
      if (response?.data?.success) {
        dispatch(getAllNotification(response?.data?.data));
      } else {
        dispatch(getnotificationLoader(false));
      }
    })
    .catch((err) => {
      console.log(err?.response?.data?.message);
      dispatch(getnotificationLoader(false));
      dispatch(getnotificationErr(err?.response?.data?.message));
    });
};

export const UpdateNotifications = (data) => (dispatch) => {
  dispatch(getAllMessages());
  axiosInstance
    .post("upload/UpdateNotification", data)
    .then((response) => {
      if (response.data.success) {
        console.log("updatedNoti", response?.data?.data);
        dispatch(getAllNotifications(true));
      } else {
        // dispatch(getAllReceiversFailled());
      }
    })
    .catch((err) => {
      console.log(err);
      // dispatch(getAllReceiversFailled());
    });
};

export const AddFavList = (adminId, userId) => (dispatch) => {
  if (!userId) {
    console.error("❌ Error: userId is undefined!");
    return;
  }

  console.log("✅ Sending AddFavList request:", { adminId, userId });

  axiosInstance.post(`/BusisnessUser/toggleFavorite?adminId=${adminId}&userId=${userId}`).then((response) => {
    if (response.data.success) {
      console.log("✅ AddFavList Response:", response.data);
      dispatch(setFavList(userId, adminId)); // Uncomment if needed
    }
  })
    .catch((err) => {
      console.error("❌ AddFavList API Error:", err);
    });
};

export const AddUserToConversation = (users) => (dispatch) => {
  dispatch(AddNewUserToChat(users));
};
export const getPreviousChatRoomId = (chatRoomId) => (dispatch) => {
  dispatch(GetPrviousChatID(chatRoomId));
};

export const closeChatSession = (data) => (dispatch) => {
  dispatch(chatSessionExpired(data));
};
export const setNewlyCreatedQA = (data) => (dispatch) => {
  dispatch(setNewlyCreatedqa(data));
};
export const RemoveUsersFromChat = (data) => (dispatch) => {
  dispatch(removeFromChat(data));
};
export const UnBlure = () => (dispatch) => {
  dispatch(UnBlurredChatForOtherAdmins());
};
export const updateMessage = (data) => (dispatch) => {
  dispatch(updateMessageStatus(data));
};

export const GetAllNotificationCustomerAsync = () => (dispatch) => {
  axiosInstance
    .get("/upload/getIsReviewBusinessCustomer", {
      params: {
        accountType: "customer",
      },
    })
    .then((response) => {
      console.log("resppppppp1", response);
      if (response) {
        dispatch(getNotificationSuccessCustomer(response?.data));
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
export const GetAllNotificationBusinessAsync = (accountType) => (dispatch) => {
  axiosInstance
    .get("/upload/getIsReviewBusinessCustomer", {
      params: {
        accountType: "business",
      },
    })
    .then((response) => {
      console.log("resppppppp2", response);
      if (response) {
        dispatch(getNotificationSuccessBusiness(response?.data));
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
export const SetPrevMesageAsyncs = (prevData) => (dispatch) => {
  dispatch(SetPrevMessage(prevData));
};

export const closeChatSessionById = (id, userId, ChatId) => {
  return async (dispatch) => {
    console.log("closeChatSession userId", id);
    try {
      const response = await axiosInstance.get(
        "AdminConroller/closeChatSession",
        {
          params: {
            id,
            userId,
            ChatId,
          },
        }
      );
      if (response?.data) {
        const data = response.data;
        // dispatch(closeChatSession(data));
        console.error("Sessionclose REquest", data);
      }
    } catch (error) {
      console.error("Error while fetching admin details:", error);
    }
  };
};
export const ChangesecondaryemailAsync = (seconemail) => async (dispatch) => {
  let loggesInUser = JSON.parse(sessionStorage.getItem("userLoggedDetails"));
  dispatch(Changesecondaryemail());

  let data = {
    adminuuid: loggesInUser?.id,
    seconemail: seconemail,
  };

  try {
    let response = await axiosInstance.post(
      "upload/updateSecondaryEmail",
      data
    );
    if (response) {
      dispatch(ChangesecondaryemailSuccess(true));
      console.log("response.....", response);
      sessionStorage.setItem(
        "userLoggedDetails",
        JSON.stringify(response?.data)
      );
    }
    return response;
  } catch (err) {
    dispatch(ChangesecondaryemailFailed());
    toast.error(
      err?.response?.data?.error?.message
        ? err?.response?.data?.error?.message
        : "Somthing went wrong"
    );
    return err; // Return the error if it occurs
  }
};

// Action creators are generated for each case reducer function
export const {
  connectSocket,
  disconnectSocket,
  receiveMessage,
  incrementUnreadMessageCount,
  getAllMessages,
  getAllMessagesSuccess,
  getAllMessagesFailed,
  sendMessage,
  sendMessageSuccess,
  sendMessageFailed,
  getAllChatRoomsUser,
  getAllChatRoomsUserFailed,
  getAllReceivers,
  getAllReceiversPaginated,
  getAllNotification,
  updateNotification,
  getAllReceiversFailled,
  setCurrentChat,
  getNotifications,
  getAllMessagesSuccesswithPagination,
  getAllChatRoomsUserSuccess,
  getAllChatRoomsSuccess,
  setFavList,
  setIsNotifyOpen,
  getDocsNotifications,
  getNotificationSuccessCustomer,
  getNotificationSuccessBusiness,
  AddNewUserToChat,
  SetPrevMessage,
  getChatNotification,
  GetPrviousChatID,
  setCloseChatSesion,
  setNewlyCreatedqa,
  chatSessionExpired,
  removeFromChat,
  getnotificationLoader,
  getnotificationErr,
  Changesecondaryemail,
  ChangesecondaryemailSuccess,
  ChangesecondaryemailFailed,
  UnBlurredChatForOtherAdmins,
  updateMessageStatus
} = chatSlice.actions;

export default chatSlice.reducer;
