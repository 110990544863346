import { Route, Routes, Navigate } from "react-router-dom";
import Login from "../pages/Auth/Login";
import HomeLandingPage from "../pages/Auth/HomeLandingPage";
import ComingSoonPage from "../pages/Auth/ComingSoonPage";

const AuthRoutes = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<HomeLandingPage />} /> */}
      <Route path="/" element={<ComingSoonPage />} />
      <Route path='/madmin' element={<Login />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default AuthRoutes;
