import React, { useEffect, useState, useRef } from "react";
import "./TempCustomerAccount.scss";
import Container from "../../../components/HOC/Container";
import Dialog from "@mui/material/Dialog";
import Group_345 from "../../../assets/icons/Group_345.svg";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import ThreeDot from "../../../assets/icons/threedot.svg";
import threedot_red from "../../../assets/icons/threedot_red.svg";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "../../../assets/icons/Unfilled.svg";
import Eye from "../../../assets/icons/eye.svg";
import Eye1 from "../../../assets/icons/eyeClose.svg";
import FilledEditd from "../../../assets/icons/FilledEditd.svg";
import avatar from "../../../assets/icons/avatar.svg";
import { Tooltip } from "react-tooltip";

import {
  getSearchCustomerAsync,
  selectedUserDetails,
  UserAccountType,
  UpdateTempPassAsync,
  getAllTempCustomerSuccess,
  UpdatemessageStatusAsync,
} from "../../../redux/usersSlice";
import StarRatings from "react-star-ratings";
import { Constants } from "../../../utils/Constants";
import Footer from "../../../components/Footer/Footer";

import SearchCusAccountDeleteDialog from "../../../components/Dialogs/AdminPanelActionDialog/SearchCusAccountDeleteDialog";
import Loader from "../../../components/Loader";
import { useNavigate } from "react-router-dom";
import { Checkbox } from "@mui/material";
//---------
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import makeStyles from "@mui/styles/makeStyles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TablePagination from "@mui/material/TablePagination";
function TempCustomerAccount() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    tempCustomerAccountData,
    tempCustomerAccountLoading,
    PaginationDataLength,
    startedRecords,
    toRecords,
    totalPages,
    updateTempLoading,
  } = useSelector((state) => state.users);
  const { IsReceivedNewQA, ReceivedQAData } = useSelector(
    (state) => state.chat
  );
  const [showPassword, setShowPassword] = useState(false);
  const [deleteData, setDeleteData] = useState(false);
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [address, setAddress] = useState("");
  const [enteredPass, setInteredPass] = useState("");
  const [page, setPage] = useState(1);
  const [allIds, setAllIds] = useState([]);
  const [selectAllIds, setSelectAllIds] = useState([]);
  const [checked1, setChecked1] = useState(false);
  const [checkboxStates, setCheckboxStates] = useState(false);
  const [passwordDialog, setPasswordDialog] = useState(false);
  const [selectedUserPasswords, setSelectedUserPasswords] = useState({});
  const [isSort, setIsSort] = useState(false);
  const SelectedBussinessEmail = useRef();
  const [passwordCheck, setPasswprdCheck] = useState(false);
  const perPage = Constants.TAKE;
  var arr = [];
  // Styling
  const classes = useStyles();
  const handleChange = (event, value) => {
    // setPage(value); // Update the page directly with the selected value
    data.page = value;
    dispatch(getSearchCustomerAsync(data));
  };
  const [sortingOrder, setSortingOrder] = useState("asc"); //
  const sorting = () => {
    setIsSort(!isSort);
    if (!isSort) {
      sortDataAsc(); // Ascending order
    } else {
      sortDataDesc(); // Descending order
    }
  };

  const data = {
    name,
    location,
    email,
    userName,
    address,
    page: 1,
    perPage: Constants.TAKE,
    temp: 1,
    isSort: 0,
    // isSort: isSort,
  };
  const handleClose = () => {
    setDeleteData(false);
  };

  useEffect(() => {
    dispatch(getSearchCustomerAsync(data));
    dispatch(UserAccountType("CUSTOMER_USER"));
    // if (tempCustomerAccountData?.length <= 0) {
    //   console.log('first call in temp ')
    // } else (
    //   console.log('loaded')
    // )
  }, [updateTempLoading]);

  const validate = () => {
    if (enteredPass?.length < 8) {
      setPasswprdCheck(true);
    } else {
      setPasswprdCheck(false);
    }
  };

  useEffect(() => {
    if (enteredPass?.length === 0) {
      setPasswprdCheck(false);
    } else if (enteredPass?.length >= 8 && enteredPass?.length < 20) {
      setPasswprdCheck(false);
    } else {
      setPasswprdCheck(true);
    }
  }, [validate]);

  const DeleteTempUser = (item) => {
    console.log("iiii", item);
    setDeleteData(true);
    SelectedBussinessEmail.current = item?.id;
  };
  const SearchAdminByQuery = (e) => {
    setName(e.target.value);
    setLocation("");
    setEmail("");
    setUserName("");
    setAddress("");
  };

  const getDatabyQuery = () => {
    dispatch(getSearchCustomerAsync(data));
  };

  const NavigateToProfile = (item) => {
    dispatch(selectedUserDetails(item));
    navigate("/UserProfile/UserProfile");
  };
  const toggleSelectAll = () => {
    const extractedIds = tempCustomerAccountData?.map((item) => item?.userId);
    if (selectAllIds?.length === extractedIds?.length) {
      setCheckboxStates({});
      setSelectAllIds([]);
      setChecked1(false);
    } else {
      setAllIds(extractedIds);
      const newCheckboxStates = [];
      extractedIds.forEach((id) => {
        newCheckboxStates[id] = true;
      });
      setCheckboxStates(newCheckboxStates);
      setSelectAllIds(extractedIds);
      setChecked1(true);
    }
  };
  const toggleCheckbox = (item) => {
    const extractedIds = tempCustomerAccountData?.map((item) => item?.userId);
    const rowId = item?.userId;
    const newCheckboxStates = { ...checkboxStates };
    newCheckboxStates[rowId] = !newCheckboxStates[rowId];
    const newSelectAllIds = newCheckboxStates[rowId]
      ? [...selectAllIds, rowId]
      : selectAllIds.filter((id) => id !== rowId);
    setCheckboxStates(newCheckboxStates);
    setSelectAllIds(newSelectAllIds);
    if (newSelectAllIds.length !== extractedIds?.length) {
      setChecked1(false);
    } else {
      setChecked1(true);
    }
  };
  const EnterPassword = () => {
    setPasswordDialog(true);
  };
  const SubmitChange = (e) => {
    // if (validate()) {
    const passwordsArray = selectAllIds.map(() => enteredPass);
    const passwordsArrayAll = allIds.map(() => enteredPass);
    e.preventDefault();
    let Data = {
      accountType: "customer",
      changePassword: checked1 === true ? passwordsArrayAll : passwordsArray,
      userIds: checked1 === true ? allIds : selectAllIds,
    };
    dispatch(UpdateTempPassAsync(Data, setPasswordDialog)).then((r) => {
      if (r?.success === true) {
        dispatch(getSearchCustomerAsync(data));
      } else {
        console.log("not updated");
      }
    });
    setPasswordDialog(false);
    setCheckboxStates(false);
    setChecked1(false);
    setAllIds([]);
    setSelectAllIds([]);
    // }
  };
  const sortDataAsc = () => {
    const sortedData = [...tempCustomerAccountData];
    sortedData.sort((a, b) => {
      const locationA = a.data.location.toString().toLowerCase();
      const locationB = b.data.location.toString().toLowerCase();
      return locationA.localeCompare(locationB);
    });
    dispatch(getAllTempCustomerSuccess(sortedData));
  };
  const sortDataDesc = () => {
    const sortedData = [...tempCustomerAccountData];
    sortedData.sort((a, b) => {
      const locationA = a.data.location.toString().toLowerCase();
      const locationB = b.data.location.toString().toLowerCase();
      return locationB.localeCompare(locationA);
    });
    dispatch(getAllTempCustomerSuccess(sortedData));
  };
  // const toggleShowPassword = (userId) => {
  //   setShowPassword((prevState) => ({
  //     ...prevState,
  //     [userId]: !prevState[userId],
  //   }));
  // };
  const toggleShowPassword = (userId) => {
    setSelectedUserPasswords((prevState) => ({
      ...prevState,
      [userId]: !prevState[userId],
    }));
  };
  // const togglePasswordVisibility = (userId) => {
  //   setSelectedUserPasswords((prevPasswords) => ({
  //     ...prevPasswords,
  //     [userId]: !prevPasswords[userId],
  //   }));
  // };
  // Broken image functionality

  const FailedToLoad = (e) => {
    console.log("not opened");
    e.target.src = avatar;
  };
  return (
    <Container>
      {tempCustomerAccountLoading || updateTempLoading ? <Loader /> : null}
      <div className="TempCustomerParentContainer">
        <div className="inner-containerTempCustomer ">
          <div className="HeadingAndSearchTempCust">
            <h1>Temporary Customer Account</h1>
            <div className="Icon-containerTempCust">
              <div className="Input-and-iconsTempCust ">
                <SearchIcon
                  onClick={getDatabyQuery}
                  className="searchIconTempCust"
                />
                <input
                  onChange={SearchAdminByQuery}
                  onKeyDown={(event) => {
                    event.key === "Enter" && getDatabyQuery();
                  }}
                  className="Input-fieldTempCust"
                  type="text"
                  placeholder="Search...."
                />
              </div>
              <div
                onClick={() =>
                  navigate("/CreateTempAccount/CreateTemporaryAccount", {
                    state: {
                      data: { data },
                    },
                  })
                }
                className="Create-iconsTempCust"
              >
                <AddIcon className="AddIcon-styleTempCust " />
                <p>Create </p>
              </div>
            </div>
          </div>
          <TableContainer sx={{ height: "calc( 100% - 80px)" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow className={classes.root}>
                  <TableCell>
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <Checkbox checked={checked1} onChange={toggleSelectAll} />
                      <p>User ID/Name</p>
                      <img
                        style={{ width: 14, marginLeft: "0.2rem" }}
                        src={Group_345}
                        alt="Group_345"
                      />
                    </span>
                  </TableCell>
                  <TableCell>
                    <span style={{ display: "flex" }}>
                      <p>Location</p>
                      <img
                        onClick={sorting}
                        style={{ width: 14, marginLeft: "0.2rem" }}
                        src={Group_345}
                        alt="Group_345"
                      />
                    </span>
                  </TableCell>
                  <TableCell>
                    <span style={{ display: "flex" }}>
                      <p>No. of Posted Projects</p>
                      <img
                        style={{ width: 14, marginLeft: "0.2rem" }}
                        src={Group_345}
                        alt="Group_345"
                      />
                    </span>
                  </TableCell>
                  <TableCell>
                    <span style={{ display: "flex", marginLeft: "0.5rem" }}>
                      <p>Average Rating</p>
                      <img
                        style={{ width: 14, marginLeft: "0.5rem" }}
                        src={Group_345}
                        alt="Group_345"
                      />
                    </span>
                  </TableCell>
                  <TableCell>
                    <span style={{ display: "flex" }}>
                      <p>Q&A</p>
                      <img
                        style={{ width: 14, marginLeft: "0.2rem" }}
                        src={Group_345}
                        alt="Group_345"
                      />
                    </span>
                  </TableCell>
                  <TableCell sx={{ height: "30px" }}>
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {/* <Checkbox checked={checked1}
                        onChange={toggleSelectAll} /> */}
                      <p>Password</p>
                      {/* <button
                        onClick={() => setShowPassword(!showPassword)}
                        className="eyeButton w-4 ml-1"
                      >
                        <img className="Eye" src={Eye} alt="Eye" />
                      </button> */}

                      {checked1 || selectAllIds.length > 0 ? (
                        <div className="ApplyBTNCustomer">
                          <img
                            onClick={EnterPassword}
                            src={FilledEditd}
                            alt="Edit"
                          />
                        </div>
                      ) : (
                        <div className="ApplyBTNCustomer">
                          <img src={FilledEditd} alt="Edit" />
                        </div>
                      )}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span style={{ display: "flex" }}>
                      <p>Actions</p>
                      <img
                        style={{ width: 14, marginLeft: "0.2rem" }}
                        src={Group_345}
                        alt="Group_345"
                      />
                    </span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tempCustomerAccountData?.map((item, i) => {
                  return (
                    <TableRow
                      className={classes.bodyroot}
                      hover
                      role="checkbox"
                    >
                      <TableCell key={i}>
                        <span className="flex  justify-start items-center  ">
                          <Checkbox
                            checked={checkboxStates[item?.userId] || checked1}
                            onChange={() => toggleCheckbox(item, i)}
                          />
                          <img
                            style={{
                              cursor: "pointer",
                              width: "2rem",
                              height: "2rem",
                              borderRadius: "50%",
                              border: "1px solid grey",
                            }}
                            src={
                              item?.image?.includes(".jpg" || ".png" || ".jpeg")
                                ? "https://" + item?.image
                                : avatar
                            }
                            onError={FailedToLoad}
                            alt="user"
                            onClick={() => NavigateToProfile(item)}
                          />

                          <span
                            onClick={() => NavigateToProfile(item)}
                            className=" FirstClild"
                          >
                            <span className="underLineText cursor-pointer">
                              {item?.userId}
                            </span>
                            <span className="returingDataDate">
                            {item?.firstName}  {item?.lastName}
                            </span>
                          </span>
                        </span>
                      </TableCell>
                      <TableCell>
                        <span className="flex items-center">
                          {item?.location?.map((data, i) => (
                            <span key={i} className="returingDataTemp">
                              {data}
                              {i < item.location.length - 1 && ", "}
                            </span>
                          ))}
                        </span>

                        {/* {isSort == true ? (
                          <span className="flex items-center">
                            {item?.location.map((item, i, array) => (
                              <React.Fragment key={i}>
                                {i === 0 || item !== array[i - 1] ? (
                                  <div className="returingDataTemp">
                                    {item} ,
                                  </div>
                                ) : null}
                                {data.length !== 0 ? null : (
                                  <div className="returingDataTemp">
                                    {item?.location.filter(
                                      (val) => val === item
                                    ).length - 1}
                                  </div>
                                )}
                              </React.Fragment>
                            ))}
                          </span>
                        ) : (
                          <span className="flex  items-center ">
                            {item?.location?.map((data, i) => (
                              <>
                                <span className="returingDataTemp">{data},</span>
                                {data.length !== 0 ? null : (
                                  <div className="carbDiv">
                                    {item?.location.length - 1}
                                  </div>
                                )}
                              </>
                            ))}
                          </span>
                        )} */}
                      </TableCell>
                      <TableCell
                        style={{
                          color:
                            (IsReceivedNewQA &&
                              ReceivedQAData?.customerId === item?.id) ||
                            item?.QA?.some(
                              (Qitem) =>
                                Qitem?.answer === null &&
                                Qitem?.customerId === item?.id
                            )
                              ? "red"
                              : "#000",
                        }}
                      >
                        {item?.postedProjects}
                      </TableCell>
                      <TableCell>
                        <StarRatings
                          rating={
                            item?.Avg_Rating !== null ? item?.Avg_Rating : 0
                          }
                          starRatedColor="#FFD555"
                          numberOfStars={5}
                          name="rating"
                          starDimension="22px"
                          starSpacing="1px"
                          starEmptyColor="grey"
                        />
                      </TableCell>
                      <TableCell>
                        <span className="QnATextTemp">New</span>
                      </TableCell>
                      <TableCell>
                        <div className="passwordContainer">
                          <div className="passWordContainerTempcustomer">
                            {selectedUserPasswords[item.userId] ? (
                              <p
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={item?.password}
                                className="WrittenPassword"
                              >
                                {item?.password?.length > 5
                                  ? item?.password.substring(0, 5) + ".."
                                  : item?.password}
                                {item?.password?.length > 5 ? (
                                  <Tooltip
                                    id="my-tooltip"
                                    style={{
                                      backgroundColor: "#38ab7c",
                                      zIndex: 100,
                                    }}
                                  />
                                ) : null}
                              </p>
                            ) : (
                              <p className="WrittenPassword_star">*****</p>
                            )}
                          </div>
                          <div
                            onClick={() => toggleShowPassword(item.userId)}
                            className="eyeButton w-4 ml-3"
                          >
                            <img
                              className="hide_show"
                              src={
                                selectedUserPasswords[item.userId] ? Eye : Eye1
                              }
                              alt="Eye"
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <img
                          onClick={() => DeleteTempUser(item)}
                          style={{
                            marginLeft: "0.5rem",
                            cursor: "pointer",
                          }}
                          src={ThreeDot}
                          alt="More"
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <SearchCusAccountDeleteDialog
        SelectedBussinessEmail={SelectedBussinessEmail}
        deleteData={deleteData}
        handleClose={handleClose}
        setDeleteData={setDeleteData}
      />
      {PaginationDataLength > Constants.TAKE ? (
        <Footer
          page={page}
          handleChange={handleChange}
          totalRecord={PaginationDataLength}
          startedRecords={startedRecords}
          toRecords={toRecords}
          totalPages={totalPages}
          perPage={perPage}
        />
      ) : null}
      <Dialog onClose={() => setPasswordDialog(false)} open={passwordDialog}>
        <div className="ChangePassDialog">
          <span style={{ fontFamily: "sans-serif" }}>Enter password</span>
          <input
            onChange={(e) => setInteredPass(e.target.value)}
            type="text"
            placeholder="Enter New password"
          />
          {passwordCheck === true ? (
            <h6 className="password_validate">
              Password must be min 8 character and max 20 character “Please use
              lower and upper case letters, numbers and special characters such
              as: #, $, &, ect.” for strong password
            </h6>
          ) : null}
          <div className="change_pass_btns_customer">
            {enteredPass?.length >= 8 ? (
              <div
                className="btn"
                onClick={SubmitChange}
                style={{ fontFamily: "sans-serif", fontSize: 15 }}
              >
                Change
              </div>
            ) : (
              <div
                className="btn_disable"
                // onClick={SubmitChange}
                style={{ fontFamily: "sans-serif", fontSize: 15 }}
              >
                Change
              </div>
            )}

            <div className="CloseBTN" onClick={() => setPasswordDialog(false)}>
              <span style={{ fontFamily: "sans-serif", fontSize: 15 }}>
                Cancel
              </span>
            </div>
          </div>
        </div>
      </Dialog>
    </Container>
  );
}
export default TempCustomerAccount;
const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      height: "28px",
      padding: "0.2rem 1rem",
    },
  },
  bodyroot: {
    "&:hover": {
      backgroundColor: "#c5d9cf !important",
    },
    "& .MuiTableCell-body": {
      height: "28px",
      padding: "0.2rem 1rem",
    },
  },
});
