import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Container from "../HOC/Container";
import ScrollToBottom from "react-scroll-to-bottom";
import Arrow from "../../assets/icons/arrow-left.svg";
import verified from "../../assets/icons/verified.svg";
import Pin from "../../assets/icons/Map-pin.svg";
import Tag from "../../assets/icons/Tag.svg";
import File from "../../assets/icons/File.svg";
import Home from "../../assets/icons/home.svg";
import Call from "../../assets/icons/Call.svg";
import mail from "../../assets/icons/mail.svg";
import avatar_img from "../../assets/icons/avatar.svg";
import Location from "../../assets/icons/Map-pin.svg";
import "./TabBar.scss";
import "../../pages/Admin/UserProfile/UserProfile.scss";
import { useNavigate } from "react-router-dom";
import StarRatings from "react-star-ratings";

import { useDispatch, useSelector } from "react-redux";
import {
  AskQuestionAsync,
  deleteProjectAsync,
  getAllQnAAsync,
} from "../../redux/projectSlice";
import Loader from "../Loader";
import { UpdatemessageStatusAsync } from "../../redux/usersSlice";
import Slider from "react-slick";
import { useLocation } from "react-router-dom";
import moment from "moment";
const ProjectTabbar = () => {
  const { state, isProject } = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = useState("1");
  const [fileOpen, setFileOpen] = useState(false);
  // for QnA
  const [answer, setAnswer] = useState("");
  const [question, setQuestion] = useState("");
  const [visible, setVisible] = useState(false);
  const [selectedQId, setSelectedQId] = useState("");
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const {
    Selected_Project,
    deleteProjectLoading,
    GetQnALoading,
    QnAData,
    postAnsLoading,
    isProjectQnA,
  } = useSelector((state) => state.project);
  const { userDetails } = useSelector((state) => state.users);
  const loggesInUser = JSON.parse(sessionStorage.getItem("userLoggedDetails"));

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(UpdatemessageStatusAsync(selectedQId));
  }, [value == 2]);
  const SelectedProject = state ? state : Selected_Project;
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setFileOpen(false);
    if (newValue == "2" && !isProjectQnA) {
      dispatch(getAllQnAAsync(Selected_Project?.id, 20, 1));
    } else {
      dispatch(getAllQnAAsync(Selected_Project?.id, 20, 1, loggesInUser?.id));
    }
  };
  useEffect(() => {
    setFileOpen(false);
  }, []);

  const checkForNullAnswer = () => {
    if (!GetQnALoading) {
      return QnAData?.some((item) => item.answer === null);
    } else {
      return false;
    }
  };
  const hasNullAnswer = checkForNullAnswer();
  useEffect(() => {
    checkForNullAnswer();
  }, []);
  const navigateforEdit = () => {
    navigate("/AddProject");
  };
  const deleteProject = () => {
    dispatch(deleteProjectAsync(Selected_Project?.id)).then((resp) => {
      if (resp?.data?.success === true) {
        navigate(-1);
      }
    });
  };

  const CustomerReply = (item) => {
    setSelectedQId(item?.id);
    setSelectedProjectId(item?.projectId);
    setVisible(true);
    setAnswer("");
  };

  const handleAnsChange = (e) => {
    setAnswer(e.target.value);
  };
  const handleQuestionChange = (e) => {
    setQuestion(e.target.value);
  };
  console.log(
    "SelectedProject((((((((((((((((((((()))))))))))))))))))))))))))))",
    SelectedProject
  );
  const Submit = () => {
    const data = {
      projectId: selectedProjectId,
      questionId: selectedQId,
      customerId: Selected_Project?.customerId,
      answer: answer,
      accountType: "customer",
    };
    dispatch(AskQuestionAsync(data)).then((resp) => {
      if (resp?.status === 200 || 201) {
        setVisible(false);
        setAnswer(null);
        setSelectedQId(null);
        dispatch(getAllQnAAsync(Selected_Project?.id, 20, 1));
      }
    });
  };
  const watermerk_image =
    "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Image_not_available.png/640px-Image_not_available.png";
  const handleLinkClick = (url) => {
    window.open("https://" + url, "_blank", "noopener,noreferrer");
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  const AskQuestionToCustomer = () => {
    const data = {
      projectId: Selected_Project?.id,
      adminId: loggesInUser?.id,
      question: question,
      customerId: Selected_Project?.customerId,
    };
    dispatch(AskQuestionAsync(data)).then((resp) => {
      if (resp?.status === 200 || 201) {
        setQuestion("");
        setSelectedQId(null);
        dispatch(getAllQnAAsync(Selected_Project?.id, 20, 1, loggesInUser?.id));
      }
    });
  };
  return (
    <Container>
      {postAnsLoading || GetQnALoading || deleteProjectLoading ? (
        <Loader />
      ) : null}

      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box
            sx={{
              marginLeft: "1rem",
            }}
          >
            <div onClick={() => navigate(-1)} className="HeadingAndBack">
              <button>
                <img src={Arrow} alt="Arrow" className="h-6" />
              </button>
              <h1>Back</h1>
            </div>
            {state ? (
              <TabList onChange={handleChange}>
                <Tab
                  sx={{
                    "&.Mui-selected": {
                      outline: "none",
                      color: "#1C8C5E",
                      borderColor: "#1C8C5E",
                    },
                  }}
                  label="Customer Details"
                  value="1"
                />
                <Tab
                  sx={{
                    color: hasNullAnswer ? "red" : "#1C8C5E",
                    "&.Mui-selected": {
                      outline: "none",
                      color: hasNullAnswer ? "red" : "#1C8C5E",
                      borderColor: "#1C8C5E",
                    },
                  }}
                  label="Project Detail"
                  value="2"
                />
              </TabList>
            ) : (
              <TabList onChange={handleChange}>
                <Tab
                  sx={{
                    "&.Mui-selected": {
                      outline: "none",
                      color: "#1C8C5E",
                      borderColor: "#1C8C5E",
                    },
                  }}
                  label="Projet Details"
                  value="1"
                />
                {userDetails?.accountType !== "demo" ||
                userDetails?.accountType !== "temmpAccount" ? (
                  <Tab
                    sx={{
                      color: hasNullAnswer ? "red" : "#1C8C5E",
                      "&.Mui-selected": {
                        outline: "none",
                        color: hasNullAnswer ? "red" : "#1C8C5E",
                        borderColor: "#1C8C5E",
                      },
                    }}
                    label="Q&A"
                    value="2"
                  />
                ) : null}
              </TabList>
            )}
          </Box>
          <TabPanel value={state ? "2" : "1"}>
            <div className="DetialMainContainer">
              <div className="Card">
                <h3>{SelectedProject?.projectTitle}</h3>
                <div className="LocationContainer">
                  <div className="icon_Text">
                    <img
                      style={{ width: "15px", height: "15px" }}
                      src={Pin}
                      alt="Info"
                    />
                    {/* <span>{SelectedProject?.location}</span> */}
                    <div className="Location_continer">
                      Location :
                      {SelectedProject?.location?.map((loc, index) => (
                        <h6 className="locations" key={index}>
                          {loc}
                          {index !== SelectedProject?.location?.length-1 && ","}
                        </h6>
                      ))}
                      {/* {SelectedProject?.location?.length > 2 && (
                        <h6 className="locations">
                          + {SelectedProject?.location?.length - 2} more
                          location
                        </h6>
                      )} */}
                    </div>
                  </div>
                  <span className="ProjectId">
                    {" "}
                    {SelectedProject?.project_Id}
                  </span>
                  <div className="CategoryContainer">
                    <img
                      style={{ width: "15px", height: "15px" }}
                      src={Tag}
                      alt="Info"
                    />
                    <span>
                      {SelectedProject?.category?.name} -{" "}
                      {SelectedProject?.subCategoryTbl?.name}
                    </span>
                  </div>
                </div>
                <h4 className="Job_Dis_Title">Job Description</h4>
                <p className="Job_discription">
                  {SelectedProject?.jobDescription}
                </p>
                <div
                  onClick={() => setFileOpen(!fileOpen)}
                  className="Files_Icon"
                >
                  <span>Documents</span>
                  <img src={File} alt="file" />
                </div>
                <div className="Images_demo_container">
                  <Slider {...settings}>
                    {SelectedProject?.projectDocumentTbl?.showfilePath.map(
                      (file, i) => {
                        const fileExtension = file
                          .split(".")
                          .pop()
                          .toLowerCase();

                        const renderContent = () => {
                          switch (fileExtension) {
                            case "jpg":
                            case "jpeg":
                            case "png":
                            case "gif":
                              // Render Image
                              return (
                                <div onClick={() => handleLinkClick(file)}>
                                  <div className="Image_scroll_container">
                                    <img
                                      src={"https://" + file}
                                      alt="Document"
                                      className="Image_scroll"
                                    />
                                  </div>
                                  <h3>Document {i + 1}</h3>
                                </div>
                              );
                            case "pdf":
                              // Render PDF Download UI
                              return (
                                <div onClick={() => handleLinkClick(file)}>
                                  <div className="Image_scroll_container">
                                    <a href={"https://" + file} download>
                                      <button className="Download_button">
                                        Download PDF
                                      </button>
                                    </a>
                                  </div>
                                  <h3>Document {i + 1}</h3>
                                </div>
                              );
                            case "mp4":
                            case "mov":
                            case "avi":
                              // Render Video
                              return (
                                <div onClick={() => handleLinkClick(file)}>
                                  <div className="Image_scroll_container">
                                    <video controls className="Video_scroll">
                                      <source
                                        src={"https://" + file}
                                        type={`video/${fileExtension}`}
                                      />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  </div>
                                  <h3>Document {i + 1}</h3>
                                </div>
                              );
                            default:
                              return (
                                <div className="Image_demo">
                                  <div className="Image_scroll_container">
                                    <img
                                      src={watermerk_image}
                                      alt="Document"
                                      className="Image_scroll"
                                    />
                                  </div>
                                  <h3>NO documents uploaded</h3>
                                </div>
                              );
                          }
                        };

                        return (
                          <div key={i} className="Image_demo">
                            {file !== "null" ? (
                              renderContent()
                            ) : (
                              <div className="Image_demo">
                                <div className="Image_scroll_container">
                                  <img
                                    src={watermerk_image}
                                    alt="Document"
                                    className="Image_scroll"
                                  />
                                </div>
                                <h3>NO documents uploaded</h3>
                              </div>
                            )}
                          </div>
                        );
                      }
                    )}
                  </Slider>
                </div>
                {SelectedProject?.tempAccount === true ? (
                  <div className="Activity_Container">
                    <p onClick={navigateforEdit} className="Edit_BTN">
                      Edit
                    </p>
                    <p onClick={deleteProject} className="Delete_BTN">
                      Delete
                    </p>
                  </div>
                ) : null}
              </div>
            </div>
          </TabPanel>
          <TabPanel value={!state ? "2" : null}>
            {isProjectQnA ? (
              <ScrollToBottom className="QnAContainer">
                <div className="qna-container">
                  {QnAData?.length > 0 && (
                    <p variant="h5" align="center">
                      Frequently Asked Questions:
                    </p>
                  )}
                  {QnAData?.length > 0 ? (
                    QnAData?.map((data, index) => (
                      <div key={index} className="QnAcard">
                        <div>
                          <p className="Questions">Q: {data?.question}</p>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "5px",
                              marginLeft: "0.5rem",
                            }}
                          >
                            <img
                              src={
                                data?.adminuser?.image?.includes(".jpg") ||
                                data?.adminuser?.image?.includes(".jpeg") ||
                                data?.adminuser?.image?.includes(".png")
                                  ? "https://" + data?.adminuser?.image
                                  : "https://png.pngtree.com/png-clipart/20210915/ourlarge/pngtree-avatar-placeholder-abstract-white-blue-green-png-image_3918476.jpg"
                              }
                              alt="Avatar"
                              style={{
                                width: 30,
                                height: 30,
                                borderRadius: "50%",
                                border: "1px solid grey",
                              }}
                            />
                            <p className="Asked_by">
                              Asked by {data?.adminuser?.firstName}{" "}
                              {data?.adminuser?.lastName} on{" "}
                              <span>
                                {moment
                                  .unix(data.createdAt)
                                  .format("MMMM DD, YYYY")}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div>
                          {data?.answer ? (
                            <p className="PlaceholerText">
                              A:{" "}
                              {data.answer.length > 50
                                ? `${data.answer.substring(0, 50)}...`
                                : data.answer}
                            </p>
                          ) : (
                            <p className="PlaceholerText">
                              Waiting for response...
                            </p>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <>
                      <p
                        variant="body1"
                        align="center"
                        color="textSecondary"
                        style={{ marginTop: "20px" }}
                      >
                        No Q&A available for this project at the moment.
                      </p>
                    </>
                  )}

                  <div className="dialog">
                    <p className="PlaceholerText">Ask New question.. </p>
                    <textarea
                      className="textarea"
                      value={question}
                      onChange={handleQuestionChange}
                      placeholder={"Ask your query..."}
                    />
                    <button
                      className="post-button"
                      onClick={
                        question?.trim() !== ""
                          ? AskQuestionToCustomer
                          : () => window.alert("Write something..")
                      }
                    >
                      Post
                    </button>
                  </div>
                </div>
              </ScrollToBottom>
            ) : (
              <ScrollToBottom className="Chat_container">
                <div className="qna-container">
                  <h2>Frequently Asked Questions:</h2>
                  {QnAData?.map((item, index) => (
                    <div key={index} className="qna-item">
                      <div className="question-container">
                        <p className="question">Q : {item.question}</p>
                        {item?.answer !== null ? (
                          <div className="answer-container">
                            <p className="answer">A : {item?.answer}</p>
                          </div>
                        ) : (
                          <div className="waiting-response">
                            {!visible || selectedQId !== item.id ? (
                              <button
                                className="reply-button"
                                onClick={() => CustomerReply(item)}
                              >
                                Answer the Question
                              </button>
                            ) : null}
                          </div>
                        )}
                      </div>
                      {visible && item?.id === selectedQId && (
                        <div className="dialog">
                          <p>Submit your response</p>
                          <textarea
                            className="textarea"
                            value={answer}
                            onChange={handleAnsChange}
                            placeholder={"Reply to this question..."}
                          />
                          <button
                            className="close-button"
                            onClick={() => {
                              setVisible(false);
                              setSelectedQId(null);
                            }}
                          >
                            Close
                          </button>
                          <button
                            className="post-button"
                            onClick={
                              answer?.trim() !== ""
                                ? Submit
                                : () => window.alert("Write something..")
                            }
                          >
                            Post
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </ScrollToBottom>
            )}
          </TabPanel>
          {state && (
            <TabPanel value="1">
              <div className="UserDetail_container">
                <div className="UserProfile_Deatils">
                  <div className="Avtar_Conatiner">
                    <div className="avatar">
                      <img
                        style={{
                          height: "5rem",
                          width: "5rem",
                          borderRadius: "80%",
                          border: "1px solid grey",
                        }}
                        src={
                          userDetails?.image
                            ? `https://${state?.customer?.image}`
                            : avatar_img
                        }
                        alt="user"
                      />
                      <img
                        className="Verified"
                        src={verified}
                        alt="Varified User"
                      />
                    </div>
                  </div>
                  <div className="Addresss_container">
                    <div className="Address">
                      <div className="BusinessAddress">
                        <img src={Home} alt="Home" />
                        <div className="business_address">
                          <h5>Customer Address</h5>
                          <h2>{state?.customer?.streetAddress}</h2>
                        </div>
                        {state?.customer?.tempAccount === true ? (
                          <div className="IsVerified">
                            <span>Verified</span>
                          </div>
                        ) : (
                          <div className="IsVerified">
                            {state?.customer?.isVerified === true ? (
                              <span>Verified</span>
                            ) : (
                              <span>Not Verified</span>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="BusinessAddress">
                        <img src={Call} alt="Home" />
                        <div className="business_address">
                          <h5>Contact No.</h5>
                          <h2>{state?.customer.phoneNumber}</h2>
                        </div>
                        {state?.customer?.tempAccount === true ? (
                          <div className="IsVerified">
                            <span>Verified</span>
                          </div>
                        ) : (
                          <div className="IsVerified">
                            {state?.customer?.isVerified === true ? (
                              <span>Verified</span>
                            ) : (
                              <span>Not Verified</span>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="BusinessAddress">
                        <img src={mail} alt="Home" />
                        <div className="business_address">
                          <h5>Email Address</h5>
                          <h2>{state?.customer?.emailAddress}</h2>
                        </div>
                        {state?.customer?.tempAccount === true ? (
                          <div className="IsVerified">
                            <span>Verified</span>
                          </div>
                        ) : (
                          <div className="IsVerified">
                            {state?.customer?.isVerified === true ? (
                              <span>Verified</span>
                            ) : (
                              <span>Not Verified</span>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <h1 className="Discription_title">Customer Discription</h1>
                  <h4>
                    I am offering to my client an attractive screenshot that can
                    be used for the google play sote. App store window store and
                    on your website. The screenshot helps to increase tyour
                    amketing in between people.
                    <br /> I can also design a cataog of your softwate by usein
                    ghe same screensh
                  </h4>
                </div>
                <div className="Full_Detail">
                  <div className="Personal_Detail">
                    <div className="NameContainer">
                      <h1>
                        {state?.customer?.fullName
                          ? state?.customer?.fullName
                          : "Temp User"}{" "}
                      </h1>
                      <div className="LocationContainer">
                        <img src={Location} />
                        <p>{state?.customer?.location}</p>
                      </div>
                    </div>
                    {state?.customer?.accountType !== "customer" && (
                      <div className="RoleContainer">
                        <span>Role :</span>{" "}
                        <p className="UserRole">{state?.customer?.Position}</p>
                      </div>
                    )}
                    <div className="Rating_Container">
                      <h4>Rating</h4>
                      <StarRatings
                        rating={
                          state?.customer?.Avg_Rating !== null
                            ? state?.customer?.Avg_Rating
                            : 0
                        }
                        // rating={4}
                        starRatedColor="#38ab7c"
                        numberOfStars={5}
                        name="rating"
                        starDimension="20px"
                        starSpacing="1px"
                        starEmptyColor="grey"
                      />
                    </div>
                    <div className="RoleContainer">
                      <span>Total Jobs Completed :</span>{" "}
                      <p className="UserRole">
                        {state?.customer?.No_Of_Completed_Job}
                      </p>
                    </div>
                  </div>
                  <div className="About_Container">
                    <div className="About">
                      <h5>ABOUT</h5>
                    </div>
                    <p className="Header">Contact Information</p>
                    <div className="Common_Details">
                      <h6 className="Common_headers">Phone :</h6>
                      <p className="Common_Data">
                        {state?.customer?.phoneNumber}
                      </p>
                    </div>
                    <div className="Common_Details">
                      <h6 className="Common_headers">Address :</h6>
                      <p className="Common_Data">
                        {state?.customer?.streetAddress} ,{" "}
                        {state?.customer?.city} , {state?.customer?.state}
                      </p>
                    </div>
                    <div className="Common_Details">
                      <h6 className="Common_headers">Email :</h6>
                      <p className="Common_Data">
                        {state?.customer?.emailAddress}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
          )}
        </TabContext>
      </Box>
    </Container>
  );
};

export default ProjectTabbar;
