import React, { useEffect } from "react";
import "./Csv.scss";
import importIcon from "../../assets/icons/import.svg";
import RightIcon from "../../assets/icons/RightIcon.svg";
import cross from "../../assets/icons/cross-blue.svg";
import downloadsvg from "../../assets/icons/downloadsvg.svg";
import TermNCondition from "../../assets/icons/TermNCondition.svg";
import { useState } from "react";
import { useRef } from "react";
import EditIcon from "../../assets/icons/EditIcon.svg";
import Swal from "sweetalert2";
import roundPlus from "../../assets/icons/round-plus-white.svg";
import { Select } from "antd";
import Papa from "papaparse";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import { Steps } from "antd";
import * as XLSX from "xlsx";
import { Progress } from "antd";
import {
  InfoCircleOutlined,
  FileProtectOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import {
  ClearsignUpFailedFailedUsers,
  signUpAsync,
  signUpAsyncWithCSV,
} from "../../redux/adminManagementSlice";
import { UploadingProgress } from "../../redux/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { createTempAccounts } from "../../redux/usersSlice";
import Loader from "../Loader";
import axios from "axios";
const CsvUploaderModal = () => {
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState("");
  const [progressBar, setProgressBar] = useState(0);
  const [uploaded, setUploaded] = useState(false);
  const [openInvalidData, setOpenInvalidData] = useState(false);
  const [showStep, setShowStep] = useState(true);
  const [loading, setLoading] = useState(false);
  //---------CSV file uploader ----------------
  const [parsedData, setParsedData] = useState([]);
  //State to store table Column name
  const [tableRows, setTableRows] = useState([]);
  //State to store the values
  const [values, setValues] = useState([]);
  const [failedData, setFailedData] = useState([]);
  //--------------------------------------------
  const { CSVVerificationAccountType, userDetails, uploadingTracker } =
    useSelector((state) => state.auth);
  const { signUpWithCSVLoading, signUpFailedUsersData } = useSelector(
    (state) => state.adminManage
  );
  const {
    selectedAccountType,
    createBusinessUserLoading,
    createTempBusinessAccountData,
    createTempCustomerAccountData,
  } = useSelector((state) => state.users);
  const [iPAddress, setIPAddress] = useState("");
  const getData = async () => {
    try {
      const res = await axios.get("https://geolocation-db.com/json/");
      console.log(res);
      setIPAddress(res.data.IPv4);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (signUpFailedUsersData?.length > 0) {
      setFailedData([signUpFailedUsersData]);
      setOpenInvalidData(true);
    }
  }, [signUpFailedUsersData]);

  console.log("signUpFailedUsersData", signUpFailedUsersData);
  const steps = [
    { title: "Step 1", content: "Upload CSV", icon: <FileTextOutlined /> },
    {
      title: "Step 2",
      content: "Validate Data",
      icon: <FileProtectOutlined />,
    },
    { title: "Step 3", content: "Import Data", icon: <InfoCircleOutlined /> },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
    icon: item.icon,
  }));

  //File uploading functionality ---------------------------------
  const handleFileUpload = (file) => {
    const fileType = file.type;
    setFileName(file?.name);
    if (fileType === "text/csv") {
      // CSV file
      const reader = new FileReader();
      reader.onload = (e) => {
        const csvText = e.target.result;
        Papa.parse(csvText, {
          header: true,
          skipEmptyLines: true,
          complete: (results) => {
            processFileData(results?.data);
          },
          error: () => {
            showError(
              "Failed to parse CSV file. Please check the file format."
            );
          },
        });
      };
      reader.readAsText(file);
    } else if (
      fileType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      fileType === "application/vnd.ms-excel"
    ) {
      // Excel file
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // Convert array of arrays into structured JSON
        const headers = jsonData[0];
        const rows = jsonData
          .slice(1)
          .map((row) =>
            Object.fromEntries(
              row.map((value, index) => [headers[index], value])
            )
          );

        processFileData(rows);
      };
      reader.readAsArrayBuffer(file);
    } else {
      showError("Invalid File Type. Please upload a valid CSV or Excel file.");
    }
  };

  const processFileData = (data) => {
    if (!data || data.length === 0) {
      showError("No valid data found in the file.");
      return;
    }

    const rowsArray = Object.keys(data[0]);
    const valuesArray = data.map((d) => Object.values(d));

    setParsedData(data);
    setTableRows(rowsArray);
    setValues(valuesArray);
    setUploaded(true);
    setProgressBar(1);
    // setLoading(false);

    Swal.fire({
      icon: "success",
      title: "File Uploaded Successfully",
      text: "You will be redirected to the data mapping screen...",
    });
  };

  const showError = (message) => {
    setLoading(false);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: message,
    });
  };

  const handleDrop = (e) => {
    e.preventDefault();
    handleFileUpload(e.dataTransfer.files[0]);
  };

  const handleFileChange = (e) => {
    handleFileUpload(e.target.files[0]);
  };

  const handleCreateAdmins = async () => {
    if (values?.length < 0) {
      Swal.fire({
        icon: "error",
        title: "Please select a CSV file",
      });
      return;
    }
    setProgressBar(2);
    setUploaded(true);
    // setOpenInvalidData(true);
  };

      const emailRegex = /^[a-zA-Z0-9._%+-]+@(gmail|outlook|yahoo|icloud|hotmail|aol|protonmail|zoho|gmx|yandex|mail|live|msn|comcast)\.(com|net|org|co|edu|gov|mil)$/i;


  const { ValidData, InvalidData } = parsedData?.reduce(
    (acc, item) => {
      const email = item.email ? item.email.trim() : "";
      if (!email || !emailRegex.test(email)) {
        acc.InvalidData.push(item);
      } else {
        acc.ValidData.push(item);
      }
      return acc;
    },
    { ValidData: [], InvalidData: [] }
  );

  const handleImportIntoDB = async () => {
    console.log("iPAddress", iPAddress);
    dispatch(UploadingProgress(1));
    setProgressBar(3);
    setLoading(true);
    try {
      if (CSVVerificationAccountType !== "masteradmin") {
        let type =
          selectedAccountType === "CUSTOMER_USER" ? "customer" : "business";
        await dispatch(
          signUpAsyncWithCSV(ValidData, userDetails?.id, type)
        ).then((res) => {
          if (
            res?.data?.status === "failed" ||
            (res?.status === 400 && res?.data?.status === "failed") ||
            res?.data?.lengthOfIncompletedUsers === ValidData?.length
          ) {
            Swal.fire({
              icon: "error",
              title: res?.data?.message,
            });
            setProgressBar(2);
          } else if (res?.data?.lengthOfIncompletedUsers > 0) {
            console.log("res>>>>>>> case1 called", res);
            Swal.fire({
              icon: "success",
              title: res?.data?.message,
            });
            // setShowStep(false);
            // navigate(-1);
            setProgressBar(2);
            dispatch(UploadingProgress(0));
          } else {
            console.log("res>>>>>>> case2 called", res);
            Swal.fire({
              icon:
                res?.data?.message === "Invalid file format"
                  ? "error"
                  : "success",
              title: res?.data?.message,
            });
            // setShowStep(false);
            // setProgressBar(2);
            dispatch(UploadingProgress(0));
            navigate(-1);
          }
        });
      } else {
        const updatedValidData = ValidData.map((item) => ({
          ...item,
          iPAddress,
        }));
        await dispatch(
          signUpAsyncWithCSV(updatedValidData, userDetails?.id, "admin")
        ).then((res) => {
          if (
            res?.data?.status === "failed" ||
            (res?.status === 400 && res?.data?.status === "failed") ||
            res?.data?.lengthOfIncompletedUsers === ValidData?.length
          ) {
            Swal.fire({
              icon: "error",
              title: res?.data?.message,
            });
            setProgressBar(2);
          } else if (res?.data?.lengthOfIncompletedUsers > 0) {
            console.log("res>>>>>>> case1 called", res);
            Swal.fire({
              icon: "success",
              title: res?.data?.message,
            });
            // setShowStep(false);
            // navigate(-1);
            setProgressBar(2);
            dispatch(UploadingProgress(0));
          } else {
            console.log("res>>>>>>> case2 called", res);
            Swal.fire({
              icon: res?.data?.message==="Invalid file format"?"error":"success",
              title: res?.data?.message,
            });
            // setShowStep(false);
            // setProgressBar(2);
            dispatch(UploadingProgress(0));
            navigate(-1);
          }
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to import data. Please try again.",
      });
    } finally {
      setLoading(false);
      dispatch(UploadingProgress(0));
    }
  };
  const ResetFile = () => {
    dispatch(ClearsignUpFailedFailedUsers());
    setValues();
    setTableRows([]);
    setParsedData([]);
    setOpenInvalidData(false);
    setProgressBar(0);
  };
  const CancelProcess = () => {
    navigate(-1);
    dispatch(ClearsignUpFailedFailedUsers());
  };
  return (
    <>
      {createBusinessUserLoading || signUpWithCSVLoading ? <Loader /> : null}
      {loading && progressBar === 3 && uploadingTracker > 0 ? (
        <div className="LoadingContainer">
          <h2>Uploading please wait ..</h2>
          <Progress percent={uploadingTracker} status="active" />
        </div>
      ) : (
        <div className="MainConatiner">
          <div className="Main_Header">
            <div className="Import_Icon_Container">
              <img src={importIcon} alt="Import" />
              <h2>Import Data</h2>
            </div>
            <div className="ContinueContainer">
              {progressBar === 1 ? (
                <div onClick={handleCreateAdmins} className="ContainerButton">
                  <img src={RightIcon} alt="" />
                  <p>Continue</p>
                </div>
              ) : (
                !signUpFailedUsersData?.length > 0 &&
                values?.length > 0 &&
                showStep && (
                  <div onClick={handleImportIntoDB} className="ContainerButton">
                    <img src={RightIcon} alt="" />
                    <p>Import</p>
                  </div>
                )
              )}
              <img
                onClick={CancelProcess}
                className="ml-2 cursor-pointer"
                src={cross}
                alt="Cancel"
              />
            </div>
          </div>
          {showStep && (
            <div className="SteppBarContainer">
              <Steps current={progressBar} items={items} />
            </div>
          )}

          {/* ---------------------------------Step0 [First]------------------------ */}
          <div className="CSV_Container">
            {progressBar === 0 && (
              <>
                <div className="Import_heading_and_Download">
                  <h1>User Import Options</h1>
                  <div className="DownloadFile">
                    <img src={downloadsvg} alt="import" />
                    <p>Download Sample File</p>
                  </div>
                </div>
                <div
                  className="ImportFile_container"
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={handleDrop}
                >
                  <img src={importIcon} alt="import" />
                  <h3>Drag & Drop your file(s) here to upload</h3>
                  <h5>
                    or
                    <input
                      type="file"
                      multiple
                      onChange={handleFileChange}
                      hidden
                      ref={inputRef}
                    />
                    <button onClick={() => inputRef.current?.click()}>
                      select file
                    </button>
                  </h5>
                </div>
                <div className="ConditionContainer">
                  <div className="Icon_FileFormat">
                    <img src={TermNCondition} alt="" />
                    <p>File format should be CSV</p>
                  </div>
                  <p>Maximum limit: 150,000 records</p>
                </div>
              </>
            )}
            {/* ------------------Step1[Second]----------------- */}
            <>
              {progressBar === 1 && values?.length > 0 && (
                <>
                  <div className="SelectedFile_container">
                    <h6>Selected File</h6>
                    <div className="FileType_container">
                      <div className="CSV_Type">
                        <p>CSV</p>
                        <CSVLink
                          data={parsedData || []}
                          filename={"my-file.csv"}
                          target="_blank"
                        >
                          <span>{fileName}</span>
                        </CSVLink>
                      </div>

                      <>
                        <input
                          type="file"
                          multiple
                          onChange={handleFileChange}
                          hidden
                          ref={inputRef}
                        />
                        <button
                          style={{ outline: "none" }}
                          onClick={() => inputRef.current?.click()}
                        >
                          <img
                            src={EditIcon}
                            alt="Edit"
                            style={{ cursor: "pointer" }}
                          />
                        </button>
                      </>
                    </div>
                  </div>

                  {tableRows?.length > 0 && (
                    <div>
                      <div className="Fields_Mapping_Container">
                        <h1>Fields Mapping</h1>
                      </div>
                      <div className="Mapping_data_Heading">
                        <h2>Fields in require file</h2>
                        <h2>Map to your data fields</h2>
                      </div>

                      {tableRows.map((rows, index) => {
                        return (
                          <div className="Mapping_Datas">
                            <h2>{rows}</h2>
                            <Select
                              defaultValue={rows}
                              style={{ width: "50%" }}
                              disabled
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                </>
              )}
            </>
            {/* -------------------------------------Step2[Third]-------------------------------------------- */}
            {!openInvalidData && progressBar === 2 && (
              <div className="inValid_Data_Container">
                <div className="inValid_Data">
                  <h1>Imported Data</h1>
                  <div className="Mapping_Datas_Header">
                    <h2>Record Type</h2>
                    <h2>Total Records</h2>
                  </div>
                  <div className="Mapping_Datas">
                    <p>Total Records</p>
                    <p>{values?.length}</p>
                  </div>
                  <div className="Mapping_Datas">
                    <p>Valid Records</p>
                    <p>{ValidData?.length}</p>
                  </div>
                  <div className="Mapping_Datas">
                    <p>Invalid Records</p>
                    <p>
                      {InvalidData?.length}
                      {InvalidData?.length > 0 && (
                        <span
                          onClick={() => setOpenInvalidData(true)}
                          className="letsReview"
                        >
                          Let's Review
                        </span>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            )}

            {/* // If invalid data found */}
            {openInvalidData && progressBar === 2 && (
              <div className="Duplicate_Data_Container">
                <p>
                  {signUpFailedUsersData?.length > 0
                    ? "This data already exists! Update it and upload again."
                    : `We found ${InvalidData?.length} invalid records. The system will reject these. Please review and update by uploading a new CSV.`}

                  <span
                    style={{
                      color: "red",
                      fontSize: "0.9rem",
                      fontWeight: "500",
                      paddingLeft: "0.5rem",
                      cursor: "pointer",
                    }}
                    onClick={ResetFile}
                  >
                    Re-upload
                  </span>
                </p>
                <table style={{ width: "100%", marginTop: "1%" }}>
                  <thead
                    style={{
                      height: 50,
                      paddingLeft: 10,
                      backgroundColor: "#F3F6F9",
                    }}
                  >
                    <tr>
                      {tableRows?.map((item) => (
                        <th>{item}</th>
                      ))}
                    </tr>
                  </thead>

                  <tbody>
                    {(signUpFailedUsersData || InvalidData)?.map((item, i) =>
                      selectedAccountType === "CUSTOMER_USER" ? (
                        <tr key={i}>
                          <td>{item.firstName}</td>
                          <td>{item.lastName}</td>
                          <td className="duplicate_email">{item.email}</td>
                          <td>{item.phoneNumber}</td>
                          <td>{item.location}</td>
                          <td>{item.city}</td>
                          <td>{item.state}</td>
                        </tr>
                      ) : (
                        <tr key={i}>
                          <td>{item.businessName?item.businessName:item.firstName}</td>
                          <td>{item.lastName?item.lastName:null}</td>
                          <td className="duplicate_email">{item.email}</td>
                          <td>{item.phoneNumber}</td>
                          <td>{item.category}</td>
                          <td>{item.subCategory}</td>
                          <td>{item.location}</td>
                          <td>{item.city}</td>
                          <td>{item.state}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CsvUploaderModal;
