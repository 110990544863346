import React, { useEffect, useState } from "react";
import "./SearchProBusinessAccount.scss";
import Vector from "../../../assets/icons/Vector.svg";
import NOdataFound from "../../../assets/icons/NoAdminData.svg";
import SearchIcon from "@mui/icons-material/Search";
import Container from "../../../components/HOC/Container";
import Group_345 from "../../../assets/icons/Group_345.svg";
import keyboard_arrow_down from "../../../assets/icons/keyboard_arrow_down.svg";
import calendar_month from "../../../assets/icons/calendar_month.svg";
import { useSelector, useDispatch } from "react-redux";
import { Constants } from "../../../utils/Constants";
import Loader from "../../../components/Loader";
import Footer from "../../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ThreeDot from "../../../assets/icons/threedot.svg";
import threedot_red from "../../../assets/icons/threedot_red.svg";

import {
  UpdateStatusAsync,
  getSearchBusinessAsync,
  // deleteBusinessAsync,
  selectedUserDetails,
} from "../../../redux/usersSlice";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import makeStyles from "@mui/styles/makeStyles";
import avatar from "../../../assets/icons/avatar.svg";
import { Chip } from "@mui/material";
import Switch from "react-switch";
import { SelectedRoute } from "../../../redux/adminManagementSlice";
function SearchProBusinessAccount() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const {
    proBusinessData,
    proBusinessLoading,
    PaginationDataLength,
    startedRecords,
    toRecords,
    totalPages,
    updateBusinessLoading,
  } = useSelector((state) => state.users);
  const [page, setPage] = useState(1);
  const [location, setLocation] = useState("");
  const [email, setEmail] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [address, setAddress] = useState("");
  const [approvalStatus, setApprovalStatus] = useState({});

  //----- Filters
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState("Business");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("Search");

  const handleSelection = (value) => {
    setFilter(value);
    setSelectedValue(value);
    setIsDropdownOpen(false); // Close the dropdown after selection
  };

  const handleClickOpen1 = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleChangeSelect = (event) => {
    setFilter(event.target.value || "");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseSelect = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };
  const data = {
    location,
    email,
    address,
    businessName,
    status: 1,
    page: 1,
    isAdminApproved: 1,
    demo: 0,
    perPage: Constants.TAKE,
  };
  const handleChange = (event, value) => {
    data.page = value;
    dispatch(getSearchBusinessAsync(data));
  };

  useEffect(() => {
    dispatch(getSearchBusinessAsync(data));
  }, [dispatch]);

  const resetStates = () => {
    setLocation("");
    setEmail("");
    setBusinessName("");
    setAddress("");
  };
  const SearchQuery = (e) => {
    resetStates();
    switch (filter) {
      case "Business":
        setBusinessName(e.target.value);
        break;
      case "Username":
        setBusinessName(e.target.value);
        break;
      case "Email":
        setEmail(e.target.value);
        break;
      case "Location":
        setLocation(e.target.value);
        break;
      case "Address":
        setAddress(e.target.value);
        break;
      default:
        break;
    }
  };

  const GoToChat = (item) => {
    dispatch(SelectedRoute("/Messages"));
    navigate("/Messages", {
      state: item,
    });
  };
  const getDataByQuery = () => {
    dispatch(getSearchBusinessAsync(data, Constants.TAKE, 0, 1));
  };
  const NavigateToBusinessUserProfile = (item) => {
    dispatch(selectedUserDetails(item));
    navigate("/UserProfile/UserProfile");
  };

  // No copy / paste functionality
  function handleCopy(event) {
    event.preventDefault();
    Swal.fire({
      icon: "error",
      text: "Copy / Paste are not allowed  ",
    });
  }

  function handlePaste(event) {
    event.preventDefault();
    Swal.fire({
      icon: "error",

      text: "Copy / Paste are not allowed  ",
    });
  }

  // Broken image functionality
  const FailedToLoad = (e) => {
    console.log("not opened");
    e.target.src = avatar;
  };

  // Next date
  const formatDate = (item) => {
    // Filter to get paymentTransactionDetails with paymentType "add_subscription"
    let subsDate = item?.paymentTransactionDetails?.filter(
      (data) => data?.paymentType === "add_subscription"
    );
    let nonDeletedSubsDate = subsDate?.filter((i) => !i?.isDeleted);
    return nonDeletedSubsDate?.[0]?.updatedAt || "non";
  };
  // Exp date
  const ExpDate = (item) => {
    let subsDate = item?.paymentTransactionDetails?.filter(
      (data) => data?.paymentType === "add_subscription"
    );
    let nonDeletedSubsDate = subsDate?.filter((i) => !i?.isDeleted);
    return nonDeletedSubsDate?.[0]?.updatedAt || "No data available";
  };
  // transId--
  const transId = (item) => {
    let subsDate = item?.paymentTransactionDetails?.filter(
      (data) => data?.paymentType === "add_subscription"
    );
    let nonDeletedSubsDate = subsDate?.filter((i) => !i?.isDeleted);
    return nonDeletedSubsDate?.[0]?.payment_Id || "No data available";
  };

  const Unapproved = (id) => {
    const newStatus = !approvalStatus[id];
    setApprovalStatus((prevStatus) => ({
      ...prevStatus,
      [id]: newStatus,
    }));
    const changeStatus = {
      accountType: "business",
      id: id,
      isAdminApproved: false,
      isReview: true,
      PrevVeried: true,
    };
    dispatch(UpdateStatusAsync(changeStatus));
  };
  return (
    <Container>
      {proBusinessLoading || updateBusinessLoading ? <Loader /> : null}
      <div
        onCopy={handleCopy}
        onPaste={handlePaste}
        className="containerForSearchProBussiness "
      >
        <div className="HeadingAndSearchSearchProBusinessAccount">
          <div className="HeaderText">
            <h1>Search results PRO business accounts</h1>
            <p>NO COPY AND PASTE AND NO PRINTING OF THIS SCREEN</p>
          </div>
          <div className="RightBarSearchProBusinessAccount">
            <div className="filtersProBusinessAccount">
              <div>
                <button
                  onClick={handleClickOpen1}
                  className="SearchSearchProBusinessAccount"
                >
                  {selectedValue}
                  <img
                    style={{ marginLeft: 5 }}
                    src={keyboard_arrow_down}
                    alt="Search"
                  />
                </button>

                {isDropdownOpen && (
                  <div className="dropdown-content">
                    <ul>
                      <li onClick={() => handleSelection("Username")}>
                        Username
                      </li>
                      <li onClick={() => handleSelection("Email")}>Email</li>
                      <li onClick={() => handleSelection("Location")}>
                        Location
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              {/* <Dialog
                disableEscapeKeyDown
                open={open}
                onClose={handleCloseSelect}
              >
                <DialogContent>
                  <Box
                    component="form"
                    sx={{ display: "flex", flexWrap: "wrap" }}
                  >
                    <FormControl sx={{ minWidth: 120 }}>
                      <Select
                        native
                        value={filter}
                        onChange={handleChangeSelect}
                        input={
                          <OutlinedInput
                            label="Filter by"
                            id="demo-dialog-native"
                          />
                        }
                      >
                        <option value="Username">Username</option>
                        <option value="Location">Location</option>
                        <option value="Email">Email </option>
                      </Select>
                    </FormControl>
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseSelect}>Cancel</Button>
                  <Button onClick={handleCloseSelect}>Ok</Button>
                </DialogActions>
              </Dialog> */}
              <div className="inputDivSearchProBusinessAccount">
                <SearchIcon
                  onClick={getDataByQuery}
                  className="searchIconSearchProBusinessAccount"
                  style={{ color: "#777", fontSize: 22 }}
                />
                <input
                  onChange={SearchQuery}
                  onKeyDown={(event) => {
                    event.key === "Enter" && getDataByQuery();
                  }}
                  className="input-fieldSearchProBusinessAccount"
                  type="text"
                  placeholder={`Search by ${filter}`}
                />
              </div>
            </div>
          </div>
        </div>
        {proBusinessData?.length === 0 && !proBusinessLoading ? (
          <div className="NoAdminDataForProBussiness">
            <img src={NOdataFound} alt="NO DATA FOUND" />
            <div className="NoAdminDataForProBussiness_Titles">
              <h1>No Business Users Available</h1>
              <h3>Currently there are no business users.</h3>
            </div>
          </div>
        ) : (
          <>
            <TableContainer sx={{ height: "calc( 100% - 40px)" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow className={classes.root}>
                    <TableCell>
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "0.5rem",
                        }}
                      >
                        <p>Username</p>
                        <img
                          style={{ width: 14, marginLeft: "0.2rem" }}
                          src={Group_345}
                          alt="Group_345"
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      <span style={{ display: "flex" }}>
                        <p>Email Address</p>
                        <img
                          style={{ width: 14, marginLeft: "0.2rem" }}
                          src={Group_345}
                          alt="Group_345"
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      <span style={{ display: "flex" }}>
                        <p>Location</p>
                        <img
                          style={{ width: 14, marginLeft: "0.2rem" }}
                          src={Group_345}
                          alt="Group_345"
                        />
                      </span>
                    </TableCell>

                    <TableCell>
                      <span style={{ display: "flex" }}>
                        <p>Category</p>
                        <img
                          style={{ width: 14, marginLeft: "0.2rem" }}
                          src={Group_345}
                          alt="Group_345"
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      <span style={{ display: "flex" }}>
                        <p>Sub-Category</p>
                        <img
                          style={{ width: 14, marginLeft: "0.2rem" }}
                          src={Group_345}
                          alt="Group_345"
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      <span style={{ display: "flex" }}>
                        <p>Pro Status</p>
                        <img
                          style={{ width: 14, marginLeft: "0.2rem" }}
                          src={Group_345}
                          alt="Group_345"
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      <span style={{ display: "flex" }}>
                        <p>Expiry Date</p>
                        <img
                          style={{ width: 14, marginLeft: "0.2rem" }}
                          src={Group_345}
                          alt="Group_345"
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      <span style={{ display: "flex" }}>
                        <p>Invoice Number</p>
                        <img
                          style={{ width: 14, marginLeft: "0.2rem" }}
                          src={Group_345}
                          alt="Group_345"
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      <span style={{ display: "flex" }}>
                        <p>Date Edited</p>
                        <img
                          style={{ width: 14, marginLeft: "0.2rem" }}
                          src={Group_345}
                          alt="Group_345"
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      <span style={{ display: "flex" }}>
                        <p>Mark as Unverified</p>
                        <img
                          style={{ width: 14, marginLeft: "0.2rem" }}
                          src={Group_345}
                          alt="Group_345"
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      <span style={{ display: "flex" }}>
                        <p>Action</p>
                        <img
                          style={{ width: 14, marginLeft: "0.2rem" }}
                          src={Group_345}
                          alt="Group_345"
                        />
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {proBusinessData?.map((item, i) => {
                    return (
                      <TableRow
                        className={classes.bodyroot}
                        hover
                        role="checkbox"
                      >
                        <TableCell key={i}>
                          <span
                            onClick={() => NavigateToBusinessUserProfile(item)}
                            className="flex justify-start items-center "
                          >
                            <div
                              style={{
                                width: "2rem",
                                height: "2rem",
                                cursor: "pointer",
                              }}
                            >
                              <img
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: "50%",
                                  border: "1px solid grey",
                                }}
                                src={
                                  item?.image &&
                                  (item.image.includes(".jpg") ||
                                    item.image.includes(".jpeg") ||
                                    item.image.includes(".png"))
                                    ? `https://${item?.image}`
                                    : avatar
                                }
                                onError={FailedToLoad}
                                alt="user"
                                onClick={() =>
                                  NavigateToBusinessUserProfile(item)
                                }
                              />
                            </div>
                            <span className="FirstClildProBusiness ">
                              <span className="underLineTextProBusiness ">
                              {item?.firstName}  {item?.lastName}
                              </span>
                              <span className="returingDataDate">
                                {item?.userId}
                              </span>
                            </span>
                          </span>
                        </TableCell>
                        <TableCell>
                          <p className="returingDataSearch">
                            {item?.emailAddress}
                          </p>
                        </TableCell>
                        <TableCell>
                        {item?.location?.map((data, i) => (
                            <span key={i} className="returingDataSearch">
                              {data}
                              {i < item.location.length - 1 && ", "}
                            </span>
                          ))}
                        </TableCell>
                        <TableCell>
                          <p className="returingDataSearch">
                            {item?.category?.name}
                          </p>
                        </TableCell>
                        <TableCell>
                          {item?.subCategory?.map((sub_name, index) => (
                            <p className="returingDataSearch">
                              {sub_name?.name}
                            </p>
                          ))}
                        </TableCell>

                        <TableCell>
                          {item?.status === true ? (
                            <Chip
                              label={"Pro"}
                              size="small"
                              color="success"
                              style={{
                                marginRight: "2px",
                                padding: "1px 2px", // Adjusted padding
                                fontSize: "0.55rem", // Adjusted font size
                                minHeight: "20px", // Adjusted minimum height
                                minWidth: "20px", // Adjusted minimum width
                              }}
                            />
                          ) : (
                            <Chip
                              label={"Non-pro"}
                              size="small"
                              color="success"
                              style={{
                                marginRight: "2px",
                                padding: "1px 2px", // Adjusted padding
                                fontSize: "0.55rem", // Adjusted font size
                                minHeight: "20px", // Adjusted minimum height
                                minWidth: "20px", // Adjusted minimum width
                              }}
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          <p
                            className="returingDataSearch"
                            key={data?.updatedAt}
                          >
                            {formatDate(item)}
                          </p>
                        </TableCell>

                        <TableCell>
                          <p className="returingDataSearch">
                            {/* {item?.paymentTransactionDetails[0]?.subscription_id} */}
                            {transId(item)}
                          </p>
                        </TableCell>
                        <TableCell>
                          <p className="returingDataSearch">{ExpDate(item)}</p>
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={approvalStatus[item.id] || false}
                            onChange={() => Unapproved(item.id)}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={22}
                            width={47}
                          />
                        </TableCell>
                        <TableCell>
                          <img
                            // onClick={() => deleteBusiness(item)}
                            onClick={() => GoToChat(item)}
                            style={{
                              marginLeft: "0.5rem",
                              cursor: "pointer",
                            }}
                            src={!item?.isLastMsgRead ? threedot_red : ThreeDot}
                            alt="More"
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </div>

      {PaginationDataLength > 0 ? (
        <Footer
          page={page}
          handleChange={handleChange}
          totalRecord={PaginationDataLength}
          startedRecords={startedRecords}
          toRecords={toRecords}
          totalPages={totalPages}
        />
      ) : null}
    </Container>
  );
}

export default SearchProBusinessAccount;
const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      height: "28px",
      padding: "0.3rem 1rem",
    },
  },
  bodyroot: {
    "&:hover": {
      backgroundColor: "#c5d9cf !important",
    },
    "& .MuiTableCell-body": {
      height: "28px",
      padding: "0.2rem 1rem",
    },
  },
});
