import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../helper/axiosInstance";
import { toast } from "react-toastify";
import Socket from "./socketConnect";
import Swal from "sweetalert2";
const initialState = {
  userType: "UNREGISTERED",
  userDetails: "",
  loginLoading: false,
  verifyAdminLoading: false,
  sessionTokenAdmin: "",
  adminData: [],
  logoutData: [],
  CSVVerificationAccountType: "",
  specialLoginLoading: false,
  specialLoginFailed: "",
  resendEamilLoading: false,
  resendEmailData: [],
  resendEmailError: "",
  currentSideBarStatus: true,
  loading: false,
  showUnauthorizedModal: false,
  showSessiosAlert: false,
  uploadingTracker: 0
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state) => {
      state.loginLoading = true;
    },
    loginMasterSuccess: (state, action) => {
      // state.userType = action.payload.accountType;
      // state.userDetails = action.payload;
      state.loginLoading = false;
      state.specialLoginLoading = false;
    },
    loginAdminSuccess: (state, action) => {
      console.log("token", action.payload);
      state.sessionTokenAdmin = action?.payload;
      state.loginLoading = false;
    },
    verifyAdmin: (state) => {
      state.verifyAdminLoading = true;
    },
    verifyAdminSuccess: (state, action) => {
      state.userDetails = action.payload;
      state.adminData = action.payload;
      state.userType = action.payload.accountType;
      state.verifyAdminLoading = false;
    },
    verifyAdminFailed: (state) => {
      state.verifyAdminLoading = false;
    },

    loginAdminLocalSuccess: (state, action) => {
      state.userDetails = action.payload;
      state.loginLoading = false;
      state.userType = action.payload.accountType;
    },
    loginFailed: (state) => {
      state.loginLoading = false;
    },

    logout: (state) => {
      state.userType = "UNREGISTERED";
    },
    logoutSuccess: (state, action) => {
      console.log('called>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
      state.logoutData = action.payload;
      sessionStorage.removeItem("userLoggedDetails");
      sessionStorage.removeItem("userLoggedToken");
      sessionStorage.clear();
      localStorage.clear();
      state.userType = "UNREGISTERED";
    },
    // logoutFailed: (state) => {
    //   // state.userType = "UNREGISTERED";
    // },
    emptyAuthState: (state) => {
      console.log("empty state");
      state.userType = "UNREGISTERED";
      state.userDetails = "";
      state.loginLoading = false;
      state.verifyAdminLoading = false;
      state.sessionTokenAdmin = "";
    },
    recognizeAccountType: (state, action) => {
      // console.log(action.payload);
      state.CSVVerificationAccountType = action.payload;
    },

    LoginSpecial: (state) => {
      state.specialLoginLoading = true;
    },
    LoginSpecialSuccess: (state, action) => {
      state.specialLoginLoading = false;
      state.adminData = action.payload;
    },
    LoginSpecialFailed: (state, action) => {
      state.specialLoginLoading = false;
      state.specialLoginFailed = action.payload;
    },

    ResendEmail: (state) => {
      state.resendEamilLoading = true;
    },
    ResendSuccessfully: (state, action) => {
      state.resendEamilLoading = false;
      state.resendEmailData = action.payload;
    },
    ResendError: (state, action) => {
      state.resendEamilLoading = false;
      state.resendEmailError = action.payload;
    },
    updateidebarStatus: (state, action) => {
      state.currentSideBarStatus = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = true;
    },
    refreshSuccess: (state, action) => {
      state.userDetails = action.payload;
      state.loading = false;
    },
    refreshFailed: (state) => {
      state.loading = false;
      state.error = "Failed to refresh token";
    },
    logoutFailed: (state) => {
      state.error = "Logout failed";
    },
    UpdateSessionAlert: (state, action) => {
      state.showUnauthorizedModal = action.payload;
      // if(action.payload===''){
      //   state.previousRoute = action.payload;
      // }
    },
    ToggleSessionAlert: (state, action) => {
      console.log('Session timed out due to inactivity.??????????????????????????', action.payload);
      state.showSessiosAlert = action.payload;
    },
    UploadingProgress: (state, action) => {
      state.uploadingTracker = action.payload;
    },
  },
});

export const loginAsync = (data, remember) => async (dispatch) => {
  dispatch(login());
  try {
    const response = await axiosInstance.post("/upload/userLogin", data);
    if (response) {
      if (response.data?.user?.accountType === "masteradmin") {
        const userData = {
          ...response?.data?.user,
          remember,
        };
        dispatch(loginMasterSuccess(userData));
        sessionStorage.setItem(
          "userLoggedDetails",
          JSON.stringify(response.data?.user)
        );
        sessionStorage.setItem(
          "userLoggedToken",
          JSON.stringify(response.data?.token)
        );
        return response?.data;
      } else {
        dispatch(loginAdminSuccess(response?.data?.sessionToken));
        sessionStorage.setItem(
          "userLoggedDetails",
          JSON.stringify(response?.data?.user)
        );
        sessionStorage.setItem(
          "userLoggedToken",
          JSON.stringify(response.data?.token)
        );
        return response?.data;
      }
    }
  } catch (error) {
    dispatch(loginFailed());

    toast.error(
      error?.response?.data?.message
        ? error.response.data.message
        : "Login failed!!"
    );
    console.log(error, error?.response?.data.message);

    return "error";
  }
};
// SpecialAdminLogin----------

export const SpecialAdminLogin = (data) => async (dispatch) => {
  dispatch(LoginSpecial());
  try {
    const response = await axiosInstance.post(
      "/AdminConroller/specialAdminLogin",
      data
    );
    if (response) {
      dispatch(verifyAdminSuccess(response.data?.data));
      dispatch(LoginSpecialSuccess(response.data?.data));
      const token = response.data.tokenStr;
      sessionStorage.clear();
      sessionStorage.setItem(
        "userLoggedDetails",
        JSON.stringify(response.data?.data)
      );
      sessionStorage.setItem("userLoggedToken", JSON.stringify(token));
      if (response.data?.data?.accountType === "masteradmin") {
        toast.success("Login Back to master admin!!");
      } else {
        toast.success("Login Successful!!");
        let loggesInUser = JSON.parse(sessionStorage.getItem("userLoggedDetails"));
        Socket.socketInit(loggesInUser?.userId, dispatch);
      }
    }
  } catch (error) {
    dispatch(LoginSpecialFailed(error));
    toast.error(
      error?.response?.data?.message
        ? error.response.data.message
        : "Login failed!!"
    );
    console.log(error, error?.response?.data.message);

    return "error";
  }
};

export const sessionStorageLoginAsync = (data) => async (dispatch) => {
  if (data) {
    if (data.accountType === "MASTERADMIN") {
      dispatch(loginMasterSuccess(data));
    } else if (data.accountType === "ADMIN") {
      dispatch(loginAdminLocalSuccess(data));
    }
  }
};
 
export const verifyAdminAsync = (data) => async (dispatch) => {
  dispatch(verifyAdmin());
  try {
    const response = await axiosInstance.post("AdminConroller/VerifyOtp", data);
    if (response?.data?.status === 200 || 201) {
      dispatch(verifyAdminSuccess(response.data));
      sessionStorage.setItem("userLoggedDetails", JSON.stringify(response.data));
      sessionStorage.setItem("userLoggedToken", JSON.stringify(response.data.token || response.data.jwtToken));
      toast.success("Login Successful!!");
    } else {
      dispatch(verifyAdminFailed());
      toast.error(response?.data?.message || response?.data?.error || "Verification failed.");
    }
  } catch (error) {
    dispatch(verifyAdminFailed());
    toast.error(error?.response?.data?.message || error?.response?.data?.error || "An error occurred.");
  }
};

export const logoutUserAsync = (data) => async (dispatch) => {
  if (!data?.isSwitch) {
    dispatch(logout());
  }
  try {
    const response = await axiosInstance.post(
      "/AdminConroller/LogoutUser",
      data
    );
    if (!data?.isSwitch) {
      if (response.status === 200) {
        dispatch(logoutSuccess(response.status));
        // localStorage.clear();
        return response;
      } else {
        dispatch(logoutFailed());
        Swal.fire("Sorry", " Unable to Logout", "error");
      }
    }
  } catch (err) {
    dispatch(logoutFailed());
    // Swal.fire("Sorry", " Unable to Logout", "error");
  }
};

export const refreshUserAsync = (data) => async (dispatch) => {
  try {
    dispatch(setLoading()); // Start loading
    const response = await axiosInstance.post("/upload/updateExpireToken", data);
    if (response.status === 200) {
      const token = response.data.token.token;
      const updateuserdata = response.data.token.updateuserdata;
      sessionStorage.setItem("userLoggedToken", JSON.stringify(token));
      sessionStorage.setItem("userLoggedDetails", JSON.stringify(updateuserdata));
      dispatch(refreshSuccess(updateuserdata)); // Dispatch success action
      console.log("Token api", response);
      // window.location.reload();
    } else {
      dispatch(logoutFailed());
      Swal.fire("Failed");
    }
  } catch (err) {
    dispatch(refreshFailed()); // Handle error
    console.error("Error during refresh:", err);
  }
};

export const ResendEamilAsync = (data) => async (dispatch) => {
  dispatch(ResendEmail());
  try {
    const response = await axiosInstance.post(
      "/upload/resendMailForIsVerifiedTrue",
      data
    );
    if (response) {
      console.log("repo", response);
      dispatch(ResendSuccessfully(response.data));
      return response.data;
    }
  } catch (error) {
    console.log(error);
    dispatch(ResendError());
  }
};




export const {
  login,
  loginMasterSuccess,
  loginAdminSuccess,
  loginAdminLocalSuccess,
  verifyAdmin,
  verifyAdminSuccess,
  verifyAdminFailed,
  loginFailed,
  logout,
  emptyAuthState,
  logoutSuccess,
  logoutFailed,
  recognizeAccountType,
  LoginSpecial,
  LoginSpecialSuccess,
  LoginSpecialFailed,
  GetStatics,
  GetStaticsSuccess,
  GetStaticsFailed,
  ResendEmail,
  ResendSuccessfully,
  ResendError,
  updateidebarStatus,
  refreshSuccess,
  refreshFailed,
  setLoading,
  UpdateSessionAlert,
  ToggleSessionAlert,
  UploadingProgress
} = authSlice.actions;

export default authSlice.reducer;
