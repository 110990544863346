import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../helper/axiosInstance";
import axiosMultipartInstance from "../helper/axiosMultipartInstance";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const initialState = {
  categoryData: [],
  subCategoryData: [],
  categoryOpenedDetail: "",
  categoryOpenedName: "",
  //------------
  categoryLoading: false,
  subCategoryLoading: false,
  subCategoryLength: "",
  getFavBusinessLoading: false,
  getFavBusinessData: [],
  getFavBusinessFailed: "",
  //--------------
  createProjectLoading: false,
  createProjectData: [],
  createProjectFailed: "",
  // Update project
  updateProjectLoading: false,
  updateProjectData: [],
  updateProjectFailed: "",
  // Update project status
  updateProjectStatusLoading: false,
  updateProjectStatusFailed: "",
};

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    //Category Reducers-------------
    getCategory: (state) => {
      state.categoryLoading = true;
    },
    getCategorySuccess: (state, action) => {
      state.categoryData = action.payload.filter((c) => c.isDeleted === false);
      state.categoryLoading = false;
    },
    getCategoryFailed: (state) => {
      state.categoryLoading = false;
    },

    //---------------
    editCategory: (state) => {
      state.categoryLoading = true;
    },
    editCategorySuccess: (state, action) => {
      console.log(action.payload);
      for (let index = 0; index < state.categoryData.length; index++) {
        if (action.payload.id === state.categoryData[index].id) {
          console.log("Category found");
          state.categoryData[index].name = action.payload.name;
        }
      }

      state.categoryOpenedDetail.name = action.payload.name;
      state.categoryOpenedName = action.payload.name;
      state.categoryLoading = false;
      state.categoryData = [...state.categoryData];
    },
    editCategoryFailed: (state) => {
      state.categoryLoading = false;
    },

    //SubCategory Reducers------------
    addSubCategory: (state) => {
      state.subCategoryLoading = true;
    },
    addSubCategorySuccess: (state) => {
      state.subCategoryLoading = false;
    },
    addSubCategoryFailed: (state) => {
      state.subCategoryLoading = false;
    },

    editSubCategory: (state) => {
      state.subCategoryLoading = true;
    },
    editSubCategorySuccess: (state, action) => {
      for (let index = 0; index < state.subCategoryData.length; index++) {
        if (action.payload.id === state.subCategoryData[index].id) {
          console.log("Sub Category found");
          state.subCategoryData[index].name = action.payload.name;
        }
      }
      state.subCategoryLoading = false;
      state.subCategoryData = [...state.subCategoryData];
    },
    editSubCategoryFailed: (state) => {
      state.subCategoryLoading = false;
    },

    delSubCategory: (state) => {
      state.subCategoryLoading = true;
    },
    delSubCategorySuccess: (state, action) => {
      state.subCategoryLoading = false;
      state.subCategoryData = state.subCategoryData.filter(
        (i) => i.id !== action.payload.id
      );
    },
    delSubCategoryFailed: (state) => {
      state.subCategoryLoading = false;
    },

    getSubCategory: (state) => {
      state.subCategoryLoading = true;
    },
    getSubCategorySuccess: (state, action) => {
      console.log("p", action.payload);
      state.subCategoryData = action.payload;
      state.subCategoryLength = action.payload.length;
      state.subCategoryLoading = false;
    },
    getSubCategoryFailed: (state) => {
      state.subCategoryData = [];
      state.subCategoryLoading = false;
    },
    categoryOpen: (state, action) => {
      // console.log(action.payload);
      state.categoryOpenedDetail = action.payload;
      state.categoryOpenedName = action.payload.name;
    },
    subCategoryDataLength: (state, action) => {
      console.log(action.payload);
      state.lengthData = action.payload;
    },
    FavBusinessUser: (state) => {
      state.getFavBusinessLoading = true;
    },
    FavBusinessuserSuccess: (state, action) => {
      state.getFavBusinessLoading = false;
      state.getFavBusinessData = action.payload;
    },
    FavBusinessUserFailed: (action, state) => {
      state.getFavBusinessLoading = false;
      state.getFavBusinessFailed = action.payload;
    },
    //----------
    CreateProject: (state) => {
      state.createProjectLoading = true;
    },
    CreateProjectSuccess: (state, action) => {
      state.createProjectLoading = false;
      state.createProjectData = action.payload;
    },
    CreateProjectFailed: (state, action) => {
      state.createProjectLoading = false;
      state.createProjectFailed = action.payload;
    },

    // Update project
    UpdateProject: (state) => {
      state.updateProjectLoading = true;
    },
    UpdateProjectSuccess: (state, action) => {
      state.updateProjectLoading = false;
      state.updateProjectData = action.payload;
    },
    UpdateProjectFailed: (state, action) => {
      state.updateProjectLoading = false;
      state.updateProjectFailed = action.payload;
    },
    // Update project status
    UpdateProjectStatus: (state) => {
      state.updateProjectStatusLoading = true;
    },
    UpdateProjectStatusSuccess: (state) => {
      state.updateProjectStatusLoading = false;
    },

    UpdateProjectStatusFailed: (state, action) => {
      state.updateProjectStatusLoading = false;
      state.updateProjectStatusFailed = action.payload;
    },
  },
});

export const editCategoryAsync = (data) => async (dispatch) => {
  console.log(data);
  dispatch(editCategory());

  axiosInstance
    .post("Category/UpdateCategory", data)
    .then((response) => {
      console.log(response);

      toast.success("Category edited!!");
      dispatch(editCategorySuccess(data));
    })
    .catch((err) => {
      if (err?.response?.status === 400) {
        toast.error(err?.response?.data?.message[0]);
      } else {
        toast.error(err?.response?.data?.error);
      }
      dispatch(editCategoryFailed());
    });
};

export const addSubCategoryAsync = (data) => async (dispatch) => {
  console.log(data);
  dispatch(addSubCategory());

  axiosInstance
    .post("Category/CreateSubCategory", data)
    .then((response) => {
      console.log(response);
      toast.success("Sub Category created!!");
      axiosInstance
        .get("Category/GetSubCategory", {
          params: {
            categoryId: data.categoryId,
            name: "",
          },
        })
        .then((response) => {
          dispatch(getSubCategorySuccess(response.data));
          dispatch(addSubCategorySuccess());
          dispatch(getCategoryAsync(false));
        })
        .catch((err) => {
          toast.error(err?.response?.data?.error);
          dispatch(getSubCategoryFailed());
          toast.error(err?.response?.data?.message);
        });
    })
    .catch((err) => {
      dispatch(addSubCategoryFailed());
      toast.error(err?.response?.data?.error);
    });
};

export const editSubCategoryAsync = (data) => async (dispatch) => {
  console.log(data);
  dispatch(editSubCategory());

  axiosInstance
    .post("Category/UpdateSubCategory", data)
    .then((response) => {
      console.log(response);
      toast.success("Sub Category edited!!");
      dispatch(editSubCategorySuccess(data));
    })
    .catch((err) => {
      console.log("errrrr", err?.response?.data?.error);
      // dispatch(editSubCategorySuccess(data));
      if (err?.response?.status === 400) {
        toast.error(err?.response?.data?.message[0]);
      } else {
        toast.error(err?.response?.data?.error);
      }
      dispatch(editSubCategoryFailed());
    });
};

export const getCategoryAsync = (isOpenCat) => (dispatch) => {
  dispatch(getCategory());
  axiosInstance
    .get("Category/GetCategory")
    .then((r) => {
      if (r) {
        console.log("cat resppppp", r);
        dispatch(getCategorySuccess(r.data));
        if (r.data.length) {
          console.log("isOpenCat", isOpenCat);
          if (isOpenCat) {
            dispatch(categoryOpen(r.data[0]));
            dispatch(getSubCategory());

            axiosInstance
              .get("Category/GetSubCategory", {
                params: {
                  categoryId: r.data[0].id,
                  name: "",
                },
              })
              .then((response) => {
                // console.log("response", r, response);
                dispatch(getSubCategorySuccess(response.data));
                if (r.data.id === response.data.categoryId) {
                  dispatch(subCategoryDataLength(r.data.length));
                  // console.log(" data length is ", r.data);
                }
              })
              .catch((err) => {
                // console.log(err);
                dispatch(getSubCategoryFailed());
              });
          }
        }
      }
    })
    .catch((err) => {
      dispatch(getCategoryFailed());

      console.log(err);
    });
};

export const delSubCategoryAsync = (data) => async (dispatch) => {
  console.log(data);
  dispatch(delSubCategory());
  axiosInstance
    .post("Category/UpdateSubCategory", data)
    .then((response) => {
      console.log(response);
      toast.success("Sub Category deleted !!");
      dispatch(getCategoryAsync(false));
      dispatch(delSubCategorySuccess(data));
    })
    .catch((err) => {
      console.log(err);
      toast.error("Sub Category not deleted !!");

      dispatch(delSubCategoryFailed());
    });
};

export const getSubCategoryAsync = (name, categoryId) => (dispatch) => {
  dispatch(getSubCategory());

  axiosInstance
    .get("Category/GetSubCategory", {
      params: {
        name,
        categoryId,
      },
    })
    .then((response) => {
      console.log(response);
      dispatch(getSubCategorySuccess(response.data));
    })
    .catch((err) => {
      console.log(err);
      dispatch(getSubCategoryFailed());
    });
};
// Get FAv business users

export const getFavBusinessAsync = (getFavById) => (dispatch) => {
  dispatch(FavBusinessUser());
  axiosInstance
    .get("/BusisnessUser/GetFavouriteUser", {
      params: {
        getFavById,
      },
    })
    .then((response) => {
      dispatch(FavBusinessuserSuccess(response.data));
    })
    .catch((err) => {
      console.log(err);
      dispatch(FavBusinessUserFailed(err?.response?.data));
    });
};
// CreateProeject ;;
export const CreateProjectAsync = (fromData, navigate) => (dispatch) => {
  dispatch(CreateProject());
  axiosMultipartInstance.post("/Category/CreateProject", fromData)
    .then((response) => {
      console.log("resp,,,", response);
      dispatch(CreateProjectSuccess(response.data));
      if (response?.data?.status === true) {
        Swal.fire({
          icon: "success",
          text: "Project Created",
        });
        navigate(-1);
      }
    })
    .catch((err) => {
      console.log("erooooooor,,,,", err);
      Swal.fire({
        icon: "error",
        text: err?.response?.data?.message,
      });
      dispatch(CreateProjectFailed(err?.response?.data));
    });
};

//

export const UpdateProjectAsync = (formData) => async (dispatch) => {
  console.log("formdata", formData);
  dispatch(UpdateProject());
  try {
    const response = await axiosMultipartInstance.post(
      "/Category/updateProject",
      formData
    );
    if (response) {
      dispatch(UpdateProjectSuccess(response.data));
      return true;
    }
  } catch (error) {
    dispatch(UpdateProjectFailed(error?.response?.data));
    return error?.response?.data;
  }
};

export const UpdateProjectStatusAsync = (data) => async (dispatch) => {
  dispatch(UpdateProjectStatus());
  try {
    const response = await axiosInstance.post(
      "/Category/UpdateProjectStatusByProjectId",
      data
    );
    if (response) {
      dispatch(UpdateProjectStatusSuccess(response.data));
      return response?.status;
    }
  } catch (error) {
    dispatch(UpdateProjectStatusFailed(error?.response?.data));
    return false;
  }
};

// Action creators are generated for each case reducer function
export const {
  UpdateProjectStatus,
  UpdateProjectStatusSuccess,
  UpdateProjectStatusFailed,
  editCategory,
  editCategorySuccess,
  editCategoryFailed,
  addSubCategory,
  getCategory,
  getCategorySuccess,
  getCategoryFailed,
  getSubCategory,
  getSubCategorySuccess,
  getSubCategoryFailed,
  categoryOpen,
  addSubCategorySuccess,
  addSubCategoryFailed,
  editSubCategory,
  editSubCategorySuccess,
  editSubCategoryFailed,
  delSubCategory,
  delSubCategorySuccess,
  delSubCategoryFailed,
  subCategoryDataLength,
  FavBusinessUser,
  FavBusinessuserSuccess,
  FavBusinessUserFailed,
  CreateProject,
  CreateProjectSuccess,
  CreateProjectFailed,
  UpdateProject,
  UpdateProjectSuccess,
  UpdateProjectFailed,
} = categorySlice.actions;

export default categorySlice.reducer;
