import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../helper/axiosInstance";
import { toast } from "react-toastify";
import { Constants } from "../utils/Constants";
import axiosMultipartInstance from "../helper/axiosMultipartInstance";
import { UploadingProgress } from "./authSlice";
const initialState = {
  signUpLoading: false,
  signUpWithCSVLoading: false,
  signUpUserFailed: "",
  signUpUserWithCSVFailed: "",
  signUpFailedUsersData: [],
  getAllAdminLoading: false,
  allAdminData: [],
  AdminPaginationDataLength: 0,
  adminStartedRecords: 1,
  adminToRecords: 20,
  totalAdminPage: 0,
  selectedEmail: "",
  selectedId: [],
  SelectedUUID: "",
  //-------
  deleteLoading: false,
  deleteData: [],
  deleteError: "",
  //----------------
  loginHistoryLoading: false,
  loginHistoryData: [],
  loginHistoryError: "",
  //---------------------
  unsuccessfulLoginLoading: false,
  unsuccessfulLoginData: [],
  unsuccessfulLoginError: "",
  //-------------------
  changePasswordLoading: false,
  changePasswordData: [],
  changePasswordError: "",
  //----------------------
  emailVerificationLoading: false,
  emailVerificationData: [],
  emailVerificationError: "",

  //-------------------
  forgetPasswordLoading: false,
  forgetPasswordData: [],
  forgetPasswordError: "",
  // Selected route
  CurrentRoute: "/tempCustomerAccount",
  previousRoute: "",
};
export const adminManagementSlice = createSlice({
  name: "adminManage",
  initialState,
  reducers: {
    signUp: (state) => {
      state.signUpLoading = true;
    },
    signUpSuccess: (state) => {
      state.signUpLoading = false;
    },
    signUpFailed: (state, action) => {
      state.signUpLoading = false;
      state.signUpUserFailed = action.payload;
    },
    signUpWithCSV: (state) => {
      state.signUpWithCSVLoading = true;
    },
    signUpSuccessWithCSV: (state) => {
      state.signUpWithCSVLoading = false;
    },
    signUpFailedWithCSV: (state, action) => {
      state.signUpWithCSVLoading = false;
      state.signUpUserWithCSVFailed = action.payload;
    },
    signUpFailedFailedUsers: (state, action) => {
      state.signUpFailedUsersData = action.payload;
    },
    ClearsignUpFailedFailedUsers: (state) => {
      state.signUpFailedUsersData = [];
    },
    getAllAdmin: (state) => {
      state.getAllAdminLoading = true;
    },
    getAllAdminSuccess: (state, action) => {
      state.getAllAdminLoading = false;
      state.allAdminData = action.payload[0];
      state.AdminPaginationDataLength = action.payload[1];
      state.adminStartedRecords =
        (action.payload.CurrentPage - 1) * Constants.TAKE + 1;
      state.adminToRecords = Math.min(
        action.payload.CurrentPage * Constants.TAKE,
        action.payload[action.payload.length - 1]
      );
      state.totalAdminPage = Math.ceil(action.payload[1] / Constants.TAKE);
    },
    getAllAdminFailed: (state) => {
      state.getAllAdminLoading = false;
    },
    storeUserId: (state, action) => {
      state.selectedEmail = action.payload;
    },
    storeId: (state, action) => {
      state.selectedId = action.payload;
    },
    store_Selected_UUID: (state, action) => {
      state.SelectedUUID = action.payload;
    },
    deleteAdmin: (state) => {
      state.deleteLoading = true;
      state.deleteError = "";
    },
    deleteAdminSuccess: (state, action) => {
      state.deleteLoading = false;
      state.allAdminData = state.allAdminData.filter(
        (i) => i.id !== action.payload
      );
    },

    setHideShowMA: (state, action) => {
      if (action.payload?.isShowMasterAdminAcc) {
        state.allAdminData = [action.payload, ...state.allAdminData];
      } else {
        state.allAdminData = state.allAdminData.filter(
          (i) => i.id !== action.payload?.id
        );
      }
    },
    deleteAdminFailed: (state, action) => {
      state.deleteLoading = false;
      state.deleteError = action.payload;
    },
    //--------LoginHistory---------
    getLoginHistory: (state) => {
      state.loginHistoryLoading = true;
    },
    getLoginHistorySuccess: (state, action) => {
      console.log("action.payload >>>>>>>>", action.payload);
      state.loginHistoryLoading = false;
      state.loginHistoryData = action.payload;
    },
    getLoginHistoryFailed: (state, action) => {
      state.loginHistoryLoading = false;
      state.loginHistoryError = action.payload;
    },
    //----------Unsuccessfull----
    getUnsuccessfulLogin: (state) => {
      state.unsuccessfulLoginLoading = true;
    },
    getUnsuccessfulLoginSuccess: (state, action) => {
      state.unsuccessfulLoginLoading = false;

      state.unsuccessfulLoginData = action.payload.filter(
        (v) => v.LoginLogout !== 1
      );
    },
    getUnsuccessfulLoginFailed: (state, action) => {
      state.unsuccessfulLoginLoading = false;
      state.unsuccessfulLoginError = action.payload;
    },
    //--------Change-Password-----
    changePassword: (state) => {
      state.changePasswordLoading = true;
    },
    changePasswordSuccess: (state, action) => {
      state.changePasswordLoading = false;
      state.changePasswordData = action.payload;
    },
    changePasswordFailed: (state, action) => {
      state.changePasswordLoading = false;
      state.changePasswordError = action.payload;
    },
    //-------Email Verify----
    getEmailVerify: (state) => {
      state.emailVerificationLoading = true;
    },
    getEmailVerifySUccess: (state, action) => {
      state.emailVerificationLoading = false;
      state.emailVerificationData = action.payload;
    },
    getEmailVerifyFailed: (state, action) => {
      state.emailVerificationLoading = false;
      state.emailVerificationError = action.payload;
    },

    udateMAdmin: (state) => {
      state.getAllAdminLoading = true;
    },
    udateMAdminSuccess: (state, action) => {
      const updatedData = action.payload;
      state.allAdminData = state.allAdminData.map((admin) =>
        admin.id === updatedData.id ? { ...admin, ...updatedData } : admin
      );
      state.getAllAdminLoading = false;
    },
    udateMAdminFailed: (state) => {
      state.getAllAdminLoading = false;
    },
    udateMAdminUserId: (state) => {
      state.getAllAdminLoading = true;
    },
    udateMAdminUserIdSuccess: (state, action) => {
      const updatedData = action.payload;
      if (updatedData !== null) {
        state.allAdminData = state.allAdminData.map((admin) =>
          admin.id === updatedData.id ? { ...admin, ...updatedData } : admin
        );
      }
      state.getAllAdminLoading = false;
    },
    udateMAdminUserIdFailed: (state) => {
      state.getAllAdminLoading = false;
    },

    // Forget admin/master Password

    //--------Change-Password-----
    forgetPwd: (state) => {
      state.forgetPasswordLoading = true;
    },
    forgetPwdSuccess: (state, action) => {
      state.forgetPasswordLoading = false;
      state.forgetPasswordData = action.payload;
    },
    forgetPwdFailed: (state, action) => {
      state.forgetPasswordLoading = false;
      state.forgetPasswordError = action.payload;
    },
    SelectedRoute: (state, action) => {
      state.CurrentRoute = action.payload;
      // if(action.payload===''){
      //   state.previousRoute = action.payload;
      // }
    },
    RefreshAdmins: (state, action) => {
      const updatedData = action.payload;
      state.allAdminData = state.allAdminData.map((admin) =>
        admin.id === updatedData.id ? { ...admin, ...updatedData } : admin
      );
    },
  },
});

export const signUpAsync = (formData) => async (dispatch) => {
  dispatch(signUp());
  try {
    const response = await axiosMultipartInstance.post(
      "/upload/createUser",
      formData
    );
    console.log(response);
    if (response?.data?.status === 200 || 201) {
      toast.success("Admin created!!");
    } else {
      toast.error("Admin not created!!");
    }
    dispatch(signUpSuccess(response.data.resultModel));
    return "success";
  } catch (err) {
    console.log("err from my side", err.response.data.message);
    dispatch(signUpFailed(err.response.data.message));

    return "error";
  }
};
export const signUpAsyncWithCSV = (data, id, accountType) => async (dispatch) => {
  dispatch(UploadingProgress(1));
  dispatch(signUpWithCSV());
  try {
    const response = await axiosInstance.post(
      "upload/saveMultipleUsers",
      data, //  (CSV data)
      {
        params: accountType === 'admin'
          ? { masterAdminId: id, accountType }
          : { adminId: id, accountType },
      }
    );
    console.log('response>>>>>>>>>>', response);
    dispatch(signUpSuccessWithCSV(response));
    return response;
  } catch (err) {
    console.log('err>>>>>>>>>>>>', err);
    dispatch(signUpFailedFailedUsers(err.response?.data?.existingUsers));
    dispatch(signUpFailedWithCSV(err.response?.data?.message));
    return err?.response;
  }
};


export const getAllAdminAsync =
  (firstName, lastName, isPrimaryAcc, isShowBoth, take, CurrentPage) =>
    (dispatch) => {
      dispatch(getAllAdmin());
      axiosInstance
        .get("/AdminConroller/GetAllAdmins", {
          params: {
            firstName,
            isPrimaryAcc,
            isShowBoth,
            page: CurrentPage,
            perPage: take,
          },
        })
        .then((response) => {
          if (response) {
            console.log("re1s", response);
            response.data.CurrentPage = CurrentPage;
            dispatch(getAllAdminSuccess(response.data));
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(getAllAdminFailed());
        });
    };

export const getLoginHistoryAsync =
  (UserId, Udate, Ddate, page, perPage) => (dispatch) => {
    dispatch(getLoginHistory());
    axiosInstance
      .get("/AdminConroller/GetAdminLoggedHistory", {
        params: {
          UserId,
          Udate,
          Ddate,
          page,
          perPage,
        },
      })
      .then((response) => {
        dispatch(getLoginHistorySuccess(response.data));
      })
      .catch((err) => {
        dispatch(getLoginHistoryFailed());
      });
  };

export const getUnsuccessfulLoginAsync =
  (UserId, Udate, Ddate) => (dispatch) => {
    dispatch(getUnsuccessfulLogin());
    axiosInstance
      .get("/AdminConroller/GetAdminLoggedHistory", {
        params: {
          UserId,
          Udate,
          Ddate,
        },
      })
      .then((response) => {
        console.log("llllllll", response);
        dispatch(getUnsuccessfulLoginSuccess(response.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(getUnsuccessfulLoginFailed());
      });
  };

export const changePasswordAsync = (data) => async (dispatch) => {
  try {
    dispatch(changePassword(data));
    const response = await axiosInstance.post(
      "/AdminConroller/changeAdminPassword",
      data
    );
    if (response) {
      console.log(response);
      toast.success(response?.data);
      dispatch(changePasswordSuccess(response));
      return "success";
    }
  } catch (err) {
    console.log(err);
    dispatch(changePasswordFailed(err.response.data));
    toast.error("Change Password Failed");
    return "error";
  }
};
export const UpdateUser = (id, isOtpAutp) => async (dispatch) => {
  try {
    const response = await axiosInstance.post("/upload/updateMasterAdmin", {
      params: {
        id,
        isOtpAutp,
      },
    });
    if (response) {
      console.log(response);
      // toast.success(response?.data);
      // dispatch(changePasswordSuccess(response));
      return "success";
    }
  } catch (err) {
    console.log(err);
    // dispatch(changePasswordFailed(err.response.data));
    // toast.error("update Password Failed");
    return "error";
  }
};

export const deleteAdminAsync = (userId) => async (dispatch) => {
  console.log("userId", userId);
  dispatch(deleteAdmin());
  try {
    const response = await axiosInstance.delete("/AdminConroller/DeleteUser", {
      params: {
        userId,
      },
    });
    console.log(response);
    if (response?.status === 200 || 201) {
      toast.success("Admin Deleted !!");
      dispatch(deleteAdminSuccess(userId));
      return "admin deleted success";
    }
  } catch (err) {
    console.log(err);
    toast.error("Admin not Deleted !!");
    dispatch(deleteAdminFailed(err.response.data));
    return "error";
  }
};

export const ForgetPasswordAsync = (userId) => async (dispatch) => {
  dispatch(forgetPwd());
  console.log("userId  ", userId);
  const data = { userId: userId };
  try {
    const response = await axiosInstance.post(
      "/AdminConroller/SendForgetPasswordUser",
      data
    );
    if (response) {
      dispatch(forgetPwdSuccess(response));
      if (response.status === 200) {
        console.log("resp", response);
        return true;
      } else {
        toast.error(response.data?.message);
        return false;
      }
    }
  } catch (err) {
    console.log(err);
    dispatch(forgetPwdFailed(err.response.data?.message));
    // toast.error(err?.response.data?.message);
    // return "error";
  }
};

export const updateMAdmin = (data) => async (dispatch) => {
  dispatch(udateMAdmin());
  try {
    const response = await axiosInstance.post(
      "AdminConroller/updateMAdmin",
      data
    );
    if (response) {
      dispatch(udateMAdminSuccess(response?.data));
      if (data?.isShowSwitchBtn) {
        sessionStorage.setItem(
          "userLoggedDetails",
          JSON.stringify(response?.data)
        );
      }
      toast.success("Updated Successful!!");
      return response?.data;
    }
  } catch (error) {
    dispatch(udateMAdminFailed());

    toast.error(
      error?.response?.data?.error
        ? error.response?.data?.error
        : "update failed!!"
    );
    console.log(error.response.data.message);
    return "error";
  }
};
export const updateMAdminUserId = (data) => async (dispatch) => {
  dispatch(udateMAdminUserId());
  try {
    const response = await axiosInstance.post(
      "upload/changeMasterAdminUserIdByOTP",
      data
    );
    if (response) {
      if (response?.data?.currentState === 2) {
        dispatch(udateMAdminUserIdSuccess(response?.data?.updateUser));
      } else {
        dispatch(udateMAdminUserIdSuccess(null));
      }
      toast.success(
        response?.data?.currentState === 2
          ? "MasterAdmin UserId successfully updated."
          : "OTP sent successfully"
      );
      return response;
    }
  } catch (error) {
    dispatch(udateMAdminUserIdFailed());
    toast.error(error?.response?.data?.error);
    return error?.response;
  }
};
export const RefreshAdminAsync = (data) => (dispatch) => {
  dispatch(RefreshAdmins(data));
};

// Action creators are generated for each case reducer function
export const {
  signUp,
  signUpSuccess,
  signUpFailed,
  getAllAdmin,
  getAllAdminSuccess,
  getAllAdminFailed,
  storeUserId,
  storeId,
  store_Selected_UUID,
  deleteAdmin,
  deleteAdminSuccess,
  deleteAdminFailed,
  getLoginHistory,
  getLoginHistorySuccess,
  getLoginHistoryFailed,
  getUnsuccessfulLogin,
  getUnsuccessfulLoginSuccess,
  getUnsuccessfulLoginFailed,
  changePassword,
  changePasswordSuccess,
  changePasswordFailed,
  forgetPwd,
  forgetPwdFailed,
  forgetPwdSuccess,
  SelectedRoute,
  udateMAdmin,
  udateMAdminSuccess,
  udateMAdminFailed,
  setHideShowMA,
  udateMAdminUserId,
  udateMAdminUserIdSuccess,
  udateMAdminUserIdFailed,
  RefreshAdmins,
  signUpWithCSV,
  signUpSuccessWithCSV,
  signUpFailedWithCSV,
  signUpFailedFailedUsers,
  ClearsignUpFailedFailedUsers
} = adminManagementSlice.actions;

export default adminManagementSlice.reducer;
