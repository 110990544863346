import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { getWindowSize } from "../../utils/dimensions";
import { useDispatch, useSelector } from "react-redux";
import "./SidebarAdmin.scss";
import Switch from "react-switch";
import {
  logoutUserAsync,
  loginAsync,
  SpecialAdminLogin,
} from "../../redux/authSlice";
import Swal from "sweetalert2";
import Loader from "../Loader";
import Horizontal from "../../assets/icons/Horizontal.svg";
import AppIcon from "../../assets/icons/AppIcon.svg";
import { SelectedRoute } from "../../redux/adminManagementSlice";
import { UpdateSessionAlert } from "../../redux/authSlice";
function SidebarAdmin() {
  const { adminData, loginLoading ,specialLoginLoading} = useSelector((state) => state.auth);
  const { CurrentRoute } = useSelector((state) => state.adminManage);
  const { newCustomerAccVerificationData, newBussinessLicenseVerifactionData } =
    useSelector((state) => state.users);
  const {
    AllUsers,
    isNotificationBusiness,
    isNotificationCustomer,
    RecieverDetails,
    ChatData,
  } = useSelector((state) => state?.chat);

  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const sidebarOpenRef = useRef(true);
  const [activeListItem, setActiveListItem] = useState("");
  const [iPAddress, setIPAddress] = useState("");
  const [checked, setchecked] = useState(false);
  const [prevRoute, setPrevRoute] = useState("");
  const dispatch = useDispatch();
  const [activeMenuItem, setActiveMenuItem] = useState("");
  const [isValidUser, SetisValidUser] = useState(false);
  const MasterAdminData = localStorage.getItem("MaUser");
  const MaData = JSON.parse(MasterAdminData);
  // console.log('maUser<<<<<<<<<<<<<<<<<<<<',MaData)
  useEffect(() => {
    let isUser = RecieverDetails?.filter((u) => u?.ChatId === ChatData?.chatId);
    if (isUser?.length === 0) {
      SetisValidUser(true);
      console.log("is validate");
    } else {
      SetisValidUser(false);
    }
  }, [ChatData]);
  const getData = async () => {
    try {
      // const res = await axios.get("https://geolocation-db.com/json/");
      // setIPAddress(res.data.IPv4);
      setIPAddress("18541154446");
    } catch (error) {
      console.error(error);
    }
  };
  const filteredCustomerData = newCustomerAccVerificationData?.filter(
    (data) => data?.isReview == null
  );
  const filteredBuzData = newBussinessLicenseVerifactionData?.filter(
    (data) => data?.isReview == null
  );
  const isNewMsg = AllUsers?.some((data) => data?.isLastMsgRead == false);

  const filteredReceiver = AllUsers?.filter(
    (user) => user?.isSenderAdmin === false && user?.lastMessage
  );

  const sideBarOptions = [
    {
      name: "Temp. Customer Account",
      icon: require("../../assets/icons/person_remove.svg").default,
      navigationScreen: "/tempCustomerAccount",
    },
    {
      name: "New Cus. Acc. Verification",
      icon:
        isNotificationCustomer || filteredCustomerData.length > 0
          ? require("../../assets/icons/worspace_premium_red.svg").default
          : require("../../assets/icons/workspace_premium.svg").default,
      navigationScreen: "/newCustomerVerification",
    },
    {
      name: "Search Customer Account",
      icon: require("../../assets/icons/person_search.svg").default,
      navigationScreen: "/searchCustomerAccount",
    },
    {
      name: "Search Business account",
      icon: require("../../assets/icons/person_search.svg").default,
      navigationScreen: "/SearchBusinessAccount",
    },
    {
      name: "Temp. Business account",
      icon: require("../../assets/icons/data.svg").default,
      navigationScreen: "/tempBusinessAccount",
    },
    {
      name: "Business license verification",
      icon:
        isNotificationBusiness || filteredBuzData.length > 0
          ? require("../../assets/icons/data_red.svg").default
          : require("../../assets/icons/data.svg").default,
      navigationScreen: "/businessLicenseVerification",
    },
    {
      name: "Search ADs per location",
      icon: require("../../assets/icons/campaign.svg").default,
      navigationScreen: "/searchAdsPerLocation",
    },
    {
      name: "Search by project/invoice",
      icon: require("../../assets/icons/campaign.svg").default,
      navigationScreen: "/searchByProject",
    },
    {
      name: "Search PRO business accounts",
      icon: require("../../assets/icons/pro-business-icon.svg").default,
      navigationScreen: "/searchProBusinessAccount",
    },
    {
      name: "Messages",
      icon:
        isNewMsg == false || isValidUser
          ? require("../../assets/icons/sms_red.svg").default
          : require("../../assets/icons/sms.svg").default,
      navigationScreen: "/Messages",
    },
    {
      name: "Secondary Email",
      icon: require("../../assets/icons/SecondaryEmail.svg").default,
      navigationScreen: "/AddSecondaryEmail",
    },
    {
      name: "Logout",
      icon: require("../../assets/icons/login.svg").default,
      navigationScreen: "/Logout",
    },
  ];
  //------

  useEffect(() => {
    const handlePopState = () => {
      const url = window.location.href;
      const segments = url.split("/");
      const lastSegment = segments[segments.length - 1];
      // You can handle any additional logic here
      dispatch(SelectedRoute(`/${lastSegment}`));
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const handleChange = () => {
    if (MaData) {
      const data = {
        UserId: MaData.userId,
        password: MaData.password,
        accountType: "masteradmin",
        // iPAddress,
      };
      const logOutdata = {
        id: adminData?.id,
        token: adminData?.deviceToken,
        requestType: 0,
        isSwitch: true,
      };
      let loggesInUser = JSON.parse(
        sessionStorage.getItem("userLoggedDetails")
      );
      dispatch(SelectedRoute("/tempCustomerAccount"));
      if (!loggesInUser?.isAvailableForChat) {
        Swal.fire({
          title: "Session Closed",
          text: "Are you sure you want to switch to Master Admin?If you do, your session will expire.",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#28a745",
          cancelButtonColor: "#d33",
          confirmButtonText: "Confirm!",
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch(logoutUserAsync(logOutdata))
              .then((resp) => {
                console.log("resp in side bar ", resp);
              })
              .catch((error) => {
                console.error("Error:", error);
              });
            dispatch(SpecialAdminLogin(data));
            // sessionStorage.clear();
            // localStorage.clear();
            setchecked(!checked);
            dispatch(UpdateSessionAlert(false));
          }
        });
      } else {
        dispatch(logoutUserAsync(logOutdata))
          .then((resp) => {
            dispatch(UpdateSessionAlert(false));
            console.log("resp in side bar ", resp);
          })
          .catch((error) => {
            console.error("Error:", error);
          });

        dispatch(SpecialAdminLogin(data));
        // sessionStorage.clear();
        // localStorage.clear();
        setchecked(!checked);
        dispatch(UpdateSessionAlert(false));
      }
    } else {
      console.log("Login Error ");
    }
  };
  const menuClicked = (name, navigationScreen) => {
    dispatch(SelectedRoute(navigationScreen));
    setActiveMenuItem(name);
    if (navigationScreen !== "/UserProfile") {
      sessionStorage.removeItem("activeMenuItem");
    }
    const storedActiveMenuItem = sessionStorage.getItem("activeMenuItem");
    if (storedActiveMenuItem && navigationScreen == "/UserProfile") {
      storeActiveMenuItem(name);
    }

    if (name === "Logout") {
      const data = {
        id: adminData?.id,
        token: adminData?.deviceToken,
        requestType: 0,
        isSwitch: false,
      };
      let loggesInUser = JSON.parse(
        sessionStorage.getItem("userLoggedDetails")
      );
      let textS, confirmBtnText;
      if (!loggesInUser?.isAvailableForChat) {
        textS =
          "Are you sure you want to logout?If you do, your session will expire.";
        confirmBtnText = "confirm";
      }
      Swal.fire({
        title: "Are you sure?",
        text: textS ? textS : "You won't be logout!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#d33",
        confirmButtonText: confirmBtnText ? "Confirm!" : "Yes, Logout it!",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(logoutUserAsync(data));
          dispatch(SelectedRoute("/tempCustomerAccount"));
          Swal.fire("Logout", " Successfully Logout", "success");
          dispatch(UpdateSessionAlert(false));
        }
      });
    }
    setActiveListItem(name);
    navigate(navigationScreen);
    if (getWindowSize().innerWidth <= 768 && sidebarOpenRef.current) {
      sidebarOpenRef.current = false;
      setOpen(false);
    }
  };
  // const SetActiveBar = () => {
  //   const matchingOption = sideBarOptions?.find(
  //     (option) => option.navigationScreen === `/${lastSegment}`
  //   );

  //   if (activeMenuItem) {
  //     setActiveListItem(activeMenuItem);
  //   } else if (matchingOption) {
  //     setActiveListItem(matchingOption.name);
  //   } else {
  //     // setActiveListItem("Temp. Customer Account");
  //   }
  // };

  const storeActiveMenuItem = (menuItem) => {
    sessionStorage.setItem("activeMenuItem", menuItem);
  };
  useEffect(() => {
    // SetActiveBar();
    getData();
    // Retrieve the active menu item from local storage
    const storedActiveMenuItem = sessionStorage.getItem("activeMenuItem");
    if (storedActiveMenuItem) {
      setActiveMenuItem(storedActiveMenuItem);
    }
    function handleWindowResize() {
      // Window resize logic...
    }

    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
      // Store the active menu item in local storage before unmounting
    };
  }, [menuClicked]);
  return (
    <>
      {loginLoading ||specialLoginLoading ? <Loader /> : null}
      <div
        className={`sidebar min-h-screen bg-[#38AB7C]
       ${
         open ? "md:w-[22rem] absolute top-0 left-0 md:static " : "w-[3.2rem]"
       } duration-500 
        `}
      >
        <div className="upper-sidebar flex relative">
          {open ? (
            <>
              <img
                className={`${open && " relative -top-5 mr-5 w-[90%]"}`}
                src={Horizontal}
                alt="ZAIBIDS"
              />
              <FiMenu
                size={26}
                className="cursor-pointer absolute right-2 top-8 text-white"
                onClick={() => setOpen(!open)}
              />
            </>
          ) : (
            <>
              <img
                className={`${!open && " mt-12"}`}
                src={AppIcon}
                alt="Admin"
              />
              <FiMenu
                size={26}
                className="cursor-pointer absolute right-2 top-2 text-white"
                onClick={() => setOpen(!open)}
              />
            </>
          )}
        </div>

        <div
          className={`mb-1 text-white duration-500 flex flex-col justify-center items-center ${
            !open && "hidden translate-x-28 overflow-hidden"
          }`}
        >
          <p className={`mb-1 text-white text-sm`}> Admin Panel</p>

          {MasterAdminData ? (
            <>
              <p className="mb-1 text-yellow-400 text-xs">
                Switch to Master Account
              </p>
              <Switch
                onChange={handleChange}
                checked={checked}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor="#FFD555"
              />
            </>
          ) : null}
        </div>
        <div className="lower-sidebar">
          {sideBarOptions?.map(({ name, icon, navigationScreen }, i) => (
            <div
              key={i}
              className={`sidebar-option cursor-pointer w-full justify-start group flex items-center text-sm gap-3.5 font-medium 
      ${
        !open ? "pb-[10px] pt-[10px] pl-3 pr-3" : "p-[8px] pl-4 pr-4"
      } hover:bg-gray-800 ${
                CurrentRoute === navigationScreen ? `bg-gray-800` : null
              }`}
              onClick={() => menuClicked(name, navigationScreen)}
            >
              <img
                src={icon}
                className="mr-2 md:mr-4 w-[18px] h-[18px] inline"
                alt={name}
              />
              <span
                style={{
                  transitionDelay: `${i + 3}00ms`,
                }}
                className={`whitespace-pre   ${
                  (name === "New Cus. Acc. Verification" &&
                    (isNotificationCustomer ||
                      filteredCustomerData?.length > 0)) ||
                  (name === "Messages" && (isNewMsg === false || isValidUser))
                    ? `text-red-700`
                    : name === "Business license verification" &&
                      (isNotificationBusiness || filteredBuzData.length > 0)
                    ? `text-red-700`
                    : `text-white`
                }   text-[0.9rem] sidebar-option-text duration-500 ${
                  !open && "opacity-0 translate-x-28 overflow-hidden"
                }`}
              >
                {name}
              </span>
              <span
                className={`${
                  open && "hidden"
                } text-[0.9rem] absolute left-48 bg-white font-semibold whitespace-pre   ${
                  name === "New Cus. Acc. Verification" &&
                  (isNotificationCustomer || filteredCustomerData?.length > 0)
                    ? `text-red-600`
                    : name === "Business license verification" &&
                      (isNotificationBusiness || filteredBuzData.length > 0)
                    ? `text-red-600`
                    : null
                }   text-gray-900 rounded-md drop-shadow-lg px-0 z-50  py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-2 group-hover:left-14 group-hover:duration-500 group-hover:w-fit  `}
              >
                {name}
              </span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default SidebarAdmin;
