import React, { useState, useEffect, useRef } from "react";
import "./NewCustomerVerification.scss";
import Group_345 from "../../../assets/icons/Group_345.svg";
import SearchIcon from "@mui/icons-material/Search";
import Container from "../../../components/HOC/Container";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ThreeDot from "../../../assets/icons/threedot.svg";
import threedot_red from "../../../assets/icons/threedot_red.svg";
import MessageIcon from "@mui/icons-material/Message";
import {
  UpdateStatusAsync,
  UpdatePhoneVerificationAsync,
  getSearchCustomerAsync,
  getMessageCustomerAsync,
  setSelectedOption,
  selectedUserDetails,
  sendMessageToUser,
} from "../../../redux/usersSlice";
import Socket from "../../../redux/socketConnect";
import { getChatRoomsUser } from "../../../redux/chatSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import Switch from "react-switch";
import MessageComponents from "../../../components/MessageComponent/MessageComponent";
import NOdataFound from "../../../assets/icons/NoAdminData.svg";
import NewCusAccountDialog from "../../../components/Dialogs/AdminPanelActionDialog/NewCusAccountDialog";
import { Constants } from "../../../utils/Constants";
import Footer from "../../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import makeStyles from "@mui/styles/makeStyles";
import avatar from "../../../assets/icons/avatar.svg";
import moment from "moment/moment";
import DraggableImage from "../../../components/Dialogs/ViewFiles/Docs/DraggableImage";
import { SelectedRoute } from "../../../redux/adminManagementSlice";
function NewCustomerVerification() {
  const [messageToggle, setMessageToggle] = useState(false);
  const [writeMessage, setWriteMessage] = useState(false);
  const [messageHeading, setMessageHeading] = useState("Message 1");
  const [message, setMessage] = useState("Write a message");
  const selectedNewCustomerVerificationEmail = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [recentUpdate, setRecentUpdate] = useState(false);
  const [filterDocsAccount, setfilterDocsAccount] = useState(false);
  const [deleteDataNewCusAccount, setDeleteDataNewCusAccount] = useState(false);
  const [page, setPage] = useState(1);
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [verificationData, setVerificationData] = useState([]);
  const [LoggedInId, setLoggedInId] = useState([]);
  const [PrimeIndex, setPrimneIndex] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  // const [customerHeading, setCustomerHeading] = useState("Message 11 ");
  // const [customerMessage, setCustomerMessage] = useState("Hello Developer 1");
  // const [open, setOpen] = useState(true);
  // Commented code ... don't remove
  // const [checkboxStates, setCheckboxStates] = useState(false);
  // const [checked1, setChecked1] = useState(false);
  // const [selectAllIds, setSelectAllIds] = useState([]);
  // const [showPassword, setshowPassword] = useState(false);
  const [checkMessage, setCheckMessage] = useState(false);
  const [loopId, setLoopId] = useState(null);
  const [textMessage, setTextMessage] = useState("");
  const [state, setState] = useState(true);
  const [usersData, setUsersData] = useState([]);
  const classes = useStyles();
  const [userId, setUserId] = useState(null);
  const [msgId, setMsgID] = useState(null);
  const {
    newCustomerAccVerificationData,
    newCustomerAccVerificationLoading,
    startedRecords,
    toRecords,
    updateMessage,
    totalPages,
    PaginationDataLength,
    Edit_msg_Loading,
    markAsVerifiedLoading,
    sendMessageLoading,
    selectedOption,
  } = useSelector((state) => state.users);
  const { ChatRoomsUsers } = useSelector((state) => state.chat);
  const data = {
    name,
    location,
    email,
    address,
    isAdminApproved: 0,
    page: 1,
    perPage: Constants.TAKE,
  };

  useEffect(() => {
    let loggesInUser = JSON.parse(sessionStorage.getItem("userLoggedDetails"));
    setLoggedInId(loggesInUser);
  }, []);

  const handleChangeBusinessAccount = (i) => {
    // setChecked(!checked);
    setMessageToggle(!messageToggle);
  };
  const handleCloseNewCusAccount = () => {
    setDeleteDataNewCusAccount(false);
  };
  const handleChange = (event, value) => {
    data.page = value;
    dispatch(getSearchCustomerAsync(data));
  };

  const approveCustomer = (id) => {
    const changeStatus = {
      accountType: "customer",
      id: id,
      isAdminApproved: true,
      isReview: true,
    };
    dispatch(UpdateStatusAsync(changeStatus));
  };
  // const verifiedPhone = async (id, isCurrentlyVerified) => {
  //   const changeStatus = {
  //     accountType: "customer",
  //     id: id,
  //     isPhoneVerified: !isCurrentlyVerified, // Toggle verification status
  //   };

  //   console.log(changeStatus, "cccc");

  //   // Await the result of the async actions
  //   await dispatch(UpdatePhoneVerificationAsync(changeStatus));
  //   // await dispatch(getSearchCustomerAsync(data));
  // };

  const NavigateToProfile = (item) => {
    dispatch(selectedUserDetails(item));
    navigate("/UserProfile/UserProfile");
    const changeStatus = {
      accountType: "customer",
      id: item?.id,
      isAdminApproved: false,
      isReview: true,
    };
    dispatch(UpdateStatusAsync(changeStatus));
  };

  // const toggleCheckbox = (item) => {
  //   const extractedIds = newCustomerAccVerificationData?.map(
  //     (item) => item?.userId
  //   );
  //   const rowId = item?.userId;
  //   const newCheckboxStates = { ...checkboxStates };
  //   newCheckboxStates[rowId] = !newCheckboxStates[rowId];
  //   const newSelectAllIds = newCheckboxStates[rowId]
  //     ? [...selectAllIds, rowId]
  //     : selectAllIds.filter((id) => id !== rowId);
  //   setCheckboxStates(newCheckboxStates);
  //   setSelectAllIds(newSelectAllIds);
  //   if (newSelectAllIds.length !== extractedIds?.length) {
  //     setChecked1(false);
  //   } else {
  //     setChecked1(true);
  //   }
  // };
  const handleChangeSwitch = () => {
    setChecked(!checked);
    if (!checked) {
      setUsersData(
        newCustomerAccVerificationData?.filter(
          (data) => data?.userToCustomiseMsgTblId === null
        )
      );
    } else {
      setUsersData(newCustomerAccVerificationData);
    }
  };

  const handleRecentUpdate = () => {
    setRecentUpdate(!recentUpdate);
    if (!recentUpdate) {
      setUsersData(
        newCustomerAccVerificationData?.filter(
          (data) => data?.isReview === false
        )
      );
    } else {
      setUsersData(newCustomerAccVerificationData);
    }
  };

  const FilterWithoutDocs = () => {
    setfilterDocsAccount(!filterDocsAccount);
    if (!filterDocsAccount) {
      setUsersData(
        newCustomerAccVerificationData?.filter(
          (data) => data?.ShowDocsUrl === null
        )
      );
    } else {
      setUsersData(newCustomerAccVerificationData);
    }
  };
  useEffect(() => {
    console.log(
      "newCustomerAccVerificationDataHERe",
      newCustomerAccVerificationData
    );

    setUsersData(newCustomerAccVerificationData);
  }, [newCustomerAccVerificationData, sendMessageLoading]);

  // Deleted function------
  // const DeleteItem = (item) => {
  //   setDeleteDataNewCusAccount(true);
  //   selectedNewCustomerVerificationEmail.current = item?.id;
  // };

  const GoToChat = (item) => {
    dispatch(SelectedRoute("/Messages"));
    navigate("/Messages", {
      state: item,
    });
  };
  const SaveMessage = () => {
    setWriteMessage(false);
  };
  const AddNewData = () => {
    setCheckMessage(true);
  };
  const SearchByQueryForVerification = (e) => {
    setName(e.target.value);
    setLocation("");
    setEmail("");
    setAddress("");
  };
  const getDatabyOfCustomerVerfication = () => {
    dispatch(getSearchCustomerAsync(data));
  };
  const Verification_docs = (id, item) => {
    setOpenDialog(true);
    setVerificationData(item);
    const changeStatus = {
      accountType: "customer",
      id: id,
      isAdminApproved: false,
      isReview: true,
    };
    dispatch(UpdateStatusAsync(changeStatus));
  };

  const GetMessages = () => {
    dispatch(getMessageCustomerAsync());
  };

  const dropdownOptions = (item) => {
    return updateMessage?.map((message, index) => ({
      label: `${index === 0 ? "None" : index}`,
      value: message.id,
      data: { id: message.id },
      storageKey: "select",
    }));
  };
  const handleDropdownChange = async (selected, item, index) => {
    setRefreshKey((prevKey) => prevKey + 1);
    let SelectMsg = updateMessage?.filter(
      (data) => data?.id === selected?.value
    );
    setUserId(item);
    dispatch(setSelectedOption(selected));
    // setMsgID(selected?.value);
    // selected.userId = item?.id;
    // selected.msg = SelectMsg[0]?.message;
    // const userData = {
    //   selectedOption: selected,
    //   userID: item?.id,
    // };
    // localStorage.setItem("selectedOption", JSON.stringify(userData));
    const Data = {
      IdOfUser: item?.id,
      CustomMagId: selected?.value,
      accountType: "customer",
      msg: SelectMsg[0]?.message,
      setnull: selected?.label === "None" ? "None" : null,
    };

    let loggesInUser = JSON.parse(sessionStorage.getItem("userLoggedDetails"));

    if (selected?.label !== "None" || item?.userToCustomiseMsgTblId !== null) {
      dispatch(sendMessageToUser(Data)).then((r) => {
        if (r?.data?.success === true) {
          GetMessages();
          setMsgID(0);

          // dispatch(getSearchCustomerAsync(data));
          dispatch(getMessageCustomerAsync());

          dispatch(getChatRoomsUser(loggesInUser?.id, item?.id, "admin")).then(
            (res) => {
              setUserId(null);
              dispatch(setSelectedOption(null));
              if (Data && res) {
                const msg = {
                  chatId: res?.id || ChatRoomsUsers?.id,
                  senderId: loggesInUser?.id,
                  receiverId: Data?.IdOfUser,
                  text: SelectMsg[0]?.message,
                  isSenderAdmin: true,
                  isRead: true,
                };
                if (msg.chatId && msg.senderId && msg.text) {
                  Socket.onSend(msg, dispatch);
                  setMsgID(null);
                }
              }
            }
          );
        } else {
          window.alert("Unable to send message");
        }
      });
    }
  };
  
  useEffect(() => {
    dispatch(getMessageCustomerAsync());
    dispatch(getSearchCustomerAsync(data));
  }, [dispatch]);

  const Prev_message = (item) => {
    const index = updateMessage?.findIndex(
      (m) => m.id === item?.userToCustomiseMsgTblId?.IdOfCustomiseMsg
    );
    return index.toString();
  };

  const editFunction = (text) => {
    setLoopId(text?.id);
    setTextMessage(text?.message);
    setWriteMessage(true);
    // handleChangeBusinessAccount();
  };
  const FailedToLoad = (e) => {
    console.log("not opened");
    e.target.src = avatar;
  };
  const formatDate = (timestamp) => {
    const today = moment().startOf("day");
    const messageDate = moment(parseInt(timestamp, 10));
    if (messageDate.isSame(today, "day")) {
      return "Today";
    } else if (messageDate.isSame(today.clone().subtract(1, "day"), "day")) {
      return "Yesterday";
    } else {
      return messageDate.format("DD MMMM YYYY");
    }
  };
  const numberOfColumns = 12;
  return (
    <Container>
      {newCustomerAccVerificationLoading ||
      Edit_msg_Loading ||
      Edit_msg_Loading ||
      sendMessageLoading ? (
        <Loader />
      ) : null}
      <div className="NewAccountMainContainer">
        {openDialog && (
          <DraggableImage
            openDialog={openDialog}
            verificationData={verificationData}
            setOpenDialog={setOpenDialog}
          />
        )}
        {/* Condition remember */}
        <div
          className={
            !messageToggle
              ? "inner_containerForNewCostmer p-3"
              : "inner_containerForNewCostmer_withMessage p-3"
          }
        >
          <div className="HeadingAndSearchNewCustomer">
            <div className="Left_Details">
              <h1>New Customer account verification</h1>
              <div className="Header_Common">
                <Switch
                  onChange={() =>
                    newCustomerAccVerificationData?.filter(
                      (item) => item?.userToCustomiseMsgTblId !== null
                    )?.length >= 1
                      ? handleChangeSwitch()
                      : window.alert("No account with send message")
                  }
                  checked={checked}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={22}
                  width={47}
                />
                <p className="ml-2 mr-1">Hide Account with send messages</p>
              </div>

              <div className="Header_Common">
                <Switch
                  onChange={handleRecentUpdate}
                  checked={recentUpdate}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={22}
                  width={47}
                />
                <p className="ml-2"> Recent Update</p>
              </div>

              <div className="Header_Common">
                <Switch
                  onChange={() =>
                    newCustomerAccVerificationData?.filter(
                      (data) => data?.ShowDocsUrl !== null
                    )?.length >= 1
                      ? FilterWithoutDocs()
                      : window.alert("All the account without docs uploaded ")
                  }
                  // onChange={FilterWithoutDocs}
                  checked={filterDocsAccount}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={22}
                  width={47}
                />
                <p className="ml-2"> Without Docs</p>
              </div>
            </div>
            <div className="Icon-containerNewCustomer">
              <div className="Input-and-iconsNewCustomer ">
                <SearchIcon
                  onClick={getDatabyOfCustomerVerfication}
                  className="searchIconNewCustomer"
                />
                <input
                  onChange={SearchByQueryForVerification}
                  onKeyDown={(event) => {
                    event.key === "Enter" && getDatabyOfCustomerVerfication();
                  }}
                  className="Input-fieldNewCustomer"
                  type="text"
                  placeholder="Search name"
                />
              </div>
              <MessageIcon
                onClick={() => {
                  handleChangeBusinessAccount();
                  GetMessages();
                }}
                style={{
                  height: "40px",
                  width: "40px",
                }}
              />
            </div>
          </div>
          <>
            <div
              className={
                !messageToggle
                  ? "table-areaForNewCustomer"
                  : "table-areaForNewCustomer_with_Sidebar"
              }
            >
              <TableContainer
                sx={{
                  height: "calc( 100vh - 70px)",
                  marginTop: "1rem",
                  overflowX: "visible",
                  zIndex: 1,
                }}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow className={classes.root}>
                      <TableCell>
                        <span style={{ display: "flex", alignItems: "center" }}>
                          {/* <Checkbox checked={checked1}
                              onChange={toggleSelectAll} /> */}
                          <p>Name</p>
                          <img
                            style={{ width: 14, marginLeft: "0.2rem" }}
                            src={Group_345}
                            alt="Group_345"
                          />
                        </span>
                      </TableCell>
                      <TableCell>
                        <span style={{ display: "flex" }}>
                          <p>Date</p>
                          <img
                            style={{ width: 14, marginLeft: "0.2rem" }}
                            src={Group_345}
                            alt="Group_345"
                          />
                        </span>
                      </TableCell>
                      <TableCell>
                        <span style={{ display: "flex" }}>
                          <p>Phone No</p>
                          <img
                            style={{ width: 14, marginLeft: "0.2rem" }}
                            src={Group_345}
                            alt="Group_345"
                          />
                        </span>
                      </TableCell>
                      <TableCell>
                        <span style={{ display: "flex" }}>
                          <p>Location</p>
                          <img
                            style={{ width: 14, marginLeft: "0.2rem" }}
                            src={Group_345}
                            alt="Group_345"
                          />
                        </span>
                      </TableCell>
                      <TableCell>
                        <span style={{ display: "flex", marginLeft: "0.5rem" }}>
                          <p>Address</p>
                          <img
                            style={{ width: 14, marginLeft: "0.5rem" }}
                            src={Group_345}
                            alt="Group_345"
                          />
                        </span>
                      </TableCell>
                      <TableCell>
                        <span style={{ display: "flex" }}>
                          <p>Email Address</p>
                          <img
                            style={{ width: 14, marginLeft: "0.2rem" }}
                            src={Group_345}
                            alt="Group_345"
                          />
                        </span>
                      </TableCell>
                      <TableCell sx={{ height: "30px" }}>
                        <span style={{ display: "flex" }}>
                          <p>Videos/Pic Files</p>
                          <img
                            style={{ width: 14, marginLeft: "0.2rem" }}
                            src={Group_345}
                            alt="Group_345"
                          />
                        </span>
                      </TableCell>
                      <TableCell>
                        <span style={{ display: "flex" }}>
                          <p> Phone No Verified</p>
                          <img
                            style={{ width: 14, marginLeft: "0.2rem" }}
                            src={Group_345}
                            alt="Group_345"
                          />
                        </span>
                      </TableCell>
                      <TableCell>
                        <span style={{ display: "flex" }}>
                          <p>Mark as verified</p>
                          <img
                            style={{ width: 14, marginLeft: "0.2rem" }}
                            src={Group_345}
                            alt="Group_345"
                          />
                        </span>
                      </TableCell>
                      <TableCell>
                        <span style={{ display: "flex" }}>
                          <p>Send Message</p>
                          <img
                            style={{ width: 14, marginLeft: "0.2rem" }}
                            src={Group_345}
                            alt="Group_345"
                          />
                        </span>
                      </TableCell>
                      <TableCell>
                        <span style={{ display: "flex" }}>
                          <p>Action</p>
                          <img
                            style={{ width: 14, marginLeft: "0.2rem" }}
                            src={Group_345}
                            alt="Group_345"
                          />
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {usersData
                      ?.filter((item) => item?.userToCustomiseMsgTblId === null)
                      .map((item, i) => (
                        <TableRow
                          className={
                            !item?.isReview
                              ? classes.notReview
                              : classes.bodyroot
                          }
                          hover
                          role="checkbox"
                        >
                          <TableCell key={i}>
                            <span className="flex justify-start items-center  ">
                              {/* <Checkbox
                                    checked={checkboxStates[item?.userId] || checked1}
                                    onChange={() => toggleCheckbox(item, i)} /> */}
                              <div
                                style={{
                                  width: "2rem",
                                  height: "2rem",
                                  cursor: "pointer",
                                }}
                              >
                                <img
                                  style={{
                                    cursor: "pointer",
                                    width: "2rem",
                                    height: "2rem",
                                    borderRadius: "50%",
                                    border: "1px solid grey",
                                  }}
                                  alt="user"
                                  onClick={() => NavigateToProfile(item)}
                                  src={
                                    item?.image?.includes(
                                      ".jpg" || ".png" || ".jpeg"
                                    )
                                      ? `https://${item?.image}`
                                      : avatar
                                  }
                                  onError={FailedToLoad}
                                />
                              </div>
                              <span
                                onClick={() => NavigateToProfile(item)}
                                className=" FirstClild"
                              >
                                <span className="underLineTextVerify cursor-pointer">
                                  {item?.userId}
                                </span>
                                <span className="returingDataVerify">
                                  {item?.firstName} {item?.lastName}
                                </span>
                              </span>
                            </span>
                          </TableCell>
                          <TableCell>
                            <p className="returingDataVerify">
                              {formatDate(item?.createdAt * 1000)}
                            </p>
                          </TableCell>
                          <TableCell>
                            <p className="returingDataVerify">
                              {item?.phoneNumber}
                            </p>
                          </TableCell>
                          <TableCell>
                            <span className="flex  items-center ">
                              {item?.location?.map((data, i) => (
                                <span key={i} className="returingDataVerify">
                                  {data}
                                  {i < item?.location.length - 1 && ", "}
                                </span>
                              ))}
                            </span>
                          </TableCell>
                          <TableCell>
                            <p className="returingDataVerify">
                              {item?.streetAddress}
                            </p>
                          </TableCell>
                          <TableCell>
                            <p className="returingDataVerify">
                              {item?.emailAddress}
                            </p>
                          </TableCell>
                          {item?.ShowDocsUrl?.length > 0 ? (
                            <TableCell
                              onClick={() =>
                                Verification_docs(item?.id, item?.ShowDocsUrl)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <span
                                className="returingDataVerify"
                                style={{
                                  borderBottom: "2px solid blue",
                                  padding: "0.2rem 0",
                                }}
                              >
                                pics
                              </span>
                            </TableCell>
                          ) : (
                            <TableCell>
                              <span
                                className="returingDataVerify"
                                style={{ padding: "0.2rem 0" }}
                              >
                                No Docs
                              </span>
                            </TableCell>
                          )}
                          <TableCell>
                            {markAsVerifiedLoading ? (
                              <span>Loading...</span>
                            ) : item?.isPhoneVerified ? (
                              // Verified icon (green checkmark)
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                style={{ color: "#4CAF50" }}
                              >
                                <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                                <polyline points="22 4 12 14.01 9 11.01" />
                              </svg>
                            ) : (
                              // Unverified icon (red cross)
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                style={{ color: "#f44336" }}
                              >
                                <circle cx="12" cy="12" r="10" />
                                <line x1="15" y1="9" x2="9" y2="15" />
                                <line x1="9" y1="9" x2="15" y2="15" />
                              </svg>
                            )}
                          </TableCell>
                          <TableCell>
                            <Switch
                              onChange={() => approveCustomer(item?.id)}
                              checked={item?.isAdminApproved}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              height={22}
                              width={47}
                              disabled={item?.isPhoneVerified == false}
                            />
                          </TableCell>

                          <TableCell
                            data-tooltip-id="my-tooltip1"
                            data-tooltip-content={
                              item?.connectedTo &&
                                item?.connectedTo !== LoggedInId?.id
                                ? "This user is engaged with another admin at the moment"
                                : null
                            }
                          >
                            <Dropdown
                              className={`myClassName dropdown-up ${item?.connectedTo &&
                                  item?.connectedTo !== LoggedInId?.id
                                  ? "dropdownDisabled"
                                  : ""
                              }`}
                              // className={`myClassName dropdown-up`}
                              options={dropdownOptions(item)}
                              onChange={(selectedValue) =>
                                handleDropdownChange(selectedValue, item, i)
                              }
                              value={
                                item?.userToCustomiseMsgTblId?.IdOfCustomiseMsg
                                  ? Prev_message(item)
                                  : "None"
                              }
                              controlClassName="myControlClassName"
                              menuClassName="myDropdownMenuClass"
                              key={`dropdown-${refreshKey}`}
                            />
                          </TableCell>
                          <TableCell
                            data-tooltip-id="my-tooltip1"
                            data-tooltip-content={
                              item?.connectedTo &&
                                item?.connectedTo !== LoggedInId?.id
                                ? "This user is engaged with another admin at the moment"
                                : null
                            }
                          >
                            <img
                              onClick={() =>
                                item?.connectedTo &&
                                item?.connectedTo !== LoggedInId?.id
                                  ? null
                                  : GoToChat(item)
                              }
                              style={{
                                marginLeft: "0.5rem",
                                cursor: "pointer",
                              }}
                              src={
                                item?.isLastMsgRead && !item?.isLastMsgRead
                                  ? threedot_red
                                  : ThreeDot
                              }
                              alt="More"
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    {usersData?.filter(
                      (item) => item?.userToCustomiseMsgTblId === null
                    )?.length != 0 &&
                      usersData?.filter(
                        (item) => item?.userToCustomiseMsgTblId !== null
                      )?.length >= 1 ? (
                      <TableRow>
                        <TableCell colSpan={numberOfColumns}>
                          <hr
                            style={{ border: "1px solid red", width: "120%" }}
                          />
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {usersData
                      ?.filter((item) => item?.userToCustomiseMsgTblId !== null)
                      .map((item, i) => (
                        <TableRow
                          className={
                            !item?.isReview
                              ? classes.notReview
                              : classes.bodyroot
                          }
                          hover
                          role="checkbox"
                        >
                          <TableCell key={i}>
                            <span className="flex justify-start items-center  ">
                              {/* <Checkbox
                                    checked={checkboxStates[item?.userId] || checked1}
                                    onChange={() => toggleCheckbox(item, i)} /> */}
                              <div
                                style={{
                                  width: "2rem",
                                  height: "2rem",
                                  cursor: "pointer",
                                }}
                              >
                                <img
                                  style={{
                                    cursor: "pointer",
                                    width: "2rem",
                                    height: "2rem",
                                    borderRadius: "50%",
                                    border: "1px solid grey",
                                  }}
                                  alt="user"
                                  onClick={() => NavigateToProfile(item)}
                                  src={
                                    item?.image?.includes(
                                      ".jpg",
                                      ".png",
                                      ".jpeg"
                                    )
                                      ? `https://${item?.image}`
                                      : avatar
                                  }
                                  onError={FailedToLoad}
                                />
                              </div>
                              <span
                                onClick={() => NavigateToProfile(item)}
                                className=" FirstClild"
                              >
                                <span className="underLineTextVerify cursor-pointer">
                                  {item?.userId}
                                </span>
                                <span className="returingDataVerify">
                                  {item?.firstName} {item?.lastName}
                                </span>
                              </span>
                            </span>
                          </TableCell>
                          <TableCell>
                            <p className="returingDataVerify">
                              {formatDate(item?.createdAt * 1000)}
                            </p>
                          </TableCell>
                          <TableCell>
                            <p className="returingDataVerify">
                              {item?.phoneNumber}
                            </p>
                          </TableCell>
                          <TableCell>
                            <span className="flex  items-center ">
                              {item?.location?.map((data, i) => (
                                <span key={i} className="returingDataVerify">
                                  {data}
                                  {i < item?.location.length - 1 && ", "}
                                </span>
                              ))}
                            </span>
                          </TableCell>
                          <TableCell>
                            <p className="returingDataVerify">
                              {item?.streetAddress}
                            </p>
                          </TableCell>
                          <TableCell>
                            <p className="returingDataVerify">
                              {item?.emailAddress}
                            </p>
                          </TableCell>
                          {item?.ShowDocsUrl?.length > 0 ? (
                            <TableCell
                              onClick={() =>
                                Verification_docs(item?.id, item?.ShowDocsUrl)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <span
                                className="returingDataVerify"
                                style={{
                                  borderBottom: "2px solid blue",
                                  padding: "0.2rem 0",
                                }}
                              >
                                pics
                              </span>
                            </TableCell>
                          ) : (
                            <TableCell>
                              <span
                                className="returingDataVerify"
                                style={{ padding: "0.2rem 0" }}
                              >
                                No Docs
                              </span>
                            </TableCell>
                          )}
                          <TableCell>
                            {markAsVerifiedLoading ? (
                              <span>Loading...</span>
                            ) : item?.isPhoneVerified ? (
                              // Verified icon (green checkmark)
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                style={{ color: "#4CAF50" }}
                              >
                                <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                                <polyline points="22 4 12 14.01 9 11.01" />
                              </svg>
                            ) : (
                              // Unverified icon (red cross)
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                style={{ color: "#f44336" }}
                              >
                                <circle cx="12" cy="12" r="10" />
                                <line x1="15" y1="9" x2="9" y2="15" />
                                <line x1="9" y1="9" x2="15" y2="15" />
                              </svg>
                            )}
                          </TableCell>
                          <TableCell>
                            <Switch
                              onChange={() => approveCustomer(item?.id)}
                              checked={item?.isAdminApproved}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              height={22}
                              width={47}
                              disabled={item?.isPhoneVerified == false}
                            />
                          </TableCell>
                          <TableCell
                            data-tooltip-id="my-tooltip1"
                            data-tooltip-content={
                              item?.connectedTo &&
                                item?.connectedTo !== LoggedInId?.id
                                ? "This user is engaged with another admin at the moment"
                                : null
                            }
                          >
                            <Dropdown
                              className={`myClassName dropdown-up ${item?.connectedTo &&
                                  item?.connectedTo !== LoggedInId?.id
                                  ? "dropdownDisabled"
                                  : ""
                              }`}
                              // className={`myClassName dropdown-up`}
                              options={dropdownOptions(item)}
                              onChange={(selectedValue) =>
                                handleDropdownChange(selectedValue, item, i)
                              }
                              value={
                                item?.userToCustomiseMsgTblId?.IdOfCustomiseMsg
                                  ? Prev_message(item)
                                  : "None"
                              }
                              controlClassName="myControlClassName"
                              menuClassName="myDropdownMenuClass"
                              key={`dropdown-${refreshKey}`}
                            />
                          </TableCell>
                          <TableCell
                            data-tooltip-id="my-tooltip1"
                            data-tooltip-content={
                              item?.connectedTo &&
                                item?.connectedTo !== LoggedInId?.id
                                ? "This user is engaged with another admin at the moment"
                                : null
                            }
                          >
                            <img
                              onClick={() =>
                                item?.connectedTo &&
                                item?.connectedTo !== LoggedInId?.id
                                  ? null
                                  : GoToChat(item)
                              }
                              style={{
                                marginLeft: "0.5rem",
                                cursor: "pointer",
                              }}
                              src={
                                item?.isLastMsgRead && !item?.isLastMsgRead
                                  ? threedot_red
                                  : ThreeDot
                              }
                              alt="More"
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {writeMessage ? (
                <MessageComponents
                  setWriteMessage={setWriteMessage}
                  SaveMessage={SaveMessage}
                  setMessage={setMessage}
                  setMessageHeading={setMessageHeading}
                  loopId={loopId}
                  setState={setState}
                  Message={textMessage}
                // messageFunction={}
                />
              ) : null}
              {checkMessage ? (
                <MessageComponents
                  setCheckMessage={setCheckMessage}
                  SaveMessage={SaveMessage}
                  setMessage={setMessage}
                  setMessageHeading={setMessageHeading}
                // messageFunction={}
                />
              ) : null}
            </div>
          </>
          {usersData?.length === 0 && (
            <div className="NoAdminDataForNewCustomerAccountVerification">
              <img src={NOdataFound} alt="NO DATA FOUND" />
              <div className="NoAdminDataForNewCustomerAccountVerification">
                <h1>No Customer Users Available</h1>
                <h3>Currently there are no new customers for verification.</h3>
              </div>
            </div>
          )}
        </div>

        <div
          className={messageToggle ? "MessageSidebar" : "MessageSidebarNone"}
        >
          <div className="MessageTopHeading">
            <p style={{ fontFamily: "Manrope" }}>
              Message next to pic for new customers
            </p>
            <div>
              <ArrowForwardIcon
                // onClick={() => setMessageToggle(false)}
                onClick={handleChangeBusinessAccount}
                style={{ fontSize: 20, cursor: "pointer" }}
              />
            </div>
          </div>
          <div className="BottomContainer">
            {updateMessage?.map((text, index) => (
              <div className="messageContainer">
                <div className="MessageBox">
                  <p style={{ fontFamily: "Manrope" }}>
                    {index === 0 ? "None" : "Message" + index}
                    <ErrorOutlineIcon style={{ fontSize: 20, marginLeft: 2 }} />
                  </p>
                  <div>
                    <DriveFileRenameOutlineOutlinedIcon
                      onClick={() => {
                        editFunction(text);
                        // handleChangeBusinessAccount();
                      }}
                      style={{
                        fontSize: 25,
                        backgroundColor: "#e2e1e8",
                        color: "#6c6c73",
                        borderRadius: 5,
                        padding: 2,
                        marginTop: 5,
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
                <div className="Messages">
                  <p style={{ fontFamily: "Manrope" }}>{text.message}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="Add_more_text_container">
            <button onClick={AddNewData} className="Button-Message">
              + Add more
            </button>
          </div>
        </div>
      </div>
      {PaginationDataLength > Constants.TAKE ? (
        <Footer
          page={page}
          handleChange={handleChange}
          totalRecord={PaginationDataLength}
          startedRecords={startedRecords}
          toRecords={toRecords}
          totalPages={totalPages}
        />
      ) : null}
      <NewCusAccountDialog
        deleteDataNewCusAccount={deleteDataNewCusAccount}
        setDeleteDataNewCusAccount={setDeleteDataNewCusAccount}
        handleCloseNewCusAccount={handleCloseNewCusAccount}
        selectedNewCustomerVerificationEmail={
          selectedNewCustomerVerificationEmail
        }
      />
    </Container>
  );
}

export default NewCustomerVerification;
const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      height: "28px",
      padding: "0.3rem 1rem",
    },
  },
  bodyroot: {
    "&:hover": {
      backgroundColor: "#c5d9cf !important",
    },
    "& .MuiTableCell-body": {
      height: "28px",
      padding: "0.2rem 1rem",
    },
  },
  notReview: {
    backgroundColor: "#b6dfca !important",
    "&:hover": {
      backgroundColor: "#c5d9cf !important",
    },
    "& .MuiTableCell-body": {
      height: "28px",
      padding: "0.2rem 0.8rem",
    },
  },
});
