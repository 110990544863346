import React from "react";
import "./Messages.scss";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import moment from "moment";
import Blur from "react-css-blur";

import { useDispatch, useSelector } from "react-redux";

const Chat = ({ messages, currentUser, receiver, currentPage, isQA,  RemoveRoom,
  isRemoved, }) => {
  const chatRef = useRef(null);

  const {
    RecieverDetails,
    ChatRoomsUsers,
    newMessageCount,
  
  } = useSelector((state) => state.chat);
  const [blurredChats, setBlurredChats] = useState([]); // New state to track blurred chats

  useEffect(() => {
    const handleRemoveUserFromChat = (data) => {
      setBlurredChats((prev) => [...prev, data?.isSameChatRoom?.senderId]); // Add the user to the blurred chats list
    };
    if (isRemoved && RemoveRoom) {
      handleRemoveUserFromChat(RemoveRoom);
    } else {
      setBlurredChats([]);
    }
  }, [isRemoved]);
  let isSender = currentUser?.id === ChatRoomsUsers.senderId;
  let res = RecieverDetails?.filter(
    (u) => u?.id === (isSender ? receiver?.receiverId : receiver?.senderId)
  );

  let isChatBlurred = res.some((v) => blurredChats.includes(v?.id));
  // console.log("isChatBlurred>>>>>>>>>>>>>>", isChatBlurred);

  const StatusIndicators = ({ status }) => {
    switch (status) {
      case 'sending':
        return <span className="sending-text">✓</span>;
      case 'sent':
        return <span className="check-icon">✓✓</span>;
      case 'failed':
        return <span className="failed-icon">🕒</span>;
      default:
        return null;
    }
  };


  useEffect(() => {
    if (!isQA && !isChatBlurred) {
      if (currentPage === 1 && chatRef.current) {
        chatRef.current.scrollIntoView({ block: "start" });
      }
      if (chatRef.current) {
        chatRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    }
  }, [newMessageCount]);

  const formatDate = (timestamp) => {
    const today = moment().startOf("day");
    const messageDate = moment(parseInt(timestamp, 10));

    if (messageDate.isSame(today, "day")) {
      return "Today";
    } else if (messageDate.isSame(today.clone().subtract(1, "day"), "day")) {
      return "Yesterday";
    } else {
      return messageDate.format("DD MMMM YYYY");
    }
  };

  let prevMessageDate = null;
  return isChatBlurred ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "25%",
      }}
    >
      <div
        style={{
          fontSize: "1.5rem",
          fontFamily: "Segoe UI",
          color: "#717171",
        }}
      >
        This user is engaged with another admin at the moment
      </div>
    </div>
  ) : (
    <div className="CustMessage">
      {messages
        ?.filter((msg) => msg?.chatId === receiver?.ChatId)
        ?.map((m, index) => {
          const timestamp = parseInt(m?.createdAt, 10);
          console.log('created', timestamp)
          const time = moment(timestamp).format("LT");

          const timeInDay = formatDate(timestamp);
          let senderFName = "";
          let senderLName = "";
          let receiverFName = "";
          let receiverLName = "";
          if (currentUser?.id === m?.senderId) {
            res.map((user) => {
              senderFName = m.isSenderAdmin
                ? "Admin"
                : user?.userId;
              senderLName = currentUser?.lastName || " ";
              receiverFName = user?.userId || "User";
              receiverLName = user?.lastName || "";
            });
          } else {
            res.map((user) => {
              senderFName = user?.userId || "User";
              senderLName = user?.lastName || "";
              receiverFName =  "Admin";
              receiverLName = currentUser?.lastName || " ";
            });
          }

          const shouldDisplayTimeIndicator =
            prevMessageDate !== timeInDay || index === 0 || !prevMessageDate;

          prevMessageDate = timeInDay;

          let admin = m.isSenderAdmin;

          return (
            <>
              {shouldDisplayTimeIndicator && (
                <div className="maindate">
                  <span
                    style={{
                      color: "#7a7474",
                      position: "relative",
                      bottom: "10px",
                    }}
                  >
                    {Array(
                      prevMessageDate.length > 12
                        ? prevMessageDate.length + 22
                        : 100
                    ).join("_")}
                  </span>
                  <div className="TodayDate">{prevMessageDate}</div>
                  <span
                    style={{
                      color: "#7a7474",
                      position: "relative",
                      bottom: "10px",
                    }}
                  >
                    {Array(
                      prevMessageDate.length > 12
                        ? prevMessageDate.length + 22
                        : 100
                    ).join("_")}
                  </span>
                </div>
              )}

              <div className="mainDiv">
                <div className={admin ? `admin` : `CustomerAvtar`}>
                  {m &&
                    (ChatRoomsUsers[0]?.senderId === m?.senderId
                      ? `${senderFName?.charAt(0).toUpperCase()}
                   
                      }`
                      : `${senderFName?.charAt(0).toUpperCase()}
                 
                    
                          `)}
                </div>
                <h6
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    marginLeft: "10px",
                  }}
                >
                  {m && m.isSenderAdmin
                    ? `Admin`
                    : `${senderFName} ${senderLName}`}
                </h6>
              </div>
              <Blur radius={isChatBlurred ? "5px" : "0"} transition="400ms">
                <div className="CustomerMessage" ref={chatRef}>
                  <h6 style={{ marginLeft: "3rem", width: "90%" }}>
                    {m?.text}
                  </h6>

                  <h6 style={{ width: "10%" }}>{time}</h6>
                  <div className="status-container">
                 {m?.isSenderAdmin &&  <StatusIndicators status={m.status} /> }
                  </div>
                </div>
              </Blur>
            </>
          );
        })}
    </div>
  );
};

export default Chat;
